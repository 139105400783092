
import ReactEcharts from "echarts-for-react";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

export default function BarChart({
  height,
  title,
  xAxisData,
  seriesData,
}) {

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: ['Count', 'GWP'],
      top: 0,
      textStyle: {
        fontSize: 13,
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      axisLabel: {
        rotate: 40
      }
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    series: [
      {
        name: 'Count',
        type: 'bar',
        data: seriesData[0],
        itemStyle: {
          color: '#f99d36',
        },
      },
      {
        name: 'GWP',
        type: 'bar',
        data: seriesData[1],
        itemStyle: {
          color: '#0231bf',
        },
      },
    ],
  };

  return (
    <Card>
      <CardHeader subheader={title} />
      <Divider sx={{ backgroundColor: "black" }} />
      <Divider />
      <CardContent>
<ReactEcharts
          style={{ height: height }}
          option={{ ...option }}
        />
        {/* <div ref={chartRef} style={{ height: height, marginTop: "-35px" }} /> */}
      </CardContent>
    </Card>
  );
}
