import React, { useState, useEffect } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Alert, AlertTitle } from "@mui/material";
import * as XLSX from "xlsx";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { validateHeadersBulkPosExcel } from "../../lib/validateExcel";
import { createMapping } from "../../lib/validateData";
import "./styles.css";
import { axiosInstance, REACT_APP_FileURL } from "../../config";

const transformData = (data) => {
  const result = data.reduce(
    (acc, curr) => {
      const Branch = curr["Branch"];
      const ReportingToMotor = curr["Reporting To Motor"];
      const ReportingToNonMotor = curr["Reporting To Non Motor"];
      const Permission = curr["Permission"];
      const Club = curr["Club"];
      if (Branch) acc.Branch.add(Branch);
      if (ReportingToMotor) acc.ReportingToMotor.add(ReportingToMotor);
      if (ReportingToNonMotor) acc.ReportingToNonMotor.add(ReportingToNonMotor);
      if (Permission) acc.Permission.add(Permission);
      if (Club) acc.Club.add(Club);

      return acc;
    },
    {
      Branch: new Set(),
      ReportingToMotor: new Set(),
      ReportingToNonMotor: new Set(),
      Permission: new Set(),
      Club: new Set(),
    }
  );

  return {
    Branch: Array.from(result.Branch),
    ReportingToMotor: Array.from(result.ReportingToMotor),
    ReportingToNonMotor: Array.from(result.ReportingToNonMotor),
    Permission: Array.from(result.Permission),
    Club: Array.from(result.Club),
  };
};

const ImportBulkPos = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    Branch: [],
    ReportingToMotor: [],
    ReportingToNonMotor: [],
    Permission: [],
    Club: [],
  });
  const [fileData, setFileData] = useState([]);
  const [headers, setHeaders] = useState([]);

   const [insertedCount, setInsertedCount] = useState(null);
    const [bgColor, setBgColor] = useState("");

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);

      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const headers = jsonData[0];
      const Codes = jsonData.slice(1).map((item) => item[0]).filter(code => code !== undefined && code !== "");
      
      const response = validateHeadersBulkPosExcel(headers,Codes);

      if (!response?.valid) {
        setError(response);
        return;
      }
      const rows = jsonData.slice(1);

      const transformedData = rows.reduce((acc, row) => {
        if (row.length === 0) return acc;

        const transformedRow = Object.fromEntries(
          row
            .map((cell, index) => {
              const header = headers[index];

              return [header, cell];
            })
            .filter(([key, value]) => key !== undefined && value !== undefined)
        );
        acc.push(transformedRow);
        return acc;
      }, []);

      setHeaders(headers);
      setFileData(transformedData);
      const resultData = transformData(transformedData);
      setData((prev) => ({
        ...prev,
        Branch: resultData.Branch,
        ReportingToMotor: resultData.ReportingToMotor,
        ReportingToNonMotor: resultData.ReportingToNonMotor,
        Permission: resultData.Permission,
        Club: resultData.Club,
      }));
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileUpload = (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      const fileType = file ? file.name.split(".").pop().toLowerCase() : "";

      if (fileType === "xls" || fileType === "xlsx") {
        parseExcel(file);
      } else {
        alert("Please upload a XLSX, or XLS file.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getDataForPosImport = async () => {
    try {
      setLoading(true);

      const { data: response } = await axiosInstance.post(
        "/Pos/getDataForPosImport",
        data
      );
   
      if (response && response.data) {
        const {
          Branch,
          ReportingToMotor,
          ReportingToNonMotor,
          Permission,
          Club,
        } = response.data;

        const validationErrors = [];

        const mappingsBranch = createMapping(Branch, "BranchName", "_id");
        const mappingClub = createMapping(Club, "Name", "_id");
        const mappingsReportingToMotor = createMapping(
          ReportingToMotor,
          "Name",
          "_id"
        );
        const mappingsReportingToNonMotor = createMapping(
          ReportingToNonMotor,
          "Name",
          "_id"
        );
        const mappingsPermission = createMapping(Permission, "Roll", "_id");
        const mappingsClub = createMapping(Club, "Name", "_id");

        let finalData = fileData.map((entry) => {
          const branchId =
            mappingsBranch[entry["Branch"]?.trim().toLowerCase()] || undefined;

          const clubId = mappingClub[entry["Club"]?.trim().toLowerCase()] || undefined;

          const reportingToMotorId =
            typeof entry["Reporting To Motor"] === "string"
              ? mappingsReportingToMotor[
                  entry["Reporting To Motor"].trim().toLowerCase()
                ]
                || undefined
                : undefined;
          const reportingToNonMotorId =
            typeof entry["Reporting To Non Motor"] === "string"
              ? mappingsReportingToNonMotor[
                  entry["Reporting To Non Motor"].trim().toLowerCase()
                ]
                || undefined
                : undefined;
          const PermissionId =
            typeof entry["Permission"] === "string"
              ? mappingsPermission[entry["Permission"].trim().toLowerCase()]|| undefined
              : undefined;
          const ClubId =
            typeof entry["Club"] === "string"
              ? mappingsClub[entry["Club"].trim().toLowerCase()]
              || undefined
              : undefined;

              if (entry["Branch"] && !branchId) {
                validationErrors.push({
                  valid: false,
                  id: `${entry["Code"]}`,
                  fieldName: "Branch",
                  fieldValue: entry["Branch"],
                  row: { ...entry, errMsg: "Branch not found" },
                });
              }

              if (entry["Club"]?.trim() && !clubId) {
                validationErrors.push({
                  valid: false,
                  id: `${entry["Code"]}`,
                  fieldName: "Club",
                  fieldValue: entry["Club"],
                  row: { ...entry, errMsg: "Club not found" },
                });
              }
      
              if (entry["Reporting To Motor"]?.trim() && !reportingToMotorId) {
                validationErrors.push({
                  valid: false,
                  id: `${entry["Code"]}`,
                  fieldName: "Reporting To Motor",
                  fieldValue: entry["Reporting To Motor"],
                  row: { ...entry, errMsg: "Reporting To Motor not found" },
                });
              }
      
              if (entry["Reporting To Non Motor"]?.trim() && !reportingToNonMotorId) {
                validationErrors.push({
                  valid: false,
                  id: `${entry["Code"]}`,
                  fieldName: "Reporting To Non Motor",
                  fieldValue: entry["Reporting To Non Motor"],
                  row: { ...entry, errMsg: "Reporting To Non Motor not found" },
                });
              }
      
              if (entry["Permission"]?.trim() && !PermissionId) {
                validationErrors.push({
                  valid: false,
                  id: `${entry["Code"]}`,
                  fieldName: "Permission",
                  fieldValue: entry["Permission"],
                  row: { ...entry, errMsg: "Permission not found" },
                });
              }
      
              if (entry["Club"]?.trim() && !ClubId) {
                validationErrors.push({
                  valid: false,
                  id: `${entry["Code"]}`,
                  fieldName: "Club",
                  fieldValue: entry["Club"],
                  row: { ...entry, errMsg: "Club not found for Permission" },
                });
              }

          if (
            entry["Examined"] &&
            entry["Examined"] !== "Yes" &&
            entry["Examined"] !== "No"
          ) {
            validationErrors.push({
              valid: false,
              id: `${entry["Code"]}`,
              fieldName: "Examined",
              fieldValue: entry["Examined"],
              row: { ...entry, errMsg: "Invalid value!" },
            });
          }

          const mobileNumber = entry["Mobile"]?.toString().trim();
          const email = entry["Email"]?.trim();
          const panNumber = entry["Pan Number"]?.trim();

          if (mobileNumber && mobileNumber.length !== 10) {
            validationErrors.push({
              valid: false,
              id: `${entry["Code"]}`,
              fieldName: "Mobile",
              fieldValue: entry["Mobile"],
              row: { ...entry, errMsg: "Invalid mobile number!" },
            });
          }

          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (email && !emailPattern.test(email)) {
            validationErrors.push({
              valid: false,
              id: `${entry["Code"]}`,
              fieldName: "Email",
              fieldValue: entry["Email"],
              row: { ...entry, errMsg: "Invalid email" },
            });
          }
          const panPattern = /^[A-Z]{3}[A-Z]{2}[0-9]{4}[A-Z]{1}$/;

          if (panNumber && !panPattern.test(panNumber)) {
            validationErrors.push({
              valid: false,
              id: `${entry["Code"]}`,
              fieldName: "Pan Number",
              fieldValue: entry["Pan Number"],
              row: { ...entry, errMsg: "Invalid pan number!" },
            });
          }

          if (!entry["Code"]) {
            validationErrors.push({
              valid: false,
              id: `${entry["Code"]}`,
              fieldName: "Code",
              fieldValue: entry["Code"],
              row: { ...entry, errMsg: "Code is required!" },
            });
          }

          return {
            BankName: entry["Bank Name"],
            Name: entry["Name"],
            AliasName: entry["Alias Name"] ,
            AadhaarNumber: entry["Aadhaar Number"],
            OpeningBalance: entry["Opening Balance"],
            IFSC: entry["IFSC"],
            BankAccountNo: entry["Bank Account No"],
            PanNumber: entry["Pan Number"],
            AccountHolderName: entry["Account Holder Name"],
            MaritalStatus: entry["Marital Status"],
            GSTIN: entry["GSTIN"],
            Address: entry["Address"],
            ParentPOS: entry["Parent POS"],
            Examined: entry["Examined"],
            ExaminedStatus: entry["Examined Status"],
            ExaminedRemark: entry["Examined Remark"],
          
            City: entry["City"],
            Mobile: entry["Mobile"],
            Email: entry["Email"],
            Code: entry["Code"],
            Pincode: entry["Pin Code"],
            Branch: branchId ,
            ReportingToMotor: reportingToMotorId ,
            ReportingToNonMotor: reportingToNonMotorId ,
            Permission: PermissionId ,
            Salutation: entry["Salutation"],
            Club: clubId ,
          };
        });
        if (validationErrors.length > 0) {
          const uniqueArray = [];
          const seen = new Map();

          for (const obj of validationErrors) {
            if (!seen.has(obj.id)) {
              seen.set(obj.id, true);
              uniqueArray.push(obj);
            }
          }

          setError({
            message: "Validation failed!",
            data: uniqueArray,
            errorList: validationErrors,
          });
          return;
        }
     

        const postResponse = await axiosInstance.post(
          "/Pos/PostPosImportBulk",
          finalData
        );

        if (postResponse?.data?.success) {
          setInsertedCount(postResponse?.data?.insertedCount);
          setBgColor("#d4edda");
        }

        const fileInput = document.getElementById("file_upload");
        if (fileInput) {
          fileInput.value = "";
        }
        setHeaders([]);
        setFileData([]);
        setData([]);

      }
    } catch (error) {
      ToastError(
        error?.response?.data?.message ||
          "An error occurred while fetching data."
      );
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateDownload = (event) => {
    event.preventDefault();

    const fileUr = `${REACT_APP_FileURL}/images/Template/PosUpdate-Template.xlsx`;
    window.open(fileUr, "_blank");
  };
  
  return (
    <>
      <Header />
      <div className="MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ListDesigncol">
                        <h4 className="card-title">Update Bulk Pos</h4>
                      </div>
                      <button
                        onClick={handleTemplateDownload}
                        className="btn mybtn"
                      >
                        Download Template
                      </button>
                    </div>
                  </div>
                  {error && (
                    <Alert severity="error">
                      {error?.message && (
                        <AlertTitle>Error : {error?.message}</AlertTitle>
                      )}
                      {error?.missingHeaders &&
                        error?.missingHeaders?.length > 0 && (
                          <AlertTitle>
                            {error?.missingHeaders.join(", ")}
                          </AlertTitle>
                        )}
                    </Alert>
                  )}
                  {insertedCount !== null && (
                    <div
                      style={{
                        backgroundColor: bgColor,   // Green if success
                        width: "80vw",               // 80% of the viewport width
                        height: "auto",              // Automatically adjust height based on content
                        padding: "20px",             // Optional padding for spacing
                        fontSize: "1rem",          // Adjust font size
                        margin: "20px auto",

                      }}
                    >
                      {insertedCount} Records Updated
                    </div>
                  )}
                  <div className="container">
                    <div>
                      <div className="upload-container">
                        <input
                          type="file"
                          id="file_upload"
                          accept=".xlsx, .xls"
                          onChange={handleFileUpload}
                        />
                      </div>
                      <div className="mt-4" style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-primary"
                          color="warning"
                          type="button"
                          disabled={loading || error || fileData.length === 0}
                          onClick={getDataForPosImport}
                        >
                          {loading ? "In Progress..." : "Update"}
                        </button>
                      </div>
                    </div>
                    {error && error?.data && error?.data?.length !== 0 && (
                      <div className="mt-8">
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Preview Error Data
                        </div>
                        <div
                          // className=" p-2 container"
                          style={{
                            zoom: "80%",
                            height: "60vh",
                            border: "2px solid black",
                            resize: "vertical", // Allow vertical resizing only
                            overflow: "auto",
                          }}
                        >
                          <table class="table table-striped   table-bordered  overflow-x mt-3">
                            <thead>
                              <tr>
                                {headers &&
                                  headers.length > 0 &&
                                  headers.map((header) => {
                                    let hasError;
                                    if (error && error?.errorList) {
                                      hasError = error?.errorList.find(
                                        (error) => error.fieldName === header
                                      );
                                    }
                                    return (
                                      <th
                                        style={{
                                          backgroundColor: hasError
                                            ? "#f8d7da"
                                            : "",
                                        }}
                                        scope="col"
                                      >
                                        {header}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {error?.data.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    {headers.map((item, index) => {
                                      let hasError;
                                      if (error && error?.errorList) {
                                        hasError = error?.errorList.find(
                                          (err) =>
                                            err?.fieldValue === data?.row[item]
                                        );
                                      }

                                      return (
                                        <td
                                          style={{
                                            backgroundColor:
                                              hasError &&
                                              hasError?.fieldValue ===
                                                data?.row[item]
                                                ? "#f8d7da"
                                                : "",
                                          }}
                                          key={index}
                                        >
                                          {data?.row[item]}
                                          {hasError &&
                                            hasError?.fieldValue ===
                                              data?.row[item] && (
                                              <div
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {hasError?.row?.errMsg || ""}
                                              </div>
                                            )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default ImportBulkPos;
