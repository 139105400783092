import ReactEcharts from "echarts-for-react";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

export default function DoughnutChart({
  height,
  title,
  data,
  onChartClick = () => {},
}) {
  const option = {
    // title: {
    //   text: "Referer of a Website",
    //   subtext: "Fake Data",
    //   left: "center",
    // },
    tooltip: {
      show: false,
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    label: {
      fontSize: 13,
      //   color: "#17195f",
      //   fontFamily: theme.typography.fontFamily,
    },
    legend: {
      show: true,
      itemGap: 20,
      icon: "circle",
      bottom: 1,
      textStyle: {
        fontSize: 13,
        // color: theme.palette.text.secondary,
        // fontFamily: theme.typography.fontFamily,
      },
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: true,
          position: "outside",
          formatter: "{b}\n{c} ({d}%)",
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          label: {
            show: true,
            fontSize: 16,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: true,
        },
        data: data,
      },
    ],
  };

  // Map events to handlers
  const onEvents = {
    click: onChartClick,
  };

  return (
    <Card>
      <CardHeader subheader={title} />
      <Divider sx={{ backgroundColor: "black" }} />
      <Divider />
      <CardContent>
        <ReactEcharts
          style={{ height: height, marginTop: "-35px" }}
          option={{ ...option }}
          onEvents={onEvents}
        />
      </CardContent>
    </Card>
  );
}
