import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import logoImage from "../../assets/GoodLogin.png";
import VehicleGif from "../../assets/GIF.gif";

const Login = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [OTP, setOTP] = useState("");
  const [isEmailEntered, setisEmailEntered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [User, setUser] = useState({});

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!PhoneNumber) {
      return ToastError("Please enter a phone number");
    }

    try {
      const {
        data: { success, message, user },
      } = await axiosInstance.post("/Employee/EmployeeLogin", {
        PhoneNumber,
      });

      if (success) {
        ToastSusses(message);
        setisEmailEntered(true);
        setUser(user);
      } else {
        ToastSusses(message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToastError(error?.response?.data?.message);
    }
  };
  const loginValidateHandler = async (e) => {
    e.preventDefault();
    if (!OTP) {
      return ToastError("Please enter a OTP");
    }
    setLoading(true);
    dispatch({
      type: "LoginRequest",
    });
    try {
      const {
        data: { success, message, user },
      } = await axiosInstance.post(
        "/Employee/ValidateLogin",
        {
          OTP,
          _id: User._id,
        },
        { withCredentials: true }
      );

      if (success) {
        ToastSusses(message);
        dispatch({
          type: "LoginSuccess",
          payload: user,
        });
      } else {
        dispatch({
          type: "LoginFailure",
          // payload: user
        });

        ToastSusses(message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToastError(error?.response?.data?.message);
    }
  };
  const { isAuthenticated } = useSelector((store) => store.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/admin/Dashboard");
    }
  }, [isAuthenticated]);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.5) 0%, rgba(224, 235, 248, 0.3) 35%, rgba(78, 115, 223, 0.2) 60%, rgba(7, 42, 200, 0.2) 100%), url(${VehicleGif})`,
        backgroundColor: "#4e73df",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="login-container" style={{}}>
        {/* <img
        src={loginImage}
        alt="Login Illustration"
        style={{
          position: "absolute",
          top: "25vh", 
          right: "0",
          width: "30vw", 
          height: "auto", 
          objectFit: "contain", 
          opacity: 1, 
          animation: "slideUp 1s ease-out forwards", 
     
        }}
      /> */}
        <img
          src={logoImage}
          alt="Logo"
          style={{
            position: "absolute",
            top: "5vh",
            left: "5vw",
            width: "15vw",
            height: "auto",
          }}
        />

        {isEmailEntered ? (
          <form onSubmit={loginValidateHandler}>
            <div className="form-container-login">
              <div className="form-login">
                <div className="title-otp">OTP Verification Code</div>
                <p className="message-otp">
                  We have sent a verification code to your registered email id
                </p>
                <ShowEmail Email={User?.Email} />
                <div className="form-group-login">
                  <label className="Login-label" required>
                    Enter OTP
                  </label>
                  <input
                    type="number"
                    id="OTP"
                    name="OTP"
                    autoFocus={true}
                    placeholder="Enter your 6 Digits OTP code"
                    className="login-PhoneNumber"
                    onChange={(e) => setOTP(e.target.value)}
                    value={OTP}
                    required
                  />
                </div>
                <div className="Otp-send-button">
                  {loading ? (
                    <button className="form-submit-btn">Loading...</button>
                  ) : (
                    <button
                      className="form-submit-btn verify"
                      // onClick={() => {
                      //   loginValidateHandler();
                      // }}
                      type="submit"
                    >
                      Verify
                    </button>
                  )}
                  <button
                    className="form-submit-btn back"
                    onClick={() => {
                      setisEmailEntered(false);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <form className="form-container-login" onSubmit={loginHandler}>
            <div className="logo-container">Login/Signup</div>

            <div className="form-login">
              <div className="form-group-login">
                <input
                  type="number"
                  id="PhoneNumber"
                  name="PhoneNumber"
                  placeholder="Enter your Phone Number"
                  className="login-PhoneNumber"
                  required
                  autoFocus={true}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={PhoneNumber}
                />
              </div>

              {loading ? (
                <button className="form-submit-btn">Loading...</button>
              ) : (
                <button
                  type="submit"
                  className="form-submit-btn"
                  // onClick={() => {
                  //   loginHandler();
                  // }}
                >
                  Send OTP
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;

const ShowEmail = ({ Email = "" }) => {
  const emailData = String(Email).split("@");

  return (
    <p className="message-otp">
      {emailData[0].slice(0, 6)}******@{emailData[1] ?? ""}
    </p>
  );
};
