import React, { useState } from "react";
import exportFromJSON from "export-from-json";
import { AiOutlineFileExcel } from "react-icons/ai";
import { axiosInstance } from "../../config";
const SaleReturnExcel = ({ _id }) => {
  const [loading, setLoading] = useState();
  const exportType = exportFromJSON.types.csv;
  const KeyArray = [
    // "Branch",
    "RSD",
    "IssueDate",
    "InsuranceCompany",
    "PolicyNumber",
    "InsureeName",
    "Category",
    "InsuranceUnderFlow",
    "MakeModal",
    "PolicyType",
    "GrossPremium",
    "NETPremium",
    "TPPremium",
    "ODPremium",
    "CommisionablePremium",
    "BasedOn",
    "Point",
    "Percent",
    "PaymentMode",
    "YOM",
    "FuelType",
    "VehicleNumber",

    // "BusinessType",
    // "RM",
    // "DOR",
    // "YOM",
    // "NCB",

    // "RED",
    // "Broker",
  ];
  const downloadFile = async () => {
    try {
      setLoading(true);
      //   const excelData = await filterDataHandlerForExcel();
      const {
        data: {
          data: {
            Policy = [],
            EnterDate,
            CreditAccount: {
              Name = "",
              Salutation = "",
              MiddleName = "",
              LastName = "",
            },
          },
        },
      } = await axiosInstance.get(`/Sale/single/Excel/${_id}`);
      const newArr = [];

      let MaxCountLengthOfInsuranceType = 0;

      Policy.forEach((value) => {
        const { InsuranceUnderFlow = [] } = value;
        MaxCountLengthOfInsuranceType = Math.max(
          MaxCountLengthOfInsuranceType,
          InsuranceUnderFlow.length
        );
      });
      Policy.forEach((value) => {
        const myObj = {};

        for (const key of KeyArray) {
          if (key === "Branch") {
            myObj[key] = value?.Branch?.BranchName ?? "";
            continue;
          }
          if (key === "PolicyType") {
            myObj[key] = value?.PolicyType?.PolicyTypeName ?? "";
            continue;
          }
          if (key === "PolicyNumber") {
            myObj[key] = value?.PolicyNumber + "\t"?? "";
            continue;
          }

          if (key === "Category") {
            myObj[key] = value?.InsuranceUnderFlow[0]?.InsuranceType ?? "";
            continue;
          }
          if (key === "DOR") {
            myObj[key] = value?.RegistrationDate ?? "";
            continue;
          }
          if (key === "YOM") {
            myObj[key] = value?.YearOfManufacture ?? "";
            continue;
          }

          // if (key === "Point Of Sale") {
          //   if (value["POS"]) {
          //     const {
          //       Name = "",
          //       Salutation = "",
          //       MiddleName = "",
          //       LastName = "",
          //     } = value["POS"];
          //     myObj[key] = `${Salutation ?? ""} ${Name ?? ""} ${
          //       MiddleName ?? ""
          //     } ${LastName ?? ""}`;
          //     continue;
          //   }
          // }
          if (key === "RM") {
            if (value["POS"]) {
              const { ReportingTo } = value["POS"];

              if (ReportingTo) {
                let {
                  Name = "",
                  Salutation = "",
                  MiddleName = "",
                  LastName = "",
                } = ReportingTo;
                myObj["RM"] = `${Salutation ?? ""} ${Name ?? ""} ${
                  MiddleName ?? ""
                } ${LastName ?? ""}`;
              }
              continue;
            }
          }
          if (
            key === "InsuranceCompany" ||
            key === "Broker" ||
            key === "MakeModal"
          ) {
            myObj[key] = value[key]?.Name ?? "";
            continue;
          }
          if (key === "EnteredBy" || key === "ApprovedBy") {
            if (value[key]) {
              let {
                Name = "",
                Salutation = "",
                MiddleName = "",
                LastName = "",
              } = value[key];
              myObj[key] = `${Salutation ?? ""} ${Name ?? ""} ${
                MiddleName ?? ""
              } ${LastName ?? ""}`;
              continue;
            }
          }
          if (key === "createdAt" || key === "updatedAt") {
            myObj[key] = value[key].split("T")[0];
            continue;
          }
          if (key === "PaymentMode") {
            myObj[key] = value?.PaymentMode ?? "";
            continue;
          }
          if (key === "CommisionablePremium") {
            myObj[`CommisionablePremium`] =
              value?.Commission?.CommisionablePremium ?? 0;
            continue;
          }
          if (key === "Point") {
            myObj[key] = value?.Commission?.TotalPolicyProfit ?? "";
            continue;
          }

          if (key === "Percent") {
            let Percent = [];
            if (value["BasedOn"]) {
              value?.BasedOn?.split("+").forEach((element) => {
                if (element === "FLAT") {
                  Percent.push(
                    value["Commission"][`Reciveable${element}`] + "FLAT"
                  );
                }
                if (element === "OD") {
                  Percent.push(
                    value["Commission"][`Reciveable${element}`] / 10 + "X OD"
                  );
                }
                if (element === "TP") {
                  Percent.push(
                    value["Commission"][`Reciveable${element}`] / 10 + "X TP"
                  );
                }
                if (element === "NET") {
                  Percent.push(
                    value["Commission"][`Reciveable${element}`] / 10 + "X NET"
                  );
                }
              });
            }

            if (Percent.length > 0) {
              myObj[`Percent`] = Percent.join(" + ");
            } else {
              myObj[`Percent`] = "";
            }

            // myObj[`PercentToPayOnFLAT`] = value?.Commission?.CommissionToPayFLAT ?? "";
            // myObj[`PercentToPayOnOD`] = value?.Commission?.CommissionToPayOD ?? "";
            // myObj[`PercentToPayOnTP`] = value?.Commission?.CommissionToPayTP ?? "";
            // myObj[`PercentToPayOnTP`] = value?.Commission?.CommissionToPayNET ?? "";
            continue;
          }

          if (key === "InsuranceUnderFlow") {
            for (
              let index = 1;
              index < MaxCountLengthOfInsuranceType;
              index++
            ) {
              myObj[`SubCat${index}`] =
                value?.InsuranceUnderFlow[index]?.InsuranceType ?? "";
            }
            continue;
          }

          myObj[key] = value[key] ?? "";

          newArr.push(myObj);
        }
      });

      const jsonObject = newArr.map(JSON.stringify);
      const uniqueSet = new Set(jsonObject);
      const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      exportFromJSON({
        data: uniqueArray,
        fileName: `Bill-${Salutation} ${Name} ${MiddleName} ${LastName}-${
          EnterDate.split("T")[0]
        }`,
        exportType: exportType,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <AiOutlineFileExcel
          // onClick={downloadFile}
          size={35}
          style={{
            backgroundColor: "green",
            color: "#fff",
            padding: "7px",
            borderRadius: "5px",
          }}
        />
      ) : (
        <AiOutlineFileExcel
          onClick={downloadFile}
          size={35}
          style={{
            backgroundColor: "green",
            color: "#fff",
            padding: "7px",
            borderRadius: "5px",
          }}
        />
      )}
    </>
  );
};

export default SaleReturnExcel;
