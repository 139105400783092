import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";

import ExcelExport from "../utils/ExcelExport/ExcelExport";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import Select from "react-select";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import { reversDate } from "../../Utils/usefullFunction";

import DateRange from "../DateRangeModule/DateRange";
const RtoGroupChecker = () => {
  const [data, setData] = useState();
  const [AllKey, setAllKey] = useState();
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [firstRender, setFirstRender] = useState(false);
  const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([]);
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [InsuranceCompany, setInsuranceCompany] = useState({
    _id: "",
    AliasName: "Select Company",
  });
  const [date, setDate] = useState({
    DEF: "",
    DET: "",
  });

  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };
  const getData = async () => {
    setLoading(true);
    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/RtoGroupChecker?page=${pagination + 1}&limit=${rowsPerPage}`
      );

      let Keys = [];
      if (data[0]) {
        Keys = Object.keys(data[0]);
      }

      setTotalDocs(totalDocs);
      setAllKey(
        Keys.filter(
          (e) =>
            e !== "_id" &&
            e !== "createdAt" &&
            e !== "__v" &&
            e !== "ListOfRto" &&
            e !== "InsuranceCompany" &&
            e !== "updatedAt"
        )
      );

      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getDataByPageNation = async () => {
    if (inputData) {
      setInsuranceCompany("");
    }
    setLoading(true);
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");

    const insuranceType = SelectedInsuranceTypeData.length - 1;

    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/RtoGroupChecker?page=${pagination + 1
        }&limit=${rowsPerPage}&inputData=${inputData}&InsuranceCompany=${InsuranceCompany?._id ?? ""
        }&InsuranceType=${SelectedInsuranceTypeData[insuranceType] ?? ""}&DEF=${date.DEF ?? ""}&DET=${date.DET ?? ""}`
      );

      setTotalDocs(totalDocs);
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteRTO = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/RtoGroupChecker/${_id}`);

      if (success) {
        getData();
        ToastSusses(message);
        // ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    } else {
      getDataByPageNation();
    }
  }, [pagination, rowsPerPage]);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    } else {
      const delayDebounceFn = setTimeout(getDataByPageNation, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [inputData]);

  const getInsuranceCompany = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceCompany");
      setInsuranceCompanyOpt(data);
    } catch (error) { }
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) { }
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) { }
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      getDataInsuranceType();
    }
  };
  const getDataInsuranceType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) { }
  };
  const EraserHandler = async () => {
    const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };
    setSelectedInsuranceType((pre) => [reSetData]);
    setInsuranceCompany({
      _id: "",
      Name: "-- Select Insurance Company --",
    });
    setInput("");
    getData();
    setDate({
      DEF: "",
      DET: ""
    })
  };
  useEffect(() => {
    callInsuranceData();
    getInsuranceCompany();
    getDataInsuranceType();
  }, []);


  const onChangeSetValue = (e) => {
    setDate({
      ...date,
      [e.target.name]: e.target.value,
    });
  };

  const getExcelData = async () => {
    try {
      let rows = [];
      const SelectedInsuranceTypeData = SelectedInsuranceType.map(
        (ele) => ele.value
      ).filter((ele) => ele !== "");

      const insuranceType = SelectedInsuranceTypeData.length - 1;

      const {
        data: { success, data },
      } = await axiosInstance.get(
        `/RtoGroupChecker?&inputData=${inputData}&InsuranceCompany=${InsuranceCompany?._id ?? ""
        }&InsuranceType=${SelectedInsuranceTypeData[insuranceType] ?? ""}&DEF=${date.DEF ?? ""}&DET=${date.DET ?? ""}`
      );

      const length = data.length;

      if (success && data.length > 0) {
        const responseData = data;
        const updatedData = responseData.map((item) => {
          let Product = "";
          let SubProduct = "";
          let VehicleClass = "";

          const insuranceUnderFlow = item?.InsuranceType?.InsuranceUnderFlow || [];
          const mainInsuranceType = item?.InsuranceType?.InsuranceType || "";

          if (insuranceUnderFlow.length === 0) {
            Product = mainInsuranceType;
          } else if (insuranceUnderFlow.length === 1) {
            Product = insuranceUnderFlow[0]?.InsuranceType || "";
            SubProduct = mainInsuranceType;
          } else if (insuranceUnderFlow.length === 2) {
            Product = insuranceUnderFlow[0]?.InsuranceType || "";
            SubProduct = insuranceUnderFlow[1]?.InsuranceType || "";
            VehicleClass = mainInsuranceType;
          }

          return {
            ...item,
            InsuranceCompany: item?.InsuranceCompany?.AliasName,
            ListOfRto: item?.ListOfRto.map(
              (rtoItem) => `${rtoItem?.RTOName} (${rtoItem?.RTOCode})`
            ).join(", "),
            DEF: item?.DEF?.split("-").reverse().join("-"),
            DET: item?.DET?.split("-").reverse().join("-"),
            PolicyType: item?.PolicyType?.PolicyTypeName || "",
            FuelType: item?.FuelType?.FuelType || "",

            Product,
            SubProduct,
            VehicleClass,
          };
        });

        rows = updatedData;
      }

      const columns = [
        { header: "Id", key: "_id", width: 20 },
        { header: "Company Name", key: "InsuranceCompany", width: 20 },
        { header: "DEF Date", key: "DEF", width: 20 },
        { header: "DET Date", key: "DET", width: 20 },
        { header: "Cluster Name", key: "GroupName", width: 20 },
        { header: "Product", key: "Product", width: 25 },
        { header: "Sub-Product", key: "SubProduct", width: 25 },
        { header: "Vehicle Class", key: "VehicleClass", width: 20 },
        { header: "Policy Type", key: "PolicyType", width: 20 },
        { header: "Fuel Type", key: "FuelType", width: 20 },
        { header: "GVW From", key: "GVWFrom", width: 10 },
        { header: "GVW To", key: "GVWTo", width: 10 },

        { header: "Seater From", key: "seaterFrom", width: 10 },
        { header: "Seater To", key: "seaterTo", width: 10 },

        { header: "List of RTO", key: "ListOfRto", width: 20 },
      ];

      return { columns, rows, fileName: "RTO Cluster List" };
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, GroupName"
      />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="card-body CreateCard">
            <div className="row">
              <div className="col-8" style={{ float: "left" }}>
                <div className="ListDesigncol">
                  <h4 className="card-title">RTO Cluster</h4>
                  {/* <p className="card-title-desc">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  </p> */}
                </div>
              </div>
              <div className="col-4" style={{ float: "left" }}>
                <Link
                  className="btn mybtn"
                  to="/admin/masters/RtoGroupChecker"
                  state={{
                    Mode: false,
                    value: "",
                  }}
                >
                  Add RTO Cluster
                </Link>{" "}
                &nbsp;
                <Link
                  to="/admin/masters/import-rtoGroup/data"
                  className="btn mybtn"
                >
                  Import Rto Cluster
                </Link>
              </div>
            </div>
            <hr
              style={{
                height: "2px",
              }}
            />
            <div className="col">
              <ExcelExport getData={getExcelData} />

            </div>

            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "unset",
                flexDirection: "row",
              }}
            >
              <div className="d-flex gap-3">
                <SearchInputFilter
                  setInput={setInput}
                  inputData={inputData}
                  style={{ width: "250px" }}
                  PlaceHolder="Search by, Cluster Name..."
                />

                <div className="col-3">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={[
                      {
                        _id: "",
                        AliasName: "Select Company",
                      },
                      ...InsuranceCompanyOpt,
                    ]}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["AliasName"]}
                    onChange={(ele) => {
                      setInsuranceCompany(ele);
                    }}
                    value={InsuranceCompany}
                  />
                </div>


                {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsurance
                          key={data.value}
                          Options={data}
                          setSelectedInsuranceType={setSelectedInsuranceType}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                          IsFilter={true}
                          ShowLabel={false}
                          col="col-md-4"
                        />
                      )
                  )}


                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <input
                    className="form-control "
                    type="date"
                    name="DEF"
                    value={date.DEF}
                    onChange={(e) => onChangeSetValue(e)}
                    required //done
                  />

                  <p>TO</p>
                  <input
                    className="form-control "
                    type="date"
                    name="DET"
                    value={date.DET}
                    onChange={(e) => onChangeSetValue(e)}
                    required //done
                  />
                </div>
              </div>

              <div className="row gap-3 pr-2">
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => getDataByPageNation()}
                >
                  <MdSend />
                </div>
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => EraserHandler()}
                >
                  <BsEraser />
                </div>
              </div>
            </div>
            {loading ? (
              <div
                className=" p-2 container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <TableSkeleton />
              </div>
            ) : (
              <>
                <div
                  class="table-responsive"
                  style={{
                    zoom: "70%",
                  }}
                >
                  <table
                    class="table table-striped   table-bordered  overflow-x mt-3"
                    id="table-to-xls"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Id</th>
                        <th scope="col">Company Name </th>
                        <th scope="col">DEF Date</th>
                        <th scope="col">DET Date</th>
                        <th scope="col">Cluster Name</th>
                        <th scope="col">Products</th>
                        <th scope="col">Policy Type</th>
                        <th scope="col">GVW</th>
                        <th scope="col">Seater</th>
                        <th scope="col">Total RTO</th>
                        <th scope="col">EXTRA_OPTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length !== 0 &&
                        data.map((value, index) => {
                          let myInsuranceType =
                            value?.InsuranceType?.InsuranceUnderFlow?.map(
                              (ele) => ele?.InsuranceType
                            );
                          myInsuranceType?.push(
                            value?.InsuranceType?.InsuranceType
                          );

                          return (
                            <tr key={index + 1}>
                              <th scope="row">
                                {index + 1}
                                {/* {(rowsPerPage * pagination) - rowsPerPage + index + 1} */}
                              </th>
                              <td>{`${value?._id ?? ""}`}</td>
                              <td>{`${value?.InsuranceCompany?.AliasName ?? ""
                                }`}</td>
                              <td>{`${reversDate(value?.DEF) ?? ""}`}</td>
                              <td>{`${reversDate(value?.DET) ?? ""}`}</td>
                              <td>{`${value?.GroupName ?? ""}`}</td>
                              <td>{`${myInsuranceType ?? ""}`}</td>
                              <td>{`${value?.PolicyType?.PolicyTypeName ?? ""
                                }`}</td>
                              <td>{`${value?.GVWFrom ?? ""}-${value?.GVWTo ?? ""
                                }`}</td>
                              <td>{`${value?.SeaterFrom ?? ""}-${value?.SeaterTo ?? ""
                                }`}</td>
                              <td style={{ position: "relative" }}>
                                <center>
                                  <span
                                    class="badge m-2 p-2"
                                    style={{ backgroundColor: "#174379" }}
                                    title={value?.ListOfRto?.map(
                                      (ele) =>
                                        `${ele?.RTOName} (${ele?.RTOCode})`
                                    ).join(", ")}
                                  >
                                    {`${value?.ListOfRto?.length}`}
                                  </span>
                                </center>
                              </td>

                              <td>
                                {" "}
                                {value?.createdBy !== "By System" && (
                                  <div className="d-flex gap-3">
                                    <div>
                                      <Link
                                        className="col-md-1"
                                        state={{
                                          value,
                                          Mode: true,
                                          view: true,
                                        }}
                                        to={"/admin/masters/RtoGroupChecker"}
                                      >
                                        <FaEye size={25} color="" />{" "}
                                      </Link>
                                    </div>
                                    <div>
                                      <Link
                                        className="col-md-1"
                                        state={{
                                          value,
                                          Mode: true,
                                        }}
                                        to={`/admin/masters/RtoGroupChecker?value=${value._id}`}
                                      >
                                        <BiEdit size={25} color="blue" />{" "}
                                      </Link>
                                    </div>
                                    <div>
                                      <AiFillDelete
                                        color="red"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        size={25}
                                        onClick={() => {
                                          const isTrue = window.confirm(
                                            "Do you want to delete!"
                                          );
                                          if (isTrue) {
                                            deleteRTO(value?._id);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {
                  <div
                    style={{
                      backgroundColor: "#1a2e45d7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      marginTop: "5px",
                    }}
                  >
                    <TablePagination
                      component="div"
                      className="text-white"
                      rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                      count={totalDocs ?? 0}
                      page={pagination}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                }
              </>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default RtoGroupChecker;
