import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import { useSelector } from "react-redux";

import useGettingData from "../Hooks/useGettingData";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FileUploadModal from "../Modals/FileUploadModal";
import ViewFileModal from "../Modals/ViewFileModal";

import Select from "react-select";
import PortalBankDetails from "../../Utils/PortalBankDetails";
const CreatePOS = () => {
  const DELETE_URL = "/Pos/delete/Image";

  const navigate = useNavigate();
  const { state } = useLocation();

  const { user } = useSelector((store) => store.user);
  const { value = "", Mode, view } = state || {};
  const [Name, setName] = useState(value?.Name || "");
  const [Permission, setPermission] = useState(value?.Permission || "");
  const [PermissionOpt, setPermissionOpt] = useState([]);
  const [AliasName, setAliasName] = useState(value?.AliasName || "");
  const [OldReportingToMotor, setOldReportingToMotor] = useState("");
  const [OldReportingToNonMotor, setOldReportingToNonMotor] = useState("");
  const [branch, setBranch] = useState(
    value?.Branch ? value?.Branch : user?.Branch
  );
  const [BankDetails, setBankDetails] = useState([]);
  // const [Salutation, setSalutation] = useState(value?.Salutation || "Mr");
  const [MiddleName, setMiddleName] = useState(value?.MiddleName || "");
  const [LastName, setLastName] = useState(value?.LastName || "");
  const [Email, setEmail] = useState(value?.Email || "");
  // const [Password, setPassword] = useState(value?.Password || "");
  const [Mobile, setMobile] = useState(value?.Mobile || 0);
  const [DOB, setDOB] = useState(value?.DOB || "");
  const [DOA, setDOA] = useState(value?.DOA || "");
  const [code, setCode] = useState(value?.Code || "");
  const [Address, setAddress] = useState(value?.Address || "");
  const [WDLocality, setWDLocality] = useState(value?.WDLocality || "");
  const [WDState, setWDState] = useState(value?.WDState || "");
  const [WDCity, setWDCity] = useState(value?.WDCity || "");
  const [WDPincode, setWDPincode] = useState(value?.WDPincode || "");
  const [WDAddress, setWDAddress] = useState(value?.WDAddress || "");
  const [OpeningBalance, setOpeningBalance] = useState(
    value?.OpeningBalance || 0
  );
  // const [Locality, setLocality] = useState(value?.Locality || "");
  // const [State, setState] = useState(value?.State || "");
  // const [City, setCity] = useState(value?.City || "");
  // const [Pincode, setPincode] = useState(value?.Pincode || "");
  const [Employee, setEmployee] = useState([]);
  const [Clubopt, setClubOpt] = useState([]);

  const [MaritalStatus, setMaritalStatus] = useState(
    value?.MaritalStatus || ""
  );

  const [GSTINNumber, setGSTINNumber] = useState(value?.GSTINNumber || "");
  const [PanNumber, setPanNumber] = useState(value?.PanNumber || "");
  // const [CINCode, setCINCode] = useState(value?.CINCode || "");
  const [AadhaarNumber, setAadhaarNumber] = useState(
    value?.AadhaarNumber || ""
  );

  const [Club, setClub] = useState(
    value?.Club || {
      _id: "",
      Name: "-- Select Club --",
    }
  );
  const [Examined, setExamined] = useState(value?.Examined || "No");
  const [ExaminedStatus, setExaminedStatus] = useState(
    value?.ExaminedStatus || ""
  );
  const [ExaminedRemark, setExaminedRemark] = useState(
    value?.ExaminedRemark || ""
  );
  const [Reference, setReference] = useState(
    value?.Reference || {
      _id: "",
      Name: "-- Select Reference By --",
    }
  );
  const [ReportingToMotor, setReportingToMotor] = useState(
    value?.ReportingToMotor || {
      _id: "",
      Name: "-- Select Reporting Employee --",
    }
  );
  const [ReportingToNonMotor, setReportingToNonMotor] = useState(
    value?.ReportingToNonMotor || {
      _id: "",
      Name: "-- Select Reporting Employee --",
    }
  );
  const [ReferenceCriteria, setReferenceCriteria] = useState(
    value?.ReferenceCriteria || ""
  );

  // IMAGES
  const [profilePic, setprofilePic] = useState();
  const [adharcardFrontImage, setadharcardFrontImage] = useState();
  const [adharcardBackImage, setadharcardBackImage] = useState();
  const [MarkSheet, setMarkSheet] = useState();
  const [Cheque, setCheque] = useState();
  const [panCard, setpanCard] = useState();
  const [PosData, setPosData] = useState([]);

  const [ModalData, setModalData] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let {
    getData: getBranch,
    Loading: BranchLoading,
    Options: BranchOpt = [
      {
        _id: "",
        BranchName: "-- Select Branch --",
      },
      user?.Branch,
    ],
  } = useGettingData("/Branch", [
    {
      _id: "",
      BranchName: "-- Select Branch --",
    },
  ]);

  const handleCreate = async (e) => {
    e.preventDefault();

    // const EMAIL_VALIDATED = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    // const GST_VALIDATER = new RegExp(
    //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/
    // );

    // if (Examined === "Yes") {
    //   if (!ExaminedStatus) {
    //     return ToastWarn("Examined Status is Required");
    //   }
    // }
    // if (GSTINNumber) {
    //   if (!GST_VALIDATER.test(GSTINNumber)) {
    //     return ToastWarn("Invalid GST Number");
    //   }
    // }

    if (!code) {
      return ToastWarn("Code is required");
    }
    if (!Name) {
      return ToastWarn("Name is required");
    }
    if (!Permission) {
      return ToastWarn("Permisssion is required");
    }

    // if (String(Mobile).length !== 10) {
    //   return ToastWarn("Please Enter valid Mobile Number");
    // }
    if (PanNumber.length !== 10) {
      return ToastWarn("Please Enter valid Pan Number");
    }
    //   if (AadhaarNumber && String(AadhaarNumber).length !== 12) {
    //     return ToastWarn("Please Enter valid Aadhaar Number");
    // }
    if (!ReportingToMotor._id) {
      return ToastWarn("Reporting Manager Required");
    }
    if (!Club._id) {
      return ToastWarn("Club is Required");
    }
    try {
      e.preventDefault();
      const EmpData = new FormData();
      EmpData.append("Code", code);
      EmpData.append("Name", Name);
      EmpData.append("AliasName", AliasName);
      // EmpData.append("Salutation", Salutation);
      EmpData.append("MiddleName", MiddleName);
      EmpData.append("LastName", LastName);
      EmpData.append("Email", Email);
      EmpData.append("Branch", branch?._id);
      EmpData.append("Mobile", Mobile);
      EmpData.append("Permission", Permission);
      // EmpData.append("Password", Password);
      EmpData.append("ReportingToMotor", ReportingToMotor._id);
      EmpData.append("ReportingToNonMotor", ReportingToNonMotor._id);
      EmpData.append("Examined", Examined);
      EmpData.append("ExaminedStatus", ExaminedStatus);
      EmpData.append("ExaminedRemark", ExaminedRemark);
      EmpData.append("DOB", DOB);
      EmpData.append("DOA", DOA);
      EmpData.append("MaritalStatus", MaritalStatus);
      EmpData.append("OpeningBalance", OpeningBalance);

      // EmpData.append("BankName", BankName);
      // EmpData.append("IFSC", IFSC);
      // EmpData.append("BankAccountNo", BankAccountNo);
      EmpData.append("GSTINNumber", GSTINNumber);
      EmpData.append("PanNumber", PanNumber.toUpperCase());
      EmpData.append("AadhaarNumber", AadhaarNumber);
      EmpData.append("WDAddress", WDAddress);
      EmpData.append("WDLocality", WDLocality);
      EmpData.append("WDState", WDState);
      EmpData.append("WDCity", WDCity);
      EmpData.append("WDPincode", WDPincode);
      // EmpData.append("Address", Address);
      // EmpData.append("Locality", Locality);
      // EmpData.append("State", State);
      // EmpData.append("City", City);
      // EmpData.append("Pincode", Pincode);
      // EmpData.append("CINCode", CINCode);
      EmpData.append("Club", Club._id);
      EmpData.append("ReferenceCriteria", ReferenceCriteria);
      EmpData.append("Reference", Reference?._id);

      // images

      EmpData.append("profilePic", profilePic);
      EmpData.append("adharcardFrontImage", adharcardFrontImage);
      EmpData.append("adharcardBackImage", adharcardBackImage);
      EmpData.append("MarkSheet", MarkSheet);
      EmpData.append("Cheque", Cheque);
      EmpData.append("panCard", panCard);

      const myData = BankDetails.map((ele) => ele._id);
      EmpData.append("BankDetails", JSON.stringify(myData));

      const {
        data: { success, message },
      } = await axiosInstance.post("/Pos", EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Pos/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    // const EMAIL_VALIDATED = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    // const GST_VALIDATER = new RegExp(
    //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/
    // );
    // if (GSTINNumber) {
    //   if (!GST_VALIDATER.test(GSTINNumber)) {
    //     return ToastWarn("Invalid GST Number");
    //   }
    // }

    // if (!EMAIL_VALIDATED.test(Email)) {
    //   return ToastWarn("Please Enter Valid Email Address");
    // }

    // if (Examined === "Yes") {
    //   if (!ExaminedStatus) {
    //     return ToastWarn("Examined Status is Required");
    //   }
    // }
    // if (String(Mobile).length !== 10) {
    //   return ToastWarn("Please Enter valid Mobile Number");
    // }

    if (!code) {
      return ToastWarn("Code is required");
    }
    if (!Name) {
      return ToastWarn("Name is required");
    }

    if (PanNumber.length !== 10) {
      return ToastWarn("Please Enter valid Pan Number");
    }
    //   if (AadhaarNumber && String(AadhaarNumber).length !== 12) {
    //     return ToastWarn("Please Enter valid Aadhaar Number");
    // }
    if (!ReportingToMotor._id) {
      return ToastWarn("Reporting Manager Required");
    }
    if (!Club._id) {
      return ToastWarn("Club is Required");
    }

    const EmpData = new FormData();
    EmpData.append("Name", Name);
    EmpData.append("AliasName", AliasName);
    EmpData.append("Code", code);
    // EmpData.append("Salutation", Salutation);
    EmpData.append("MiddleName", MiddleName);
    EmpData.append("LastName", LastName);
    EmpData.append("Email", Email);
    EmpData.append("Mobile", Mobile);
    EmpData.append("Branch", branch?._id);
    EmpData.append("ReportingToMotor", ReportingToMotor._id);
    EmpData.append("ReportingToNonMotor", ReportingToNonMotor._id);
    EmpData.append("OldReportingToMotor", OldReportingToMotor);
    EmpData.append("OldReportingToNonMotor", OldReportingToNonMotor);
    EmpData.append("Permission", Permission);
    // EmpData.append("Password", Password);
    EmpData.append("DOB", DOB);
    EmpData.append("DOA", DOA);
    EmpData.append("MaritalStatus", MaritalStatus);
    EmpData.append("OpeningBalance", OpeningBalance);

    EmpData.append("Club", Club._id);
    EmpData.append("GSTINNumber", GSTINNumber);
    EmpData.append("PanNumber", PanNumber);
    EmpData.append("AadhaarNumber", AadhaarNumber);
    EmpData.append("WDAddress", WDAddress);
    EmpData.append("WDLocality", WDLocality);
    EmpData.append("WDState", WDState);
    EmpData.append("WDCity", WDCity);
    EmpData.append("WDPincode", WDPincode);
    EmpData.append("Examined", Examined);
    EmpData.append("ExaminedStatus", ExaminedStatus);
    EmpData.append("ExaminedRemark", ExaminedRemark);

    EmpData.append("ReferenceCriteria", ReferenceCriteria);
    EmpData.append("Reference", Reference?._id);

    // images
    EmpData.append("profilePic", profilePic);
    EmpData.append("adharcardFrontImage", adharcardFrontImage);
    EmpData.append("adharcardBackImage", adharcardBackImage);
    EmpData.append("MarkSheet", MarkSheet);
    EmpData.append("Cheque", Cheque);
    EmpData.append("panCard", panCard);
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Pos/${value?._id}`, EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (success) {
        ToastSusses(message);
        return navigate("/admin/masters/Pos/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      return ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (ReportingToMotor) {
      setOldReportingToMotor(ReportingToMotor._id || "")
    }
  }, [])
  useEffect(() => {
    if (ReportingToNonMotor) {
      setOldReportingToNonMotor(ReportingToNonMotor._id || "")
    }
  }, [])

  const getPos = async () => {
    try {
      const {
        data: { data = [] },
      } = await axiosInstance.post("/Pos/getPosByField", {
        showFiled: {
          Name: 1,
          // Salutation: 1,
          MiddleName: 1,
          LastName: 1,
          _id: 1,
        },
      });

      const POS = data.map(
        ({
          Name = "",
          // Salutation = "",
          MiddleName = "",
          LastName = "",
          _id,
        }) => {
          return {
            Name: `${Name} ${MiddleName} ${LastName}`,
            _id,
          };
        }
      );
      setPosData([
        {
          _id: "",
          Name: "-- Select Reference By --",
        },
        ...POS,
      ]);
    } catch (error) { }
  };

  const getEmployee = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/Employee/selectBoxDropdown");

      const Emp = data.map(
        ({ Name = "", MiddleName = "", LastName = "", _id }) => {
          return {
            Name: `${Name} ${MiddleName} ${LastName}`,
            _id,
          };
        }
      );
      setEmployee(Emp);
    } catch (error) { }
  };

  const getClub = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/Club");

      setClubOpt(data);
    } catch (error) { }
  };

  const getPermission = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/RBAC");
      setPermissionOpt(data);
    } catch (error) { }
  };

  useEffect(() => {
    // getPos();
    getClub();
    getEmployee();
    getPermission();
  }, []);

  return (
    <>
      <Header />
      <ViewFileModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        ModalData={ModalData}
      />
      {/* <UploadBankDocModal
        handleOpen={() => {
          setBankDocModal(true);
        }}
        handleClose={() => {
          setBankDocModal(false);
        }}
        open={BankDocModal}
        ModalData={ModalDataForBankDetails}
        BankDetails={BankDetails}
        setBankDetails={setBankDetails}
      /> */}

      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <form onSubmit={(e) => (Mode ? handleEdit(e) : handleCreate(e))}>
            <div className="row  ">
              <section
                className="push "
                style={{
                  position: "relative",
                  transition: " margin-left .5s",
                  zoom: "90%",
                }}
              >
                <div className="CreateCard p-4">
                  <div className="row mx-2 ">
                    <h5>
                      {view ? "View POS" : Mode ? "Edit POS" : "Create POS"}
                    </h5>
                    <hr className="mt-1 " />
                    <div className="col-md-4 align-items-center">
                      <label required>Code</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Enter Code"
                        required
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label required>Name</label>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        {/* <select
                          class="form-select"
                          style={{
                            width: "20%",
                          }}
                          aria-label="Default select example"
                          name="Salutation"
                          value={Salutation}
                          onChange={(e) => setSalutation(e.target.value)}
                          placeholder="Salutation"
                          disabled={view}
                        >
                          <option value="Mr">Mr.</option>
                          <option value="Mrs">Mrs.</option>
                          <option value="Miss">Miss.</option>
                          <option value="Ms">Ms.</option>
                        </select> */}
                        <input
                          disabled={view}
                          className="form-control myName"
                          type="text"
                          name="Name"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                          aria-label="Default select example"
                        />
                        {/* <input
                          disabled={view}
                          className="form-control myName "
                          type="text"
                          name="MiddleName"
                          value={MiddleName}
                          onChange={(e) => setMiddleName(e.target.value)}
                          placeholder=" Middle Name"
                        /> */}
                        {/* <input
                          disabled={view}
                          className="form-control myName"
                          type="text"
                          name="LastName"
                          value={LastName}
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="Last Name"
                        /> */}
                      </div>
                    </div>
                    {/* //new code for AliasName */}

                    <div className="col-md-4 align-items-center">
                      <label>Alias Name</label>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <input
                          disabled={view}
                          className="form-control myName"
                          type="text"
                          name="AliasName"
                          value={AliasName}
                          onChange={(e) => setAliasName(e.target.value)}
                          placeholder="Alias Name"
                          aria-label="Default select example"
                        />
                      </div>
                    </div>

                    <div className="col-md-4 align-items-center">
                      <label required>Reporting Manager (MOTOR)</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={[
                          {
                            _id: "",
                            Name: "-- Select Reporting Empolyee --",
                          },
                          ...Employee,
                        ]}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                        value={ReportingToMotor}
                        onChange={setReportingToMotor}
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label required>Reporting Manager (NON-MOTOR)</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={[
                          {
                            _id: "",
                            Name: "-- Select Reporting Empolyee --",
                          },
                          ...Employee,
                        ]}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                        value={ReportingToNonMotor}
                        onChange={setReportingToNonMotor}
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Mobile Number</label>
                      <input
                        disabled={view}
                        className="form-control"
                        type="Number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        name="Mobile"
                        value={Mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="Mobile"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Email</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Email"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Pos Email"
                      />
                    </div>
                    {/* <div className="col-md-4 align-items-center">
                      <label required>Password</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Password"
                        value={Password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Pos Password"
                        required
                      />
                    </div> */}

                    <div className="col-md-4 align-items-center">
                      <label>DOB</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="date"
                        name="DOB"
                        value={DOB}
                        onChange={(e) => setDOB(e.target.value)}
                        placeholder="Enter Pos DOB"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Aadhaar Number</label>
                      <input
                        disabled={view}
                        className="form-control text-uppercase "
                        type="Number"
                        name="AadhaarNumber"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 12))
                        }
                        value={AadhaarNumber}
                        onChange={(e) => setAadhaarNumber(e.target.value)}
                        placeholder="Enter AadhaarNumber"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label required>Pan Number</label>
                      <input
                        disabled={view}
                        className="form-control text-uppercase"
                        type="text"
                        name="PanNumber"
                        minLength={10}
                        maxLength={10}
                        value={PanNumber}
                        onChange={(e) => setPanNumber(e.target.value)}
                        placeholder="Enter PanNumber"
                      />
                    </div>

                    <div className="col-md-4 align-items-center">
                      <label>GSTIN Number</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="GSTINNumber"
                        value={GSTINNumber}
                        onChange={(e) => setGSTINNumber(e.target.value)}
                        placeholder="Enter GSTINNumber"
                      />
                    </div>

                    {/* <div className="col-md-4 align-items-center">
                      <label>CIN Code</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="CINCode"
                        value={CINCode}
                        onChange={(e) => setCINCode(e.target.value)}
                        placeholder="Enter CINCode"
                      />
                    </div> */}
                    <div className="col-md-4 align-items-center">
                      <label>Marital Status</label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setMaritalStatus(e.target.value)}
                        value={MaritalStatus}
                        disabled={view}
                      >
                        <option value="yes">Yes</option>
                        <option selected value="No">
                          No
                        </option>
                      </select>
                    </div>
                    {MaritalStatus === "yes" && (
                      <div className="col-md-4 align-items-center">
                        <label>DOA</label>
                        <input
                          disabled={view}
                          className="form-control "
                          type="date"
                          name="DOA"
                          value={DOA}
                          onChange={(e) => setDOA(e.target.value)}
                          placeholder="Enter Pos DOA"
                        />
                      </div>
                    )}
                    {/* <div className="col-md-4 align-items-center">
                      <label>Address</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Address"
                        value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address"
                      />
                    </div> */}
                    {/* <div className="col-md-4 align-items-center">
                      <label>Locality</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Locality"
                        value={Locality}
                        onChange={(e) => setLocality(e.target.value)}
                        placeholder="Enter Working Locality"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>State</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="State"
                        value={State}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="Enter State"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>City</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="City"
                        value={City}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Enter City"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Pin code</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Pincode"
                        value={Pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        placeholder="Enter Pincode"
                      />
                    </div> */}
                    {/* <div className="col-md-4 align-items-center">
                      <label>Club</label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        // onChange={(e) => setClub(e.target.value)}

                        disabled={view}
                      >
                        options=
                        {[
                          {
                            _id: "",
                            Name: "-- Select Club--",
                          },
                          ...Club,
                        ]}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                        onChange={setClub}
                        value={Club}
                      </select>
                    </div> */}

                    <div className="col-md-4 align-items-center">
                      <label required>Club</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={[
                          {
                            _id: "",
                            Name: "-- Select Club --",
                          },
                          ...Clubopt,
                        ]}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                        value={Club}
                        onChange={setClub}
                      />
                    </div>

                    {Reference?._id && (
                      <div className="col-md-4 align-items-center">
                        <label>Reference Criteria</label>
                        <select
                          disabled={view}
                          class="form-select"
                          aria-label="Default select example"
                          name="ReferenceCriteria"
                          value={ReferenceCriteria}
                          onChange={(e) => {
                            setReferenceCriteria(e.target.value);
                          }}
                        >
                          <option value={""}>Select Reference Criteria</option>
                          <option value={1}>1%</option>
                          <option value={2}>2%</option>
                          <option value={3}>Club difference</option>
                        </select>
                      </div>
                    )}

                    <div className="col-md-3 align-items-center">
                      <label required> Permission</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setPermission(e.target.value)}
                        value={Permission}
                      >
                        <option value="">-----Select Permission------</option>
                        {PermissionOpt &&
                          PermissionOpt.map((data) => (
                            <option value={data._id}>{data?.Roll}</option>
                          ))}
                      </select>
                    </div>

                    <div
                      className="col-md-4 align-items-center"
                      onClick={getBranch}
                    >
                      <label required>Branch</label>
                      {/* <div className=""> */}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={user?.Branch?.BranchCode !== "0"}
                        options={
                          BranchOpt.length === 0 ? [user?.Branch] : BranchOpt
                        }
                        isLoading={BranchLoading}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["BranchName"]}
                        value={branch}
                        onChange={(ele) => {
                          setBranch(ele);
                        }}
                        styles={{ height: "42px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="CreateCard p-4">
                  <div className="row mx-4 ">
                    <h5>Examined Details</h5>
                    {/* Pos Information */}
                    <hr className="mt-1 " />

                    <div className="col-md-4 align-items-center">
                      <label>Examined </label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        name="Examined"
                        value={Examined}
                        onChange={(e) => {
                          setExamined(e.target.value);
                        }}
                      >
                        <option value="">
                          -------------Select Examined-------------
                        </option>

                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Status</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        name="ExaminedStatus"
                        value={ExaminedStatus}
                        onChange={(e) => {
                          setExaminedStatus(e.target.value);
                        }}
                      >
                        <option value="">
                          -------------Select Status-------------
                        </option>

                        {[
                          "All",
                          "Profile pending",
                          "Registration requested",
                          "Duplicate",
                          "Docs invalid",
                          "Rejected",
                          "Gi- study material shared",
                          "Gi- exam pending",
                          "Gi- exam failed",
                          "Gi- exam cleared",
                          "Gi- Study material downloaded",
                          "Li- study material shared",
                          "Li- exam pending",
                          "Li- exam failed",
                          "Li- exam cleared",
                          "Li - study material downloaded",
                        ].map((ele, index) => (
                          <option key={index} value={ele}>
                            {ele}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Opening Balance</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="OpeningBalance"
                        value={OpeningBalance}
                        onChange={(e) => setOpeningBalance(e.target.value)}
                        placeholder="Remark"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Remark</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="ExaminedRemark"
                        value={ExaminedRemark}
                        onChange={(e) => setExaminedRemark(e.target.value)}
                        placeholder="Remark"
                      />
                    </div>
                  </div>
                </div>
                <div className="CreateCard p-4">
                  <div className="row mx-4 ">
                    <h5>Address Details</h5>
                    {/* Pos Information */}
                    <hr className="mt-1 " />

                    <div className="col-md-4 align-items-center">
                      <label>Address</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Address"
                        value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Enter Address"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Locality</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="WDLocality"
                        value={WDLocality}
                        onChange={(e) => setWDLocality(e.target.value)}
                        placeholder="Enter Working Locality"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>State</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        name="WDState"
                        value={WDState}
                        onChange={(e) => {
                          setWDState(e.target.value);
                        }}
                      >
                        <option value="">----Select State----</option>
                        {[
                          "Andhra Pradesh",
                          "Arunachal Pradesh",
                          "Assam",
                          "Bihar",
                          "Chhattisgarh",
                          "Goa",
                          "Gujarat",
                          "Haryana",
                          "Himachal Pradesh",
                          "Jharkhand",
                          "Karnataka",
                          "Kerala",
                          "Madhya Pradesh",
                          "Maharashtra",
                          "Manipur",
                          "Meghalaya",
                          "Mizoram",
                          "Nagaland",
                          "Odisha",
                          "Punjab",
                          "Rajasthan",
                          "Sikkim",
                          "Tamil Nadu",
                          "Telangana",
                          "Tripura",
                          "Uttar Pradesh",
                          "Uttarakhand",
                          "West Bengal",
                        ].map((ele) => (
                          <option value={ele}>{ele}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>City</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="WDCity"
                        value={WDCity}
                        onChange={(e) => setWDCity(e.target.value)}
                        placeholder="Enter City"
                      />
                    </div>
                    <div className="col-md-4 align-items-center">
                      <label>Pin code</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="WDPincode"
                        value={WDPincode}
                        onChange={(e) => setWDPincode(e.target.value)}
                        placeholder="Enter Pincode"
                      />
                    </div>
                  </div>
                </div>

                <div className="CreateCard p-4">
                  <div className="row mx-4 ">
                    <h5>
                      {Mode
                        ? "Edit Pos Bank Details"
                        : "Create Pos Bank Details"}
                    </h5>
                    {/* Pos Information */}
                    <hr className="mt-1 " />
                    <PortalBankDetails
                      view={view}
                      Name={Name}
                      MiddleName
                      LastName={MiddleName}
                      value={value}
                      Mode={Mode}
                      BankDetails={BankDetails}
                      setBankDetails={setBankDetails}
                      For="Pos"
                    />
                  </div>
                </div>
                <div
                  className="CreateCard p-4"
                  style={{
                    zoom: "90%",
                  }}
                >
                  <div className="row mx-4 ">
                    <h5>POS Document Upload </h5>
                    {/* Pos Information */}
                    <hr className="mt-1 " />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <FileUploadModal
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"1"}
                        Mode={Mode}
                        Url={value?.profilePic ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setprofilePic}
                        Name="Profile Pic"
                        filedName="profilePic"
                      />
                      <FileUploadModal
                        deleteUrl={DELETE_URL}
                        id={value?._id}
                        index={"2"}
                        Mode={Mode}
                        Url={value?.adharcardFrontImage ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setadharcardFrontImage}
                        Name="Aadharcard Front"
                        filedName="adharcardFrontImage"
                      />
                      <FileUploadModal
                        deleteUrl={DELETE_URL}
                        id={value?._id}
                        index={"3"}
                        Mode={Mode}
                        Url={value?.adharcardBackImage ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setadharcardBackImage}
                        Name="Adharcard Back"
                        filedName="adharcardBackImage"
                      />
                      <FileUploadModal
                        deleteUrl={DELETE_URL}
                        id={value?._id}
                        index={"4"}
                        Mode={Mode}
                        Url={value?.panCard ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setpanCard}
                        Name="Pan Card Image"
                        filedName="panCard"
                      />
                      <FileUploadModal
                        deleteUrl={DELETE_URL}
                        id={value?._id}
                        index={"6"}
                        Mode={Mode}
                        Url={value?.MarkSheet ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setMarkSheet}
                        Name="MarkSheet*"
                        filedName="MarkSheet"
                      />
                      <FileUploadModal
                        deleteUrl={DELETE_URL}
                        id={value?._id}
                        index={"7"}
                        Mode={Mode}
                        Url={value?.Cheque ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setCheque}
                        Name="Cancel Cheque"
                        filedName="Cheque"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <div className="d-flex gap-3 ">
                <button
                  type="button"
                  className="btn mybtn"
                  onClick={() => navigate("/admin/masters/Pos/data")}
                >
                  Go Back
                </button>
                {!view && (
                  <button
                    type="submit"
                    className="btn mybtn"
                  // style={{ marginTop: 28 }}
                  // onClick={() => {
                  //   Mode ? handleEdit() : handleCreate();
                  // }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreatePOS;
