import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import CreatePincode from "../backend/Pincode/CreatePincode";
import Pincode from "../backend/Pincode/Pincode";
import VehicleRcInformation from "../backend/vehicle-rc-information/VehicleRcInformation";
import QC from "../backend/QC/QC";
import Dashboard from "../backend/Dashboard/Dashboard";
import CreateReceipt from "../backend/Voucher/CreateReceipt";
import Bank from "../backend/Bank/Bank";
import CreateBank from "../backend/Bank/CreateBank";
import Receipt from "../backend/Voucher/Receipt";
import CreatePaymentList from "../backend/Voucher/CreatePaymentList";
import PaymentList from "../backend/Voucher/PaymentList";
import Branch from "../backend/Bank/Branch";
import CreateBranch from "../backend/Bank/CreateBranch";
import POS from "../backend/POS/POS";
import Ledger from "../backend/Books/Ledger";
import LedgerGroup from "../backend/LedgerGroup/LedgerGroup";
import CreateLedgerGroup from "../backend/LedgerGroup/CreateLedgerGroup";
import LedgerEntry from "../backend/LedgerEntry/LedgerEntry";
import CreateLedgerEntry from "../backend/LedgerEntry/CreateLedgerEntry";
import CreditNotes from "../backend/Voucher/CreditNotes";
import CreateCreditNotes from "../backend/Voucher/CreateCreditNotes";
import Contra from "../backend/Voucher/Contra";
import CreateContra from "../backend/Voucher/CreateContra";
import CreateRtoGroupChecker from "../backend/RtoGroupChecker/CreateRtoGroupChecker";
import RtoGroupChecker from "../backend/RtoGroupChecker/RtoGroupChecker";
import DebitNotes from "../backend/Voucher/DebitNotes";
import CreateDebitNotes from "../backend/Voucher/CreateDebitNotes";
import State from "../backend/State/State";
import CreateState from "../backend/State/CreateState";
import Manufacturer from "../backend/Manufacturer/Manufacturer";
import CreateManufacturer from "../backend/Manufacturer/CreateManufacturer";
import CreateEmployee from "../backend/Employee/CreateEmployee";
import Employee from "../backend/Employee/Employee";
import CreateRto from "../backend/RTO/CreateRto";
import Rto from "../backend/RTO/Rto";
import CreatePOS from "../backend/POS/CreatePOS";
import InsuranceCompany from "../backend/InsuranceCompany/InsuranceCompany";
import CreateInsuranceCompany from "../backend/InsuranceCompany/CreateInsuranceCompany";
import InsuranceType from "../backend/InsuranceType/InsuranceType";
import CreateInsuranceType from "../backend/InsuranceType/CreateInsuranceType";
import VehicleModal from "../backend/VehicleModal/VehicleModal";
import CreateVehicleModal from "../backend/VehicleModal/CreateVehicleModal";
import CreateInsuranceSubCategory from "../backend/insuranceSubCategory/CreateInsuranceSubCategory";
import InsuranceSubCategory from "../backend/insuranceSubCategory/InsuranceSubCategory";
import PolicyType from "../backend/PolicyType/PolicyType";
import CreatePolicyType from "../backend/PolicyType/CreatePolicyType";
import CreatePayoutGrid from "../backend/PayoutGrid/CreatePayoutGrid";
import PayoutGrid from "../backend/PayoutGrid/PayoutGrid";
import EditPayoutGrid from "../backend/PayoutGrid/EditPayoutGrid";
import Policy from "../backend/Policy/Policy";
// import CreatePolicy from "../backend/Policy/CreatePolicyType";
import RBAC from "../backend/AccessControl/RBAC";
import CreateRBAC from "../backend/AccessControl/CreateRBAC";
import Broker from "../backend/Broker/Broker";
import CreateBroker from "../backend/Broker/CreateBroker";
import MakeModal from "../backend/MakeModal/MakeModal";
import CreateMakeModal from "../backend/MakeModal/CreateMakeModal";
import EditVehicleModal from "../backend/VehicleModal/EditVehicleModal";
import CommissionTable from "../backend/Policy/CommissionTable";
import CommissionTableForPos from "../backend/Policy/CommissionTableForPos";
import CreatePurchase from "../backend/Purchase/CreatePurchase";
import CreatePurchaseReturn from "../backend/PurchaseReturn/CreatePurchaseReturn";
import Purchase from "../backend/Purchase/Purchase";
import PurchaseBilling from "../backend/Purchase/PurchaseBilling";
import EditPurchase from "../backend/Purchase/EditPurchase";
import PurchaseReturn from "../backend/PurchaseReturn/PurchaseReturn";
import EditPurchaseReturn from "../backend/PurchaseReturn/EditPurchaseReturn";

import BankBranch from "../backend/BankBranch/BankBranch";
import CreateBankBranch from "../backend/BankBranch/CreateBankBranch";
import ViewPolicy from "../backend/Policy/ViewPolicy";
// import QCStatus from "../backend/QC/QCStatus";
import QCStatus from "../backend/QC/QCStatus";
import Login from "../backend/Login/Login";
import EmployeeReport from "../backend/Dashboard/EmployeeReport";
import ERPReport from "../backend/Dashboard/ERPReport";
import NewDebitNotes from "../backend/Voucher/NewDebitNotes";
import CreateGeneralVoucher from "../backend/Voucher/CreateGeneralVoucher";
import GeneralVoucher from "../backend/Voucher/GeneralVoucher";
import TrialBalance from "../backend/Voucher/TrialBalance";
import CreateSale from "../backend/Sale/CreateSale";
import Sale from "../backend/Sale/Sale";
import EditSale from "../backend/Sale/EditSale";
import ViewPurchase from "../backend/Purchase/ViewPurchase";
import ReportHOC from "../backend/Dashboard/ReportHOC";
import UnbilledCase from "../backend/Books/UnbilledCase";
import ViewReceipt from "../backend/Voucher/ViewReceipt";
import ViewSale from "../backend/Sale/ViewSale";
import ViewPayment from "../backend/Voucher/ViewPayment";
import ViewContra from "../backend/Voucher/ViewContra";
import ViewCreditNotes from "../backend/Voucher/ViewCreditNotes";
import ViewDebitNotes from "../backend/Voucher/ViewDebitNotes";
import ViewGeneral from "../backend/Voucher/ViewGeneral";
import SalesBook from "../backend/Header/SalesBook";
import ERPReportByBranch from "../backend/Dashboard/ERPReportByBranch";
import ERPReportReportingManager from "../backend/Dashboard/ERPReportReportingManager";
// import SelectPolicyEntry from "../backend/Policy/SelectPolicyEntry";
import CreatePolicy from "../backend/Policy/CreatePolicyType";
import PolicyForEdit from "../backend/Policy/PolicyForEdit";
import TDSReport from "../backend/Books/TDSReport";
import DuplicatedRto from "../backend/RTO/DuplicatedRto";
import RemoveRtos from "../backend/RTO/RemoveRtos";
import ViewPurchaseReturn from "../backend/PurchaseReturn/ViewPurchaseReturn";
import FuelType from "../backend/FuelType/FuelType";
import CreateFuelType from "../backend/FuelType/CreateFuelType";
import AddMake from "../backend/Make/AddMake";
import MakeList from "../backend/Make/MakeList";
import AddModel from "../backend/Model/AddModel";
import Model from "../backend/Model/Model";
import Variant from "../backend/Variant/Variant";
import AddVariant from "../backend/Variant/AddVariant";
import ImportPolicy from "../backend/Import/ImportPolicy";
import ImportPos from "../backend/Import/ImportPos";
import ImportBulkPos from "../backend/Import/ImportBulkPos";
import ImportrtoGroup from "../backend/Import/ImportRtoGroup";
import ClusterMatch from "../backend/Policy/ClusterMatch";
import ImportPayoutGrid from "../backend/Import/ImportPayoutGrid";
import ImportEmployee from "../backend/Import/ImportEmployee";
import Club from "../backend/Club/Club";
import CreateClub from "../backend/Club/CreateClub";
import ClubException from "../backend/ClubException/ClubException";
import AddClubException from "../backend/ClubException/AddClubException";
import CPPolicy from "../backend/CPPolicy/CPPolicy";
import CommissionablePremium from "../backend/CommissionablePremium/CommissionablePremium";
import AddCommissionablePremium from "../backend/CommissionablePremium/AddCommissionablePremium";
import SaleReturnn from "../backend/SaleReturn/SaleReturn";
import CreateSaleReturn from "../backend/SaleReturn/CreateSaleReturn";
import ViewSaleReturn from "../backend/SaleReturn/ViewSaleReturn";
import EditSaleReturn from "../backend/SaleReturn/EditSaleReturn";
import SaleReturnExcel from "../backend/SaleReturn/SaleReturnExcel";
import NewReturnSaleExcel from "../backend/SaleReturn/NewReturnSaleExcel";
import BulkDeletePayoutGrid from "../backend/PayoutGrid/BulkDeletePayoutGrid";
import BulkUpdatePayoutGrid from "../backend/PayoutGrid/BulkUpdatePayoutGrid";
import ClusterMatchNonMotor from "../backend/Policy/ClusterMatchNonMotor";


const PayoutGridOutflow = lazy(() =>
  import("../backend/PayoutGrid/PayoutGridOutflow")
);

const BackendRoute = () => {
  return (
    <Routes>
      {
        <>
          {" "}
          <Route element={<ProtectedRoute />}>
            {/* reports  */}
            <Route
              path="/admin/Entry/Report"
              element={
                <ReportHOC
                  ReportComponent={<EmployeeReport isReport="$EnteredBy" />}
                  isWhat="EnteredBy"
                />
              }
            />
            <Route
              path="/admin/Approved/Report"
              element={
                <ReportHOC
                  ReportComponent={<EmployeeReport isReport="$ApprovedBy" />}
                  isWhat="ApprovedBy"
                />
              }
            />
            <Route
              path="/admin/POS/Report"
              element={
                <ReportHOC
                  ReportComponent={<ERPReport ReportOf="POS" />}
                  isWhat="POS"
                />
              }
            />
            <Route
              path="/admin/Branch/Report"
              element={
                <ReportHOC
                  ReportComponent={<ERPReportByBranch ReportOf="Branch" />}
                  isWhat="Branch"
                />
              }
            />
            <Route
              path="/admin/Broker/Report"
              element={
                <ReportHOC
                  ReportComponent={<ERPReport ReportOf="Broker" />}
                  isWhat="Broker"
                />
              }
            />
            <Route
              path="/admin/ReportingManger/Report"
              element={
                <ReportHOC
                  ReportComponent={
                    <ERPReportReportingManager ReportOf="ReportingManger" />
                  }
                  isWhat="ReportingManger"
                />
              }
            />
            <Route
              path="/admin/Check/Commission"
              element={
                <ReportHOC
                  ReportComponent={<CommissionTable />}
                  isWhat="InsuranceCompany"
                />
              }
            />
            {/* <CommissionTable /> */}
            <Route
              path="/admin/Company/Report"
              // element={<ERPReport ReportOf="InsuranceCompany" />}
              element={
                <ReportHOC
                  ReportComponent={<ERPReport ReportOf="InsuranceCompany" />}
                  isWhat="InsuranceCompany"
                />
              }
            />
            {/* reports  */}
            <Route path="/admin/QC/:id" element={<QCStatus />} />
            <Route path="/admin/masters/Rto/data" element={<Rto />} />
            <Route path="/admin/masters/Rto" element={<CreateRto />} />
            <Route path="/admin/RTO/Duplicated" element={<DuplicatedRto />} />
            <Route path="/remove/duplicate/:id" element={<RemoveRtos />} />
            <Route path="/admin/masters/State/data" element={<State />} />
            <Route path="/admin/masters/State" element={<CreateState />} />
            <Route path="/admin/masters/Club/data" element={<Club />} />
            <Route path="/admin/masters/Club" element={<CreateClub />} />
            <Route
              path="/admin/masters/club-exception/data"
              element={<ClubException />}
            />
            <Route
              path="/admin/masters/club-exception"
              element={<AddClubException />}
            />
            <Route
              path="/admin/masters/commissionable-premium/data"
              element={<CommissionablePremium />}
            />
            <Route
              path="/admin/masters/CommissionablePremium"
              element={<AddCommissionablePremium />}
            />
            <Route path="/admin/Manufacturer/data" element={<Manufacturer />} />
            <Route path="/admin/masters/FuelType/data" element={<FuelType />} />
            <Route
              path="/admin/masters/FuelType"
              element={<CreateFuelType />}
            />
            <Route path="/admin/masters/Make/data" element={<MakeList />} />
            <Route path="/admin/masters/Make" element={<AddMake />} />
            <Route path="/admin/masters/Model/data" element={<Model />} />
            <Route path="/admin/masters/Model" element={<AddModel />} />
            <Route path="/admin/masters/Model/:id" element={<AddModel />} />

            <Route path="/admin/masters/Variant/data" element={<Variant />} />
            <Route path="/admin/masters/Variant" element={<AddVariant />} />
            <Route path="/admin/masters/Variant/:id" element={<AddVariant />} />

            <Route
              path="/admin/Manufacturer"
              element={<CreateManufacturer />}
            />
            <Route
              path="/admin/masters/PolicyType/data"
              element={<PolicyType />}
            />
            <Route path="/admin/Broker/data" element={<Broker />} />
            <Route path="/admin/Broker" element={<CreateBroker />} />
            <Route path="/admin/books/TDSReport/data" element={<TDSReport />} />
            <Route
              path="/admin/masters/PolicyType"
              element={<CreatePolicyType />}
            />
            <Route path="/admin/data/cluster/:id" element={<ClusterMatch />} />
            <Route path="/admin/data/cluster/NonMotor/:id" element={<ClusterMatchNonMotor />} />
            <Route
              path="/admin/import-policy/data"
              element={<ImportPolicy />}
            />
            <Route
              path="/admin/masters/import-rtoGroup/data"
              element={<ImportrtoGroup />}
            />
            <Route
              path="/admin/masters/import-Pos/data"
              element={<ImportPos />}
            />
            <Route
              path="/admin/masters/import-Bulk-Pos/data"
              element={<ImportBulkPos />}
            />
            <Route
              path="/admin/masters/import-payoutgrid/data"
              element={<ImportPayoutGrid />}
            />
            <Route
              path="/admin/masters/payout-grid/outflow"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <PayoutGridOutflow />
                </Suspense>
              }
            />
            <Route
              path="/admin/masters/import-employee/data"
              element={<ImportEmployee />}
            />

            <Route
              path="/admin/masters/InsuranceType/data"
              element={<InsuranceType />}
            />
            <Route
              path="/admin/masters/InsuranceType"
              element={<CreateInsuranceType />}
            />
            <Route
              path="/admin/masters/InsuranceType/:id"
              element={<CreateInsuranceType />}
            />

            <Route
              path="/admin/masters/Employee"
              element={<CreateEmployee />}
            />
            <Route path="/admin/masters/Employee/data" element={<Employee />} />
            <Route path="/admin/masters/POS/data" element={<POS />} />
            <Route path="/admin/masters/Pos" element={<CreatePOS />} />
            <Route path="/admin/VehicleModal/data" element={<VehicleModal />} />
            <Route
              path="/admin/VehicleModal"
              element={<CreateVehicleModal />}
            />
            <Route
              path="/admin/VehicleModal/:id"
              element={<EditVehicleModal />}
            />
            <Route
              path="/admin/InsuranceSubCategory/data"
              element={<InsuranceSubCategory />}
            />
            <Route
              path="/admin/InsuranceSubCategory"
              element={<CreateInsuranceSubCategory />}
            />
            <Route
              path="/admin/masters/InsuranceCompany/data"
              element={<InsuranceCompany />}
            />
            <Route
              path="/admin/masters/InsuranceCompany"
              element={<CreateInsuranceCompany />}
            />
            <Route
              path="/admin/masters/PayoutGrid"
              element={<CreatePayoutGrid />}
            />

            <Route
              path="/admin/masters/PayoutGrid/data"
              element={<PayoutGrid />}
            />


            <Route
              path="/admin/masters/PayoutGrid/edit/:id"
              element={<EditPayoutGrid />}
            />

            <Route
              path="/admin/masters/BulkDeletePayoutGrid/data"
              element={<BulkDeletePayoutGrid />}
            />

            <Route
              path="/admin/masters/BulkUpdatePayoutGrid/data"
              element={<BulkUpdatePayoutGrid />}
            />

            <Route path="/admin/Policy/data" element={<Policy />} />
            <Route path="/admin/CPPolicy/data" element={<CPPolicy />} />
            <Route
              path="/admin/view/Policy/:PolicyId"
              element={<ViewPolicy />}
            />
            <Route path="/admin/Policy" element={<CreatePolicy />} />
            <Route path="/admin/Policy/:PolicyId" element={<PolicyForEdit />} />
            <Route path="/admin/Pincode" element={<CreatePincode />} />
            <Route path="/admin/Pincode/data" element={<Pincode />} />
            <Route
              path="/admin/masters/AccessControl"
              element={<CreateRBAC />}
            />
            <Route
              path="/admin/masters/AccessControl/data"
              element={<RBAC />}
            />

            <Route path="/admin/QC" element={<QC />} />
            <Route
              path="/admin/masters/VehicleRcInformation"
              element={<VehicleRcInformation />}
            />
            <Route path="/admin/Dashboard" element={<Dashboard />} />
            <Route path="/admin/masters/Bank/data" element={<Bank />} />
            <Route path="/admin/masters/Bank" element={<CreateBank />} />
            <Route path="/admin/masters/Branch/data" element={<Branch />} />
            <Route path="/admin/masters/Branch" element={<CreateBranch />} />
            <Route path="/admin/masters/BankBranch/data" element={<BankBranch />} />
            <Route path="/admin/masters/BankBranch" element={<CreateBankBranch />} />
            <Route
              path="/admin/vouchers/Journal"
              element={<CreateGeneralVoucher />}
            />
            <Route
              path="/admin/vouchers/Journal/data"
              element={<GeneralVoucher />}
            />
            <Route path="/admin/vouchers/Receipt" element={<CreateReceipt />} />
            <Route path="/admin/view/Receipt/:id" element={<ViewReceipt />} />
            <Route path="/admin/view/Payment/:id" element={<ViewPayment />} />
            <Route path="/admin/view/Contra/:id" element={<ViewContra />} />
            <Route path="/admin/view/Journal/:id" element={<ViewGeneral />} />
            <Route
              path="/admin/view/CreditNotes/:id"
              element={<ViewCreditNotes />}
            />
            <Route
              path="/admin/view/DebitNotes/:id"
              element={<ViewDebitNotes />}
            />

            <Route path="/admin/vouchers/Receipt/data" element={<Receipt />} />
            <Route
              path="/admin/books/TrialBalance/data"
              element={<TrialBalance />}
            />
            <Route path="/admin/books/Ledger/data" element={<Ledger />} />
            <Route
              path="/admin/books/SalesRegister/data"
              element={<SalesBook />}
            />
            <Route
              path="/admin/books/Unbilled/data"
              element={<UnbilledCase />}
            />
            <Route
              path="/admin/vouchers/PaymentList"
              element={<CreatePaymentList />}
            />

            <Route
              path="/admin/vouchers/PaymentList/data"
              element={<PaymentList />}
            />
            <Route
              path="/admin/masters/LedgerGroup/data"
              element={<LedgerGroup />}
            />
            <Route
              path="/admin/masters/LedgerGroup"
              element={<CreateLedgerGroup />}
            />
            <Route
              path="/admin/masters/LedgerEntry/data"
              element={<LedgerEntry />}
            />
            <Route
              path="/admin/masters/LedgerEntry"
              element={<CreateLedgerEntry />}
            />
            <Route
              path="/admin/vouchers/CreditNotes/data"
              element={<CreditNotes />}
            />
            <Route
              path="/admin/vouchers/CreditNotes"
              element={<CreateCreditNotes />}
            />
            <Route
              path="/admin/vouchers/DebitNotes/data"
              element={<DebitNotes />}
            />
            <Route
              path="/admin/vouchers/DebitNotes"
              element={<NewDebitNotes />}
            />
            <Route path="/admin/vouchers/Contra/data" element={<Contra />} />
            <Route path="/admin/vouchers/Contra" element={<CreateContra />} />
            <Route path="/admin/MakeModal/data" element={<MakeModal />} />
            <Route path="/admin/MakeModal" element={<CreateMakeModal />} />
            <Route path="/admin/MakeModal/:id" element={<CreateMakeModal />} />

            <Route
              path="/admin/vouchers/Purchase/Create"
              element={<CreatePurchase />}
            />
            <Route path="/admin/vouchers/Purchase" element={<Purchase />} />
            <Route
              path="/admin/vouchers/Purchase/edit"
              element={<EditPurchase />}
            />
            <Route path="/admin/view/Purchase/:id" element={<ViewPurchase />} />
            <Route
              path="/admin/vouchers/Sale/Create"
              element={<CreateSale />}
            />
            <Route path="/admin/vouchers/Sale" element={<Sale />} />
            <Route path="/admin/vouchers/Sale/:id" element={<EditSale />} />
            {/* <Route path="/admin/Sale/:id" element={<EditSale />} /> */}
            <Route path="/admin/viewSale/:id" element={<ViewSale />} />
            <Route
              path="/admin/vouchers/Create/Purchase/Return"
              element={<CreatePurchaseReturn />}
            />
            <Route
              path="/admin/vouchers/Purchase/Return"
              element={<PurchaseReturn />}
            />
            <Route
              path="/admin/view/Purchase/Return/:id"
              element={<ViewPurchaseReturn />}
            />
            <Route
              path="/admin/POS/Billing/:id"
              element={<PurchaseBilling />}
            />
            <Route
              path="/admin/vouchers/Purchase/Return/:id"
              element={<EditPurchaseReturn />}
            />

            <Route
              path="/admin/vouchers/Create/Sale/Return"
              element={<CreateSaleReturn />}
            />
            <Route
              path="/admin/vouchers/Sale/Return"
              element={<SaleReturnn />}
            />
            <Route
              path="/admin/view/Sale/Return/:id"
              element={<ViewSaleReturn />}
            />

            <Route
              path="/admin/vouchers/Sale/Return/:id"
              element={<EditSaleReturn />}
            />
            <Route
              path="/admin/vouchers/Sale/Return/:id"
              element={<SaleReturnExcel />}
            />
            <Route
              path="/admin/vouchers/Sale/Return/:id"
              element={<NewReturnSaleExcel />}
            />
            <Route
              path="/admin/Check/POS/Commission"
              element={<CommissionTableForPos />}
            />
            <Route
              path="/admin/masters/RtoGroupChecker"
              element={<CreateRtoGroupChecker />}
            />
            <Route
              path="/admin/masters/RtoGroupChecker/data"
              element={<RtoGroupChecker />}
            />
            <Route path="/" element={<Dashboard />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </>
      }

      {/* <Route path="*" element={<Login />} /> */}
    </Routes>
  );
};

export default BackendRoute;
