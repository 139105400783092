import React from "react";
import { Tab, Tabs } from "@mui/material";
import { BiTimer } from "react-icons/bi";
import { AiOutlineStop } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import { BsShieldCheck } from "react-icons/bs";
import { MdPendingActions } from "react-icons/md";

const PolicyTab = ({ TabsValue, onChangeFilter, Status }) => {
  return (
    <Tabs
      value={TabsValue}
      onChange={(ele, value) => {
        onChangeFilter("TabsValue", value);
      }}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary icon position tabs example"
    >
      <Tab
        icon={<BiTimer style={{ color: "blue" }} />}
        value="ALL"
        iconPosition="start"
        label={
          <span
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              textTransform: "capitalize",
            }}
          >
            {`All ${Status["ALL"] ?? ""}`}
          </span>
        }
      />
      <Tab
        icon={<BiTimer style={{ color: "blue" }} />}
        value="PENDING"
        iconPosition="start"
        label={
          <span
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              textTransform: "capitalize",
            }}
          >
            {`Pending ${Status["PENDING"] ?? ""}`}
          </span>
        }
      />
      <Tab
        icon={<BsShieldCheck style={{ color: "green" }} />}
        value="APPROVED"
        iconPosition="start"
        label={
          <span
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              textTransform: "capitalize",
            }}
          >
            {`Approved ${Status["APPROVED"] ?? ""}`}
          </span>
        }
      />
      <Tab
        icon={<MdPendingActions style={{ color: "#E8960F" }} />}
        value="HOLD"
        iconPosition="start"
        label={
          <span
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              textTransform: "capitalize",
            }}
          >
            {`Hold ${Status["HOLD"] ?? ""}`}
          </span>
        }
      />
      <Tab
        icon={<AiOutlineStop style={{ color: "red" }} />}
        value="REJECTED"
        iconPosition="start"
        label={
          <span
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              textTransform: "capitalize",
            }}
          >
            {`Rejected ${Status["REJECTED"] ?? ""}`}
          </span>
        }
      />
      <Tab
        icon={<GiCancel style={{ color: "red" }} />}
        value="CANCELLED"
        iconPosition="start"
        label={
          <span
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              textTransform: "capitalize",
            }}
          >
            {`Cancelled ${Status["CANCELLED"] ?? ""}`}
          </span>
        }
      />
    </Tabs>
  );
};

export default PolicyTab;
