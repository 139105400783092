export const calculateCPMargin = (
  foundCPNewMargin,
  foundPOSNewMargin,
  value
) => {
  const inflowAmount = value?.Inflow ? +value?.Inflow : null;

  let cpMargin = "-";

  if (!foundCPNewMargin) {
    cpMargin = foundPOSNewMargin
      ? foundPOSNewMargin?.basedOn === "CPMargin"
        ? foundPOSNewMargin?.CPMargin
        : inflowAmount -
          foundPOSNewMargin?.POSRate -
          foundPOSNewMargin?.HOMargin
      : value?.POS?.Club?.CPMargin;
  } else {
    cpMargin = inflowAmount - foundCPNewMargin?.HOMargin - value?.POSRate;
  }
  return cpMargin;
};

export const calculateHOMargin = (
  foundCPNewMargin,
  foundPOSNewMargin,
  value
) => {
  let hoMargin;

  if (!foundCPNewMargin) {
    hoMargin = foundPOSNewMargin
      ? foundPOSNewMargin?.HOMargin
      : value?.POS?.Club?.HOMargin;
  } else if (foundCPNewMargin) {
    hoMargin = +foundCPNewMargin?.HOMargin;
  }
  return hoMargin;
};

export const calculateOutflow = (
  foundCPNewMargin,
  foundPOSNewMargin,
  value
) => {
  const inflowAmount = value?.Inflow ? +value?.Inflow : null;

  let marginOffset = "-";

  if (inflowAmount && !foundCPNewMargin) {
    const HOMargin = foundPOSNewMargin?.HOMargin ?? 0;

    const CPMargin = foundPOSNewMargin?.CPMargin ?? 0;

    const POSRate = foundPOSNewMargin?.POSRate ?? 0;

    const basedOn = foundPOSNewMargin?.basedOn || null;

    if (
      foundPOSNewMargin &&
      HOMargin != null &&
      CPMargin != null &&
      basedOn === "CPMargin"
    ) {
      marginOffset = inflowAmount - HOMargin - CPMargin;
    } else if (foundPOSNewMargin && basedOn === "CPPMargin") {
      marginOffset = POSRate;
    } else if (!foundPOSNewMargin) {
      marginOffset =
        inflowAmount -
        +(value?.POS?.Club?.HOMargin ?? 0) -
        +(value?.POS?.Club?.CPMargin ?? 0);
    }
  } else {
    marginOffset = value?.POSRate;
  }
  return marginOffset;
};

export const calculateTPOutflow = (
  foundCPNewMargin,
  foundPOSNewMargin,
  value
) => {
  const inflowAmount = value?.InflowTP ? +value?.InflowTP : null;

  let marginOffset = "-";

  if (inflowAmount && !foundCPNewMargin) {
    const HOMargin = foundPOSNewMargin?.HOMargin ?? 0;

    const CPMargin = foundPOSNewMargin?.CPMargin ?? 0;

    const POSRate = foundPOSNewMargin?.POSRate ?? 0;

    const basedOn = foundPOSNewMargin?.basedOn || null;

    if (
      foundPOSNewMargin &&
      HOMargin != null &&
      CPMargin != null &&
      basedOn === "CPMargin"
    ) {
      marginOffset = inflowAmount - HOMargin - CPMargin;
    } else if (foundPOSNewMargin && basedOn === "CPPMargin") {
      marginOffset = POSRate;
    } else if (!foundPOSNewMargin) {
      marginOffset =
        inflowAmount -
        +(value?.POS?.Club?.HOMargin ?? 0) -
        +(value?.POS?.Club?.CPMargin ?? 0);
    }
  } else {
    marginOffset = value?.POSRate;
  }
  return marginOffset;
};

export const calculateTotalAmount = (
  value,
  percentage = 0,
  TPPercentage = 0
) => {
  switch (value.BasedOn) {
    case "FLAT":
      return (+value.Inflow).toFixed(0);

    case "NET":
      return ((+value.NETPremium / 100) * percentage).toFixed(0);

    case "OD":
      return (
        ((+value.ODPremium + +value.AddOnPremium) / 100) *
        +percentage
      ).toFixed(0);

    case "TP":
      return (
        ((+value.TPPremium + +value.PAPremium + +value.LLPremium) / 100) *
        +percentage
      ).toFixed(0);

    case "OD+TP":
      //  value.Inflow if for OD &
      //  value.InflowTo if for TP

      const ODAmount =
        ((+value.ODPremium + +value.AddOnPremium) / 100) * +percentage;

      const TPAmount =
        ((+value.TPPremium + +value.PAPremium + +value.LLPremium) / 100) *
        +TPPercentage;

      return {
        ODAmount: ODAmount.toFixed(0),
        TPAmount: TPAmount.toFixed(0),
      };

    case "OD+TP (Exc. PA)":
      //  value.Inflow if for OD &
      //  value.InflowTo if for TP

      const TotalODAmount =
        ((+value.ODPremium + +value.AddOnPremium) / 100) * +percentage;

      const TotalTPAmount =
        ((+value.TPPremium + +value.LLPremium) / 100) * +TPPercentage;

      return {
        ODAmount: TotalODAmount.toFixed(0),
        "TP (Exc. PA)Amount": TotalTPAmount.toFixed(0),
      };

    default:
      return null;
  }
};

export const calculateMarginAmount = (totalAmount, margin) =>
  (totalAmount / 100) * margin;

export const calculateHOAmount = (totalAmount, hoMargin) =>
  totalAmount - (totalAmount / 100) * hoMargin;

export const calculateCPAmount = (remainingAmount, cpMargin) =>
  (remainingAmount / 100) * cpMargin;
