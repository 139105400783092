import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { BiSearchAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { convertDateStringToDate } from "../../Utils/usefullFunction";
import DateRange from "../DateRangeModule/DateRange";
import { useSelector } from "react-redux";

import { EmployeeReportcolumns, transformData } from "../QC/helper";
import ExcelExport from "../utils/ExcelExport/ExcelExport";

const EmployeeReport = ({ isReport }) => {
  const today = new Date();
  const { user } = useSelector((state) => state.user);

  // 2023-05-05
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [loading, setLoading] = useState(false);
  const [StartDate, setStartDate] = useState(firstDay);
  const [EndDate, setEndDate] = useState(lastDay);
  const [data, setData] = useState();
  const branchCode = user?.Branch?.BranchCode || null;
  const branchId = branchCode !== "0" ? user?.Branch?._id : "";

  const getData = async () => {
    setLoading(true);
    try {
      const url = `/Policy/getPolicyCountByEmployee?startDate=${StartDate}&endDate=${EndDate}&isReport=${isReport}&branchId=${branchId}`;
      const {
        data: { data },
      } = await axiosInstance.get(url);

      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const setDateHandler = (ranges) => {
    setEndDate(convertDateStringToDate(ranges.endDate));
    setStartDate(convertDateStringToDate(ranges.startDate));
  };
  useEffect(() => {
    getData();
  }, [isReport, branchId]);

  const downloadReport = async () => {
    try {
      const response = await axiosInstance.get("Excel/createBranchWiseReport", {
        responseType: "blob", // Ensures the response is treated as a Blob
      });

      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : "report.xlsx";

      // Create a link element to trigger the download
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  const getExcelData = async () => {
    try {

      const ids = data.map(item => item._id);
      const today = new Date();
      const firstDay = `${today.toISOString().substr(0, 7)}-01`;
      const lastDay = today.toISOString().substr(0, 10);
      const response = await axiosInstance.post("/Policy/getPoliciedByEnteredBy", {
        ids,
        firstDay,
        lastDay,
      });
      // const columns = [
      //   { header: "Name", key: "Name", width: 20 },
      //   { header: "Total Count", key: "Count", width: 15 },
      //   { header: "Motor Count", key: "MotorCount", width: 15 },
      //   { header: "Non-Motor Count", key: "NonMotorCount", width: 25 },
      //   { header: "Motor Gross Premium", key: "MotorGrossPremium", width: 25 },
      //   { header: "Non-Motor Gross Premium", key: "NonMotorGrossPremium", width: 25 }
      // ];

      // return { columns, rows: data, fileName: "Report List" };
      const rows = await transformData(response.data.data);

      return {
        columns: EmployeeReportcolumns,
        rows,
        fileName: `ReportList`,
      };
    } catch (error) {
      console.error("Error:", error);
      return { columns: [], rows: [], fileName: "Report List" };
    }
  };

  return (
    <>
      <div
        className="container-fluid"
      // style={{ marginTop: "70px", zoom: "85%" }}
      >
        <div className="row ">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div
                className="col-lg-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <input
                  className="form-control "
                  type="date"
                  name="startDate"
                  value={StartDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  // placeholder="Enter  PolicyNumber"
                  required //done
                />

                <p>-</p>
                <input
                  className="form-control "
                  type="date"
                  name="endDate"
                  value={EndDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  // placeholder="Enter  PolicyNumber"
                  required //done
                />
                <DateRange setDateHandler={setDateHandler} />
              </div>
              <div className="">
                <BiSearchAlt
                  size={32}
                  onClick={() => getData()}
                  style={{
                    backgroundColor: "#174379",
                    color: "#fff",
                    padding: "5px",
                    borderRadius: "5px",
                    marginTop: "2px",
                  }}
                />
                &nbsp;

                <Link>
                  <RxReset
                    onClick={() => {
                      setStartDate(firstDay);
                      setEndDate(lastDay);
                    }}
                    size={32}
                    style={{
                      backgroundColor: "#E8960F",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "5px",
                      marginTop: "2px",
                    }}
                  />
                </Link>
                <ExcelExport getData={getExcelData} /> &nbsp;
                {/* <button className="mybtn btn" onClick={downloadReport}>
                  Download Report
                </button> */}
              </div>
            </div>
            <div className="row">
              <div className="col-10" style={{ float: "left" }}></div>
              <div className="col-2" style={{ float: "left" }}></div>
            </div>
            {loading ? (
              <div
                className=" p-2 container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TableSkeleton />
              </div>
            ) : (
              <>
                <div
                  class="table-responsive"
                  style={{
                    zoom: "85%",
                  }}
                >
                  <table class="table table-striped table-bordered mt-3">
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}>
                          SNo.
                        </th>
                        <th scope="row" style={{ textAlign: "center" }}>
                          Name
                        </th>
                        <th scope="row" style={{ textAlign: "center" }}>
                          Motor Count
                        </th>
                        <th scope="row" style={{ textAlign: "center" }}>
                          Motor GWP
                        </th>
                        <th scope="row" style={{ textAlign: "center" }}>
                          Non-Motor Count
                        </th>
                        <th scope="row" style={{ textAlign: "center" }}>
                          Non-Motor GWP
                        </th>
                        <th scope="row">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length > 0 &&
                        data.map((ele, index) => {
                          const {
                            Name = "",
                            Salutation = "",
                            MiddleName = "",
                            LastName = "",
                            Count = 0,
                          } = ele;
                          return (
                            <tr key={ele._id}>
                              <td> {index + 1}</td>
                              <td
                                style={{ textAlign: "left" }}
                              >{`${Salutation} ${Name} ${MiddleName} ${LastName}`}</td>
                              <td style={{ textAlign: "right" }}>
                                {ele?.MotorCount}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(ele?.MotorGrossPremium).toFixed(0)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {ele?.NonMotorCount}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(ele?.NonMotorGrossPremium).toFixed(0)}
                              </td>
                              <td style={{ textAlign: "right" }}>{Count}</td>
                            </tr>
                          );
                        })}

                      <tr>
                        <td></td>
                        <td>
                          <b> Total:- </b>{" "}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {data &&
                              data.length > 0 &&
                              data.reduce((acc, it) => acc + it.MotorCount, 0)}
                          </b>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {data &&
                              data.length > 0 &&
                              data
                                .reduce(
                                  (acc, it) => acc + it.MotorGrossPremium,
                                  0
                                )
                                .toFixed(0)}
                          </b>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {data &&
                              data.length > 0 &&
                              data.reduce(
                                (acc, it) => acc + it.NonMotorCount,
                                0
                              )}
                          </b>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {data &&
                              data.length > 0 &&
                              data
                                .reduce(
                                  (acc, it) => acc + it.NonMotorGrossPremium,
                                  0
                                )
                                .toFixed(0)}
                          </b>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {data &&
                              data.length > 0 &&
                              data.reduce((acc, it) => acc + it.Count, 0)}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeReport;
