import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import Select from "react-select";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
    removeEmptyValues,
} from "../../Utils/usefullFunction";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import useGettingData from "../Hooks/useGettingData";
import { useDispatch, useSelector } from "react-redux";

const BulkUpdatePayoutGrid = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        PayoutGrid,
        InsuranceCompany,
        PolicyType,
        pagination,
        rowsPerPage,
        SavedData,
        SavedTotalDocs,
        SelectedInsuranceType,
        RTOGroup,
    } = useSelector((store) => store.PayoutGridFilters);

    const {
        getData: getInsuranceCompany,
        Loading: CompanyLoading,
        Options: InsuranceCompanyOpt,
    } = useGettingData("/InsuranceCompany", [
        {
            _id: "",
            AliasName: "-- Select Company --",
        },
    ]);
    const {
        getData: getPolicyType,
        Loading: PolicyTypeLoading,
        Options: PolicyTypeOpt,
    } = useGettingData("/PolicyType", [
        {
            _id: "",
            PolicyTypeName: "-- Select PolicyType --",
        },
    ]);
    const [RTOGroupOpt, setRTOGroupOpt] = useState([]);
    const [IsFirstRendering, setIsFirstRendering] = useState(true);
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [records, setRecords] = useState();
    const [toDate, setToDate] = useState("");
    const [InsuranceCompanyid, setInsuranceCompanyid] = useState();
    const [updateDate, setupdateDate] = useState("");

    const handleChangePage = (event, newPage) => {
        onSetPolicyFilter("pagination", newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        onSetPolicyFilter("rowsPerPage", parseInt(event.target.value));
        onSetPolicyFilter("pagination", 0);
    };

    const onSetPolicyFilter = (key, value) => {
        dispatch({
            type: "updatePayoutGridFilters",
            key: key,
            value: value,
        });
    };

    useEffect(() => {
        if (InsuranceCompany && SelectedInsuranceType.length > 0) {

            EraserHandler()
        }
    }, []);

    const deletePayoutGrid = async (_id) => {
        try {
            const {
                data: { success, message },
            } = await axiosInstance.delete(`/PayoutGrid/${_id}`);

            if (success) {
                filterDataHandler();
                ToastSusses(message);
            } else {
                ToastSusses(message);
            }
        } catch (error) {
            ToastError(error?.response?.data?.message);
        }
    };

    const getRTOGroupOpt = async (insid, policyTypeid) => {
        try {
            const {
                data: { data },
            } = await axiosInstance.get(
                `/RtoGroupChecker/getRtoGroupSelectDrpDown?InsuranceCompany=${insid}&DEF=${fromDate}&DET=${toDate}&PolicyType=${policyTypeid}`
            );
            setRTOGroupOpt(data);
        } catch (error) {
            console.error(error);
        }
    };

    const onChangeSetValueStart = (e) => {
        setFromDate(e.target.value || "")
        onSetPolicyFilter("PayoutGrid", {
            ...PayoutGrid,
            [e.target.name]: e.target.value,
        });
    };

    const onChangeSetValueEnd = (e) => {
        const value = e.target.value || "";
        setToDate(value);
        onSetPolicyFilter("PayoutGrid", {
            ...PayoutGrid,
            [e.target.name]: e.target.value,
        });


    };

    useEffect(() => {
        if (toDate) {

            getRTOGroupOpt(InsuranceCompanyid, "");
        }
    }, [toDate]);

    const filterDataHandler = async (isEraser) => {
        try {
            setLoading(true);

            const SelectedInsuranceTypeData = SelectedInsuranceType.map(
                (ele) => ele.value
            ).filter((ele) => ele !== "");

            const insuranceType = SelectedInsuranceTypeData.length - 1;
            let que = {};
            if (isEraser) {
                // que["startDate"] = firstDay;
                // que["endDate"] = lastDay;
            } else {
                que = {
                    ...PayoutGrid,
                    InsuranceType: SelectedInsuranceTypeData[insuranceType],
                };
                if (InsuranceCompany._id) {
                    que["InsuranceCompany"] = InsuranceCompany._id;
                }

                if (PolicyType._id) {
                    que["PolicyType"] = PolicyType._id;
                }


                if (RTOGroup._id) {
                    que["RTOGroup"] = RTOGroup._id;
                }
            }
            removeEmptyValues(que);

            const {
                data: { data, totalDocs },
            } = await axiosInstance.post("/PayoutGrid/getPayoutGridByFilter", {
                page: pagination + 1,
                limit: rowsPerPage,
                ...que,
            });
            onSetPolicyFilter("SavedData", data);
            onSetPolicyFilter("SavedTotalDocs", totalDocs);

            setRecords(totalDocs)

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const EraserHandler = async () => {
        filterDataHandler(true);
        dispatch({
            type: "setPayoutGridFilterDefaultValue",
        });
        const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };
        onSetPolicyFilter("SelectedInsuranceType", [reSetData]);
    };

    const callInsuranceData = async (InsuranceType) => {
        const length = InsuranceType?.length;
        if (length) {
            const myInsuranceType = [];

            try {
                const {
                    data: { data },
                } = await axiosInstance.get("/InsuranceType?Root=true");
                myInsuranceType.push({ data, value: InsuranceType[0] });
            } catch (error) { }
            for (let index = 1; index < length; index++) {
                try {
                    const {
                        data: { data = [] },
                    } = await axiosInstance.get(
                        `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]
                        }}&InsuranceCompany=${PayoutGrid?.InsuranceCompany ?? ""}`
                    );
                    await myInsuranceType.push({
                        data,
                        value: InsuranceType[index],
                    });
                } catch (error) { }
            }

            return onSetPolicyFilter("SelectedInsuranceType", [
                ...SelectedInsuranceType,
                ...myInsuranceType,
            ]);
        } else {
            const {
                data: { data },
            } = await axiosInstance.get("/InsuranceType?Root=true");
            onSetPolicyFilter("SelectedInsuranceType", [{ data, value: "" }]);
        }
    };


    useEffect(() => {
        if (IsFirstRendering) {
            setIsFirstRendering(false);
            if (SavedData.length === 0) {
                filterDataHandler();
            }
        } else {
            filterDataHandler();
        }
    }, [pagination, rowsPerPage]);
    useEffect(() => {
        if (SelectedInsuranceType.length === 0) {
            callInsuranceData();
        }
        getInsuranceCompany();
        getPolicyType();
    }, []);





    const deleteRecordsHandler = async () => {
        if (!fromDate) {
            ToastError("Date filter is required");
            return false;
        }
        if (!toDate) {
            ToastError("Date filter is required");
            return false;
        }
        if (!InsuranceCompanyid) {
            ToastError("Insurance Company is required");
            return false;
        }
        const isConfirmed = window.confirm("Are you sure you want to Update the records?");
        if (!isConfirmed) {
            return;
        }
        try {
            const response = await axiosInstance.delete(`/PayoutGrid/deleteBulk?fromDate=${fromDate}&toDate=${toDate}&InsuranceCompanyid=${InsuranceCompanyid}`)

            if (response.status === 200) {
                ToastSusses(response.data.message);

                navigate("/admin/masters/PayoutGrid/data");
            }
        } catch (error) {

            ToastError(error?.response?.data?.message || "An error occurred");
        }
    };

    console.log("InsuranceCompany", InsuranceCompany)
    return (
        <>
            <Header />
            <div className="MainDiv MainDivPadding">
                <div className="container-fluid">
                    <div className="row CreateCard">
                        <div className="row mt-4">
                            <div className="col-8" style={{ float: "left" }}>
                                <div className="ListDesigncol">
                                    <h4 className="card-title">Bulk Update Payout Grid</h4>

                                </div>
                            </div>

                        </div>

                        <div
                            className=" p-2 sub-container filters"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "unset",
                                flexDirection: "row",
                                gap: "10px",
                                zoom: "75%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "baseline",
                                    flexDirection: "row",
                                    gap: "10px",
                                    marginTop: "10px!important"
                                }}
                            >
                                <div
                                    onClick={() => {
                                        getInsuranceCompany();
                                    }}
                                >
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        options={InsuranceCompanyOpt}
                                        noOptionsMessage={() => "No Company Found"}
                                        isLoading={CompanyLoading}
                                        getOptionValue={(option) => option["_id"]}
                                        getOptionLabel={(option) => option["AliasName"]}
                                        onChange={(ele) => {
                                            onSetPolicyFilter("InsuranceCompany", ele);

                                            if (ele._id) {
                                                setRTOGroupOpt([]);
                                                onSetPolicyFilter("InsuranceCompany", ele);
                                                onSetPolicyFilter("RTOGroup", {
                                                    _id: "",
                                                    GroupName: "-- Select RTO Group --",
                                                });
                                                setInsuranceCompanyid(ele._id)
                                                getRTOGroupOpt(ele._id, "");
                                            }
                                        }}
                                        value={InsuranceCompany}
                                    />
                                </div>

                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "baseline",
                                        flexDirection: "row",
                                        gap: "10px",
                                    }}
                                >
                                    <input
                                        className="form-control "
                                        type="date"
                                        name="startDate"
                                        value={PayoutGrid?.startDate}
                                        onChange={(e) => onChangeSetValueStart(e)}
                                        required //done
                                    />

                                    <p>TO</p>
                                    <input
                                        className="form-control "
                                        type="date"
                                        name="endDate"
                                        value={PayoutGrid?.endDate}
                                        onChange={(e) => onChangeSetValueEnd(e)}
                                        required //done
                                    />
                                </div>




                                <div></div>

                            </div>
                            <div className="d-flex gap-2">
                                <div
                                    className="searchBox"
                                    style={{
                                        width: "77px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => filterDataHandler()}
                                >
                                    <MdSend />
                                </div>

                                <div
                                    className="searchBox"
                                    style={{
                                        width: "77px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => EraserHandler()}
                                >
                                    <BsEraser />
                                </div>
                            </div>

                        </div>
                        <div
                            className=" p-2 sub-container filters"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "unset",
                                flexDirection: "row",
                                gap: "10px",
                                zoom: "75%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "baseline",
                                    flexDirection: "row",
                                    gap: "10px",
                                }}
                            >

                            </div>
                        </div>
                        {records > 0 && (
                            <div style={{ background: "white", borderRadius: "8px", textAlign: "left" }}>

                                <div style={{ display: "flex", marginBottom: "12px" }}>


                                    <div className="col-6" style={{ textAlign: "left" }}>
                                        <p style={{ fontSize: "16px", marginBottom: "0", marginTop: "10px", fontWeight: "bold" }}>
                                            {records} record(s) found
                                        </p>
                                    </div>


                                    <div className="col-6" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <span style={{ marginLeft: "10px" }}>
                                        <label htmlFor="dateInp" style={{ marginRight: "10px", fontWeight: "bold" }}>DET:</label>
                                            <input
                                             id="dateInp"
                                                type="date"
                                                name="Date"
                                                value={updateDate}
                                                onChange={(e) => setupdateDate(e.target.value)}
                                                required
                                                style={{
                                                    padding: "6px 8px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "5px",
                                                    fontSize: "14px",
                                                }}
                                            />
                                        </span> &nbsp;
                                        <button
                                            onClick={deleteRecordsHandler}
                                             className="btn mybtn"
                                        >
                                            Update
                                        </button>
                                    </div>

                                </div>

                            </div>
                        )}




                        {loading ? (
                            <div
                                className="sub-container"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <TableSkeleton />
                            </div>
                        ) : (
                            <div
                                className=" p-2 sub-container"
                                style={{
                                    zoom: "65%",
                                }}
                            >
                                {SavedData && (
                                    <table class="table table-striped   table-bordered  overflow-x mt-3">
                                        <thead>
                                            <tr style={{ background: "#174379", color: "#fff" }}>
                                                <th scope="col">S No.</th>
                                                <th scope="col">DEF</th>
                                                <th scope="col">DET</th>
                                                <th scope="col">Insurance Company</th>
                                                <th scope="col">Policy Type</th>
                                                <th scope="col">Product</th>
                                                <th scope="col">FuelType</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">GVW</th>
                                                <th scope="col">Seater</th>
                                                <th scope="col">RTO Cluster</th>
                                                {/* <th scope="col">fuel type</th> */}

                                                <th scope="col">Age </th>
                                                <th scope="col">based on </th>
                                                {/* <th scope="col">IMTType </th>
                        <th scope="col">NCBSTATUS </th> */}
                                                <th scope="col">Inflow </th>
                                                <th scope="col">EXTRA_OPTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {SavedData &&
                                                SavedData.length > 0 &&
                                                SavedData.map((data, index) => (
                                                    <tr key={data._id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{data?.DEF || ""}</td>
                                                        <td>{data?.DET || ""}</td>
                                                        <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                                                        <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                                                        <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                                                        <td>{data?.FuelType?.FuelType || ""}</td>
                                                        <td>{data?.MakeModal?.Make || ""}</td>
                                                        <td>{data?.Model?.Model || ""}</td>
                                                        <td>{`${data?.GVWFrom || ""}-${data?.GVWTo || ""
                                                            }`}</td>
                                                        <td>{`${data?.seaterFrom || ""}-${data?.seaterTo || ""
                                                            }`}</td>
                                                        <td>{data?.RTOGroup?.GroupName || ""}</td>
                                                        <td>{`${data?.FromAge ?? ""}-${data?.ToAge || ""
                                                            }`}</td>
                                                        <td>{data?.BasedOn || ""}</td>
                                                        <td>
                                                            <td>{data?.Reciveable || ""}</td>
                                                            {/* <table className="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th
                                      scope="col"
                                      colSpan={
                                        data?.BasedOn
                                          ? data.BasedOn.split("+").length
                                          : 1
                                      }
                                    >
                                      Receivable
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row"></th>
                                    <th scope="row"></th>
                                    {data?.BasedOn?.split("+").map(
                                      (ele, idx) => (
                                        <td key={idx}>{ele}</td>
                                      )
                                    )}
                                  </tr>

                                  {data?.AddDetails.map((ele, idx) => (
                                    <tr key={idx}>
                                      <th scope="row">{idx + 1}</th>
                                      <th scope="row">
                                        {ele?.DEF?.split("T")[0]
                                          .split("-")
                                          .reverse()
                                          .join("-") || "N/A"}
                                      </th>
                                      {data?.BasedOn?.split("+").map(
                                        (based, idx) => (
                                          <td key={idx}>
                                            {ele[`Reciveable${based}`] || "N/A"}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table> */}
                                                        </td>
                                                        <td>
                                                            <div className="col-md-2">
                                                                <div className="d-flex gap-3">
                                                                    <div>
                                                                        <Link
                                                                            state={{ view: true }}
                                                                            to={`/admin/masters/PayoutGrid/edit/${data._id}`}
                                                                        >
                                                                            <FaEye size={25} />
                                                                        </Link>
                                                                    </div>
                                                                    <div>
                                                                        <Link
                                                                            state={{ Mode: true }}
                                                                            to={`/admin/masters/PayoutGrid/edit/${data._id}`}
                                                                        >
                                                                            <BiEdit size={25} color="blue" />
                                                                        </Link>
                                                                    </div>
                                                                    <div>
                                                                        <AiFillDelete
                                                                            color="red"
                                                                            style={{ cursor: "pointer" }}
                                                                            size={25}
                                                                            onClick={() => {
                                                                                if (
                                                                                    window.confirm(
                                                                                        "Do you want to Update!"
                                                                                    )
                                                                                ) {
                                                                                    deletePayoutGrid(data?._id);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                )}
                                {
                                    <div
                                        style={{
                                            backgroundColor: "#1a2e45d7",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "start",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <TablePagination
                                            component="div"
                                            className="text-white"
                                            rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                                            count={SavedTotalDocs ?? 0}
                                            page={pagination}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BulkUpdatePayoutGrid;

//  <TableTransition
// AllKey={AllKey}
// data={data}
// editUrl={"/admin/PayoutGrid/edit"}
// Mode={true}
// deleteFunction={deletePayoutGrid}
// EditId={true}
// isPayoutGrid={true}
// />
