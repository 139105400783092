import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import BackendRoute from "./Utils/BackendRoute";
import { useDispatch } from "react-redux";
import { getMe } from "./Redux/Actions/userAction";

import { axiosInstance } from "./config";
import { ToastError, ToastSusses } from "./Utils/Tost";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function App() {
  const dispatch = useDispatch();
  // const { isAuthenticated } = useSelector((store) => store.user);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    // Auto-logout after 30 minutes of inactivity
    let inactivityTimeout;

    const resetInactivityTimeout = () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        logoutHandler();
      }, 3600000);
    };
    const logoutHandler = async () => {
      try {
        dispatch({
          type: "logoutRequest",
        });
        const {
          data: { success, message },
        } = await axiosInstance.get("/Employee/Logout");
        if (success) {
          ToastSusses(message);
        } else {
          ToastSusses(message);
        }
        dispatch({
          type: "logoutSuccess",
        });
      } catch (error) {
        dispatch({
          type: "logoutFailure",
        });
        ToastError(error?.response?.data?.message);
      }
    };

    // Add event listeners for user activity
    window.addEventListener("mousemove", resetInactivityTimeout);
    window.addEventListener("keydown", resetInactivityTimeout);
    window.addEventListener("click", resetInactivityTimeout);

    // Reset the timer when the component mounts
    resetInactivityTimeout();

    // Clean up event listeners and timeout when component unmounts
    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener("mousemove", resetInactivityTimeout);
      window.removeEventListener("keydown", resetInactivityTimeout);
      window.removeEventListener("click", resetInactivityTimeout);
    };
  }, [dispatch]);

  return (
    <BrowserRouter>
      <BackendRoute />
    </BrowserRouter>
  );
}

export default App;
