import React, { useEffect, useState } from "react";
import { ToastError } from "../../Utils/Tost";
import { axiosInstance } from "../../config";

import "./Dashboard.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Skeleton from "@mui/material/Skeleton";
import { Modal } from "@mui/material";
import { FaRegChartBar } from "react-icons/fa";
import { useSelector } from "react-redux";
import DoughnutChart from "../../components/DoughnutChart";
import BarChart from "../../components/BarChart";

const colors = [
  "#b87333",
  "silver",
  "gold",
  "#e5e4e2",
  "#ff6347",
  "#ff4500",
  "#4682b4",
  "#32cd32",
];

const initialState = {
  NOP: [],
  GWP: [],
  title: "",
};

const Dashboard = () => {
  const [InsuranceTypes, setInsuranceTypes] = useState([]);
  const [InsuranceCompanies, setInsuranceCompanies] = useState([]);
  const [PolicyStatue, setPolicyStatue] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [dataForLOB, setDataForLOB] = useState({
    NOP: [],
    GWP: [],
  });
  const [renderKey, setRenderKey] = useState(0);
  const [dataForPolicyType, setDataForPolicyType] = useState({
    NOP: [],
    GWP: [],
  });
  const [dataForBusinessType, setDataForBusinessType] = useState({
    NOP: [],
    GWP: [],
  });
  const [product, setProduct] = useState({
    motor: [],
    nonMotor: [],
  });
  const [subProduct, setSubProduct] = useState(initialState);
  const [dataForEmployeesPolicy, setDataForEmployeesPolicy] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user } = useSelector((store) => store.user);

  const userBranchCode = user?.Branch?.BranchCode;

  const userBranchId = user?.Branch?._id;

  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(2) + " Cr";
    } else if (num >= 100000) {
      return (num / 100000).toFixed(2) + " L";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(2) + " K";
    } else {
      return num;
    }
  };

  const getLobCount = async () => {
    setLoading(true);
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/groupByLOB`, {
        params: {
          branchId: branchIdParam,
        },
      });
      if (data) {
        const NOP = [];
        const GWP = [];
        data.forEach((item) => {
          NOP.push({
            value: item.NOP ?? 0,
            name: item._id,
          });
          GWP.push({
            value: Math.floor(item.GWP) ?? 0,
            name: item._id,
          });
        });
        setDataForLOB({ NOP, GWP });
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const getPolicyTypeCount = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/groupByPolicyType`, {
        params: { branchId: branchIdParam },
      });

      if (data) {
        const NOP = [];
        const GWP = [];
        data.forEach((item) => {
          // NOP.push({ value: item.NOP ?? 0, name: item.policyTypeName });
          GWP.push({
            value: Math.floor(item.GWP) ?? 0,
            name: `${item.policyTypeName} (${item.NOP ?? 0})`,
          });
        });
        setDataForPolicyType({ NOP, GWP });
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || error.message);
    }
  };

  const getBusinessTypeCount = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/groupByBusinessType`, {
        params: { branchId: branchIdParam },
      });
      if (data) {
        const NOP = [];
        const GWP = [];
        data.forEach((item) => {
          // NOP.push({ value: item.NOP ?? 0, name: item._id });
          GWP.push({
            value: Math.floor(item.GWP) ?? 0,
            name: `${item._id} (${item.NOP ?? 0})`,
          });
        });
        setDataForBusinessType({ NOP, GWP });
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || error.message);
    }
  };

  const getDataForEmployeesPolicy = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;
      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/groupByEnteredBy`, {
        params: {
          branchId: branchIdParam,
        },
      });
      setDataForEmployeesPolicy(data);
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const formattedData = [
    [
      "Element",
      "No of Policies",
      "Total Gross Premium",
      { role: "style" },
      { role: "tooltip", type: "string", p: { html: true } },
    ], // Added custom tooltip column
  ];

  dataForEmployeesPolicy.forEach((item, index) => {
    const tooltip = `
    Name - ${item.employeeName}
    Count -  ${formatNumber(item.count)} 
    Total Gross Premium - ${formatNumber(item.totalGrossPremium)}
    `;

    formattedData.push([
      item.employeeName,
      item.count,
      item.totalGrossPremium,
      colors[index % colors.length],
      tooltip,
    ]);
  });

  const groupByProduct = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const [motorResp, nonMotorResp] = await Promise.all([
        axiosInstance.post("/Policy/groupByProduct", {
          LOB: "Motor",
          branchId: branchIdParam,
        }),
        axiosInstance.post("/Policy/groupByProduct", {
          LOB: "Non-Motor",
          branchId: branchIdParam,
        }),
      ]);

      if (motorResp || nonMotorResp) {
        const formattedData = [];
        motorResp.data.data.forEach((item) => {
          formattedData.push({
            value: Math.floor(item.GWP) ?? 0,
            name: `${item.Products} (${item.NOP ?? 0})`,
          });
        });

        const formatedNonMotorData = [];
        nonMotorResp.data.data.forEach((item) => {
          formatedNonMotorData.push({
            value: Math.floor(item.GWP) ?? 0,
            name: `${item.Products} (${item.NOP ?? 0})`,
          });
        });

        setProduct({
          motor: formattedData,
          nonMotor: formatedNonMotorData,
        });
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || error.message);
    }
  };

  const groupBySubProduct = async (product, GVW) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Policy/groupBySubProduct?InsuranceType=${product}`
      );

      if (data) {
        const NOP = [];
        const GWP = [];
        data.forEach((item) => {
          // NOP.push({ value: item.NOP ?? 0, name: item._id });
          GWP.push({
            value: Math.floor(item.GWP) ?? 0,
            name: `${item.name} (${item.NOP ?? 0})`,
          });
        });
        setSubProduct({ NOP, GWP, title: `${product} GWP: (${GVW})` });
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || error.message);
    }
  };

  // Define the event handler
  const onChartClick = async (params) => {
    await groupBySubProduct(params.name, params.value);
  };

  useEffect(() => {
    Promise.all([
      getInsuranceTypes(),
      getInsurancecompanies(),
      getStatus(),
      getDataForEmployeesPolicy(),
      getLobCount(),
      getPolicyTypeCount(),
      getBusinessTypeCount(),
      groupByProduct(),
    ]);
  }, []);

  useEffect(() => {
    if (subProduct.GWP && subProduct.GWP.length > 0) {
      setOpenPopup(true);
    }
  }, [subProduct]);

  useEffect(() => {
    if (openPopup) {
      const timeout = setTimeout(() => {
        setRenderKey((prevKey) => prevKey + 1);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [openPopup]);

  const handleClosePopup = () => {
    setOpenPopup(false);
    setSubProduct(initialState);
  };

  const getInsurancecompanies = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/getPolicyReport`, {
        params: {
          dbName: "insurancecompanies",
          localFieldName: "InsuranceCompany",
          asToSave: "InsuranceCompany",
          groupUpKey: "InsuranceCompany.AliasName",
          branchId: branchIdParam,
        },
      });

      const formattedData = data.map(([name, count, gwp]) => ({
        name,
        count,
        gwp
      }));


      const xAxis = formattedData.map(item => item.name);
      const series = formattedData.map(item => item.count);
      const grossPremium = formattedData.map(item => item.gwp);

      const xAxisData = xAxis;
      const seriesData = [
        series,
        grossPremium,
      ];

      setInsuranceCompanies(formattedData);
      setXAxisData(xAxisData);
      setSeriesData(seriesData);
    } catch (error) {
      ToastError(
        error?.response?.data?.message || "Failed to fetch insurance companies"
      );
    }
  };
  const getStatus = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(
        `/Policy/getPolicyReportByFiledName?groupUpKey=Status`,
        {
          params: {
            branchId: branchIdParam,
          },
        }
      );

      const totalCount = data.reduce((sum, item) => sum + item.count, 0);

      data.forEach((ele, index) => {
        ele["percent"] = ((ele.count / totalCount) * 100).toFixed(3);
      });

      setPolicyStatue(data);
    } catch (error) {
      ToastError(
        error?.response?.data?.message || "Failed to fetch status data"
      );
    }
  };

  const getInsuranceTypes = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Policy/getPolicyReport?dbName=insurancetypes&localFieldName=InsuranceType&asToSave=InsuranceType&groupUpKey=InsuranceType.InsuranceType`
      );
      setInsuranceTypes([["", ""], ...data]);
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div data-topbar="dark" data-layout="horizontal" data-layout-size="boxed">
        <div style={{ marginTop: "7vh" }}>
          <div className="container-fluid" style={{ backgroundColor: "#f0f2f8", }}>
            <br />
            <div
              className="row"
              style={{
                display: "flex",
                flexWrap: "wrap",
                // paddingLeft: "1vw",
                padding: "0 5px",
                justifyContent: "space-between",
            
              }}
            >
              {PolicyStatue.map((data, i) => (
                <div className="col-sm-2" style={{ minWidth: "19.8vw" }} key={i}>
                  <div className="card mini-stat text-black" style={{ borderRadius: "6px" }}  >
                    <div className="card-body" >
                      <div className="mb-2">
                        <div className="float-start mini-stat-img me-3" style={{ backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                          <FaRegChartBar color="#0231bf" size="2em" />
                        </div>
                        <h5 className="font-size-14 text-uppercase text-black" style={{ fontWeight: 'bold' }}>
                          {data?._id}
                        </h5>
                        <h4 className="fw-medium font-size-18" style={{ fontWeight: 'bold' }}>
                          {data?.count}
                          <i className="mdi mdi-arrow-up text-success ms-2" />
                        </h4>
                        {/* <div className={`mini-stat-label ${data?._id}`}>
                          <p className="mb-0"> {data?.percent ?? 0}%</p>
                        </div> */}
                      </div>
                      {/* <div className="pt-0">
                        <div className="float-end">
                          <a href="#" className="text-white-50">
                          <i className="mdi mdi-arrow-right h5" />
                          </a>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">
                          Since last month
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* end row */}

            <div className="d-flex justify-content-center" style={{ marginLeft: "5px", backgroundColor: "#f0f2f8" }}>
              {/* <div className="col-lg-12"> */}
              <div className="mt-2" style={{ width: "100%", border: "none" }}>
                {InsuranceCompanies.length === 0 ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="340px"
                  />
                ) : (
                  <BarChart
                    height="340px"
                    // width="100%"
                    title="Insurance Company"
                    xAxisData={xAxisData}
                    seriesData={seriesData}
                  />
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-between"
        style={{ padding: "0 5px", backgroundColor: "#f0f2f8" }}
      >
        <div className="mt-2" style={{ width: "30%", border: "none" }}>
          <DoughnutChart
            height="400px"
            title="LOB (NOP)"
            data={dataForLOB.NOP}
          />
        </div>

        <div className="mt-2" style={{ width: "69.6%", border: "none" }}>
          <DoughnutChart
            height="400px"
            title="Motor"
            data={product.motor}
            onChartClick={onChartClick}
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-between"
        style={{ padding: "0 5px", backgroundColor: "#f0f2f8" }}
      >
        <div className="mt-2" style={{ width: "30%", border: "none" }}>
          <DoughnutChart
            height="430px"
            title="LOB (GWP)"
            data={dataForLOB.GWP}
          />
        </div>

        <div className="mt-2" style={{ width: "69.6%", border: "none" }}>
          <DoughnutChart
            height="430px"
            title="Non Motor"
            data={product.nonMotor}
            onChartClick={onChartClick}
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-between"
        style={{ padding: "0 5px", backgroundColor: "#f0f2f8" }}
      >
        <div className="my-2" style={{ width: "49.7%", border: "none" }}>
          <DoughnutChart
            height="350px"
            title="Policy Type"
            data={dataForPolicyType.GWP}
          />
        </div>

        <div className="my-2" style={{ width: "49.7%", border: "none" }}>
          <DoughnutChart
            height="350px"
            title="Business Type"
            data={dataForBusinessType.GWP}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      ></div>
      <Footer />

      {/* Popup Modal */}
      <Modal open={openPopup} onClose={handleClosePopup}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            maxWidth: "70%",
            width: "100%",
          }}
        >
          {subProduct?.GWP && subProduct?.GWP.length > 1 ? (
            <DoughnutChart
              height="500px"
              title={subProduct.title}
              data={subProduct?.GWP}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height="300px" />
          )}
          {/* <Button onClick={handleClosePopup} style={{ marginTop: "20px" }}>
            Close
          </Button> */}
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
