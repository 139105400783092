import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import TableTransition from "../TableTransition/TableTransition";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";

const BankBranch = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [inputData, setInput] = useState("");

  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(0);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/BankBranch?page=${pagination + 1}&limit=${rowsPerPage}&search=${inputData}`
      );
      const { data, totalDocs } = response.data;
      setData(data);
      setTotalDocs(totalDocs);
    } catch (error) {
      ToastError(error?.response?.data?.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const deleteBranch = async (_id) => {
    try {
      const response = await axiosInstance.delete(`/BankBranch/${_id}`);
      if (response.data.success) {
        ToastSusses(response.data.message);
        getData();
      } else {
        ToastError(response.data.message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || "Error deleting branch");
    }
  };

  useEffect(() => {
    getData();
  }, [pagination, rowsPerPage, inputData]);

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by Branch Name, IFSC Code, Branch Code"
      />
      <div className="MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="row">
                  <div className="col-10">
                    <h4 className="card-title">Branch Management</h4>
                  </div>
                  <div className="col-2">
                    <Link
                      className="btn mybtn"
                      to="/admin/masters/BankBranch"
                      state={{ Mode: false, value: "" }}
                    >
                      Add Branch
                    </Link>
                  </div>
                </div>
                {loading ? (
                  <TableSkeleton />
                ) : (
                  <>
                    <TableTransition
                      AllKey={[ "IfscCode", "BranchName", "BranchCode"]}
                      data={data}
                      editUrl="/admin/masters/BankBranch"
                      Mode={true}
                      deleteFunction={deleteBranch}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={totalDocs}
                        page={pagination}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BankBranch;
