import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { BsCheck2All } from "react-icons/bs";
import { GiCrossMark } from "react-icons/gi";
import "./RBAC.css";

import Test from "../../Utils/Test";

const RBAC = () => {
  const [data, setData] = useState([]);
  const [AllKey, setAllKey] = useState();
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [firstRender, setFirstRender] = useState(false);
  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };
  const getData = async () => {
    setLoading(true);
    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/RBAC?page=${pagination + 1}&limit=${rowsPerPage}`
      );

      let Keys = [];
      if (data[0]) {
        Keys = Object.keys(data[0]);
      }
      setTotalDocs(totalDocs);
      setAllKey(
        Keys.filter(
          (e) =>
            e !== "_id" && e !== "createdAt" && e !== "__v" && e !== "updatedAt"
        )
      );
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getDataByPageNation = async () => {
    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/RBAC?page=${pagination + 1
        }&limit=${rowsPerPage}&inputData=${inputData}`
      );

      let Keys = [];
      if (data[0]) {
        Keys = Object.keys(data[0]);
      }
      setTotalDocs(totalDocs);
      setAllKey(
        Keys.filter(
          (e) =>
            e !== "_id" && e !== "createdAt" && e !== "__v" && e !== "updatedAt"
        )
      );
      setData(data);
    } catch (error) { }
  };

  const deleteRBAC = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/RBAC/${_id}`);

      if (success) {
        getData();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    } else {
      const delayDebounceFn = setTimeout(getDataByPageNation, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [inputData]);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
      getDataByPageNation();
    }
  }, [pagination, rowsPerPage]);
  console.log("data",data)
  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, Roll... "
      />
      <div
        className="container-fluid"
        style={{ marginTop: "80px", zoom: "80%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="">
              <div className="card-body">
                <div className="row">
                  <div className="col-10" style={{ float: "left" }}></div>
                  <div className="col-2" style={{ float: "left" }}>
                    <Link
                      className="btn mybtn"
                      to="/admin/masters/AccessControl"
                      style={{ marginRight: "10px" }}
                      state={{
                        Mode: false,
                        value: "",

                      }}
                    >
                      Add Access Control
                    </Link>
                    <Test />
                  </div>
                </div>
                {loading ? (
                  <div
                    className=" p-2 container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <>
                    {
                      <table class="table table-striped table-bordered overflow-x mt-3">
                        <thead>
                          <tr className="text-center">
                            <th scope="col" style={{ textAlign: "center" }}>
                              S No.
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Roll
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Description
                            </th>

                            <th scope="col" style={{ textAlign: "center" }}>
                              Masters
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Data Entry
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Vouchers
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              QC
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Books
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Reports
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              CP Margin
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((ele, index) => (
                              <tr className="text-center">
                                <td scope="row">{index + 1}</td>
                                <td>{ele?.Roll}</td>
                                <td>{ele?.Description}</td>
                                {/* <td>
                                      {ele?.Read ? (
                                        <BsCheck2All className="BsCheck2All" />
                                      ) : (
                                        <GiCrossMark className="GiCrossMark" />
                                      )}
                                    </td>
                                    <td>
                                      {ele?.Create ? (
                                        <BsCheck2All className="BsCheck2All" />
                                      ) : (
                                        <GiCrossMark className="GiCrossMark" />
                                      )}
                                    </td>
                                    <td>
                                      {ele?.Edit ? (
                                        <BsCheck2All className="BsCheck2All" />
                                      ) : (
                                        <GiCrossMark className="GiCrossMark" />
                                      )}
                                    </td>
                                    <td>
                                      {ele?.Delete ? (
                                        <BsCheck2All className="BsCheck2All" />
                                      ) : (
                                        <GiCrossMark className="GiCrossMark" />
                                      )}
                                    </td>
                                    <td>
                                      {ele?.Download ? (
                                        <BsCheck2All className="BsCheck2All" />
                                      ) : (
                                        <GiCrossMark className="GiCrossMark" />
                                      )}
                                    </td> */}
                                <td>
                                  {ele?.Masters ? (
                                    <BsCheck2All className="BsCheck2All" />
                                  ) : (
                                    <GiCrossMark className="GiCrossMark" />
                                  )}
                                </td>
                                <td>
                                  {ele?.Policy ? (
                                    <BsCheck2All className="BsCheck2All" />
                                  ) : (
                                    <GiCrossMark className="GiCrossMark" />
                                  )}
                                </td>
                                <td>
                                  {ele?.Vouchers ? (
                                    <BsCheck2All className="BsCheck2All" />
                                  ) : (
                                    <GiCrossMark className="GiCrossMark" />
                                  )}
                                </td>
                                <td>
                                  {ele?.QC ? (
                                    <BsCheck2All className="BsCheck2All" />
                                  ) : (
                                    <GiCrossMark className="GiCrossMark" />
                                  )}
                                </td>
                                <td>
                                  {ele?.Books ? (
                                    <BsCheck2All className="BsCheck2All" />
                                  ) : (
                                    <GiCrossMark className="GiCrossMark" />
                                  )}
                                </td>
                                <td>
                                  {ele?.Reports ? (
                                    <BsCheck2All className="BsCheck2All" />
                                  ) : (
                                    <GiCrossMark className="GiCrossMark" />
                                  )}
                                </td>
                                <td>
                                  {ele?.CPMargin ? (
                                    <BsCheck2All className="BsCheck2All" />
                                  ) : (
                                    <GiCrossMark className="GiCrossMark" />
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <div className="d-flex gap-3 justify-content-center">
                                    <div>
                                      <Link
                                        className="col-md-1"
                                        state={{
                                          value: ele,
                                          view: true,
                                        }}
                                        to={`/admin/masters/AccessControl`}
                                      >
                                        <FaEye
                                          size={35}
                                          style={{
                                            backgroundColor: "#E8960F",
                                            color: "#fff",
                                            padding: "7px",
                                            borderRadius: "5px",
                                          }}
                                        />{" "}
                                      </Link>
                                    </div>
                                    {ele?.Roll !== "Administrator" && (
                                      <>
                                        <div>
                                          <Link
                                            className="col-md-1"
                                            state={{
                                              value: ele,
                                              Mode: true,
                                            }}
                                            to={`/admin/masters/AccessControl`}
                                          >
                                            <BiEdit
                                              size={35}
                                              style={{
                                                backgroundColor: "green",
                                                color: "#fff",
                                                padding: "7px",
                                                borderRadius: "5px",
                                              }}
                                            />{" "}
                                          </Link>
                                        </div>
                                        <div>
                                          <AiFillDelete
                                            size={35}
                                            style={{
                                              backgroundColor: "#A50406",
                                              color: "#fff",
                                              padding: "7px",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              const isTrue = window.confirm(
                                                "Do you want to delete!"
                                              );
                                              if (isTrue) {
                                                deleteRBAC(ele?._id);
                                              }
                                            }}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    }

                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      >
                        <TablePagination
                          component="div"
                          className="text-white"
                          rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                          count={totalDocs ?? 0}
                          page={pagination}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>

            {/* end col */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RBAC;
