import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { coverAmountIntoCurrent } from "../../Utils/usefullFunction";
const ViewGeneral = () => {
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const { id } = useParams();
  const [EntryDate, setEntryDate] = useState(lastDay);
  const [TotalCR, setTotalCR] = useState(0);
  const [TotalDR, setTotalDR] = useState(0);

  //   const [CashBankAccount, setCashBankAccount] = useState(
  //     value?.CashBankAccount || ""
  //   );
  const [Remark, setRemark] = useState("");

  const [GeneralVoucher, setGeneralVoucher] = useState([]);

  const GeneralVoucherHandler = (GeneralVoucher) => {
    const myArr = GeneralVoucher?.map((ele) => {
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Employee: EmployeeDetails,
        Broker: BrokerDetails,
        _id,
        ...rest
      } = ele;

      let obj;

      if (EmployeeDetails) {
        const { _id: Employee, Name } = EmployeeDetails;
        obj = {
          ...rest,
          Employee,
          Name,
        };
      }
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
        };
      }
      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }
      return obj;
    });

    const TotalDr = GeneralVoucher.reduce((sum, a) => sum + Number(a.DR), 0);
    const TotalCr = GeneralVoucher.reduce((sum, a) => sum + Number(a.CR), 0);
    setTotalCR(TotalCr);
    setTotalDR(TotalDr);
    setGeneralVoucher(myArr);
  };
  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/General/single/${id}`);

      setRemark(value?.Remark);
      setEntryDate(value?.EntryDate);
      GeneralVoucherHandler(value?.GeneralVoucher);
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getDataById();
    }
  }, [id]);
  return (
    <>
      <Header />

      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>view Journal </h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              <div className="col-md-4 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control "
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  //   onChange={(e) => setEntryDate(e.target.value)}
                  placeholder="Enter General "
                  required //done
                />
              </div>

              <div className="col-md-4 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  //   onChange={(e) => setRemark(e.target.value)}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Account</th>
                        <th scope="col">Narration</th>
                        <th scope="col">DR.</th>
                        <th scope="col">CR.</th>
                        {/* <th scope="col">Amount</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {GeneralVoucher &&
                        GeneralVoucher.length > 0 &&
                        GeneralVoucher.map((ele, index) => (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{ele?.Name}</td>
                            <td>{ele?.RefNumber}</td>
                            <td>{coverAmountIntoCurrent(ele?.DR)}</td>
                            <td>{coverAmountIntoCurrent(ele?.CR)}</td>

                            {/* <td>{ele?.Account}</td> */}
                          </tr>
                        ))}
                      <tr>
                        <th scope="row"></th>
                        <td></td>
                        <td></td>

                        <td>
                          {" "}
                          {coverAmountIntoCurrent(TotalDR)}
                          {/* {General.reduce((sum, a) => sum + Number(a.DR), 0)} */}
                        </td>

                        <td>
                          {coverAmountIntoCurrent(TotalCR)}
                          {/* {General.reduce((sum, a) => sum + Number(a.CR), 0)} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            <Link className="btn mybtn" to="/admin/vouchers/Journal/data">
              Go Back
            </Link>
            <Link
              className="btn mybtn"
              to={`/admin/vouchers/Journal?_id=${id}&Mode=true`}
            >
              Edit
            </Link>
          </div>


        </div>
      </div>

      <Footer />
    </>
  );
};

export default ViewGeneral;
