export function calculateVehicleAge(issueDate, registrationDate, company) {
  const registration = new Date(registrationDate);
  const today = new Date(issueDate);

  const yearsDifference = today.getFullYear() - registration.getFullYear();
  const monthsDifference = today.getMonth() - registration.getMonth();
  const totalMonths = yearsDifference * 12 + monthsDifference;

  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  if (company.roundOn === "Exact") {
    return +`${years}.${months}`;
  } else {
    const roundedAge = months >= 7 ? years + 1 : years;
    return roundedAge;
  }
}
