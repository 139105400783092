import React, { useEffect, useState } from "react";
import Select from "react-select"; // Import react-select
import { ToastError, ToastSusses } from "../../Utils/Tost";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const CreateBank = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [Name, setName] = useState(value?.Name || "");
  const [AccountNumber, setAccountNumber] = useState(
    value?.AccountNumber || ""
  );
  const [Branch, setBranch] = useState(value?.Branch?._id || "");
  const [BranchData, setBranchData] = useState([]);
  const [Balance, setBalance] = useState(value?.Balance || "");
  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Bank", {
        Name,
        AccountNumber,
        Balance: Math.round(Balance),
        Branch,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Bank/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Bank/${value?._id}`, {
        Name,
        AccountNumber,
        Balance: Balance,
        Branch,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Bank/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getBranch = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/BankBranch");
      setBranchData(data);
    } catch (error) {
      ToastError("Error fetching branches");
    }
  };

  const fetchIfscDetails = async (ifscCode) => {
    try {
      if (value?._id) {
        return;
      }
      const response = await axios.get(`https://ifsc.razorpay.com/${ifscCode}`);
      if (response.data) {
        setName(response.data.BANK);
      }
    } catch (error) {
      ToastError(error.message);
    }
  };

  const handleBranchSelect = (selectedOption) => {
    setBranch(selectedOption.value); // Set the selected branch id
    const selectedBranch = BranchData.find(
      (branch) => branch._id === selectedOption.value
    );
    if (selectedBranch && selectedBranch.IfscCode) {
      fetchIfscDetails(selectedBranch.IfscCode);
    }
  };

  useEffect(() => {
    getBranch();
  }, []);

  // Prepare options for react-select
  const branchOptions = BranchData.map((branch) => ({
    value: branch._id,
    label: `${branch.BranchName} (${branch.IfscCode})`,
  }));

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="card-body">
                <h4 className="card-title">
                  {view ? "View Bank" : Mode ? "Edit Bank" : "Create New Bank"}
                </h4>
                <hr />
                <br />
                <div className="outer-repeater">
                  <div
                    className="col-md-6 col-12 align-items-center"
                    style={{ float: "left", padding: 15 }}
                  >
                    <label>Branch Name</label>
                    <Select
                      options={branchOptions} // Use the branch options
                      onChange={handleBranchSelect} // Handle the change
                      value={branchOptions.find(
                        (option) => option.value === Branch
                      )} // Pre-select the branch if exists
                      isDisabled={view} // Disable if in view mode
                      required
                    />
                  </div>
                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item="" className="outer">
                      <div
                        className="col-md-6 col-12"
                        style={{ float: "left", padding: 15 }}
                      >
                        <label className="form-label" htmlFor="formname">
                          Bank Name
                        </label>
                        <input
                          disabled={view}
                          className="form-control"
                          type="text"
                          name="Name"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter Bank Name"
                          required
                        />
                      </div>
                      <div
                        className="col-md-6 col-12"
                        style={{ float: "left", padding: 15 }}
                      >
                        <label className="form-label" htmlFor="formemail">
                          Account Number
                        </label>
                        <input
                          disabled={view}
                          className="form-control"
                          type="text"
                          name="AccountNumber"
                          value={AccountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="AccountNumber"
                          required
                        />
                      </div>

                      <div
                        className="col-md-6 col-12"
                        style={{ float: "left", padding: 15 }}
                      >
                        <label className="form-label" htmlFor="formemail">
                          Opening Balance
                        </label>
                        <input
                          disabled={view}
                          className="form-control"
                          type="number"
                          name="Balance"
                          value={Balance}
                          onChange={(e) => setBalance(e.target.value)}
                          placeholder="Balance"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3">
            <button
              type="submit"
              className="btn mybtn"
              onClick={() => navigate("/admin/masters/Bank/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateBank;
