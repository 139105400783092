import React from "react";
import "./Header.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { axiosInstance } from "../../config";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { useDispatch, useSelector } from "react-redux";

import logo from "./Good.png";

const today = new Date();
const firstDay = `${today.toISOString().slice(0, 7)}-01`;
const lastDay = today.toISOString().slice(0, 10);

const buildObfuscatedQuery = (params) => new URLSearchParams(params).toString();

const query = buildObfuscatedQuery({
  f1: 1, // Represents "page"
  f2: 25, // Represents "per page"
  f3: firstDay, // Represents "issueFrom"
  f4: lastDay, // Represents "issueTo"
  f5: firstDay, // Represents "createdAtFrom"
  f6: lastDay, // Represents "createdAtTo"
  f7: "IssueDate", // Represents "filterOn"
});

const Header = () => {
  const location = useLocation();

  const pathUrl = location.pathname.split("/");
  const isHeaderActive = (kewords) => kewords.includes(pathUrl[2]);

  const dispatch = useDispatch();
  const {
    // isAuthenticated,
    // loading,
    // isLoadingRequest,
    user: {
      Name = "",
      MiddleName = "",
      LastName = "",
      Permission = {},
      // businessDetails = {},
    },
  } = useSelector((store) => store.user);

  const {
    Masters = true,
    Policy = true,
    CPMargin = true,
    Vouchers = true,
    QC = true,
    Books = true,
    Reports = true,
  } = Permission ?? {};

  const logoutHandler = async () => {
    try {
      dispatch({
        type: "logoutRequest",
      });
      const {
        data: { success, message },
      } = await axiosInstance.get("/Employee/Logout");
      if (success) {
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
      dispatch({
        type: "logoutSuccess",
      });
    } catch (error) {
      dispatch({
        type: "logoutFailure",
      });
      ToastError(error?.response?.data?.message);
    }
  };
  return (
    <>
      <header id="page-topbar">
        <div
          className="topnav"
          style={{
            background: "rgb(23, 25, 95)",
          }}
        >
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
              aria-controls="topnav-menu-content"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-brand-box">
              <Link
                className="navbar-brand"
                style={{
                  color: "#fff",
                  fontFamily: '"Sansita Swashed", cursive',
                  fontSize: 24,
                  letterSpacing: 4,
                  padding: 0,
                }}
                to="/"
              >
                <img
                  style={{ width: "90px", marginTop: "-10px" }}
                  src={logo}
                  alt="_header_logo"
                />
              </Link>
            </div>
            <div
              className="collapse navbar-collapse"
              id="topnav-menu-content"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div className="ms-auto">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/Dashboard">
                      Dashboard
                    </NavLink>
                  </li>

                  {Masters && (
                    <li className="nav-item dropdown">
                      <Link
                        className={`nav-link dropdown-toggle ${
                          isHeaderActive(["masters"]) ? "active" : ""
                        }`}
                        // to="/admin/rto"
                        id="mastersDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        role="button"
                      >
                        Masters
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="topnav-emailtemplates"
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          width: "40vw",
                          maxHeight: "70vh",
                          overflowY: "auto",
                        }}
                      >
                        <div
                          className="row"
                          style={{
                            width: "40vw",
                          }}
                        >
                          <div className="col-lg-4">
                            <div>
                              <NavLink
                                to="/admin/masters/AccessControl/data"
                                className="dropdown-item"
                              >
                                Access Control
                              </NavLink>
                              <NavLink
                                to="/admin/masters/RTO/data"
                                className="dropdown-item"
                              >
                                RTO
                              </NavLink>

                              <NavLink
                                to="/admin/masters/Club/data"
                                className="dropdown-item"
                              >
                                Club
                              </NavLink>

                              <NavLink
                                to="/admin/masters/club-exception/data"
                                className="dropdown-item"
                              >
                                Club Exception
                              </NavLink>

                              <NavLink
                                to="/admin/masters/LedgerGroup/data"
                                className="dropdown-item"
                              >
                                Ledger Group
                              </NavLink>
                              <NavLink
                                to="/admin/masters/LedgerEntry/data"
                                className="dropdown-item"
                              >
                                Ledger Master
                              </NavLink>
                              <NavLink
                                to="/admin/masters/POS/data"
                                className="dropdown-item"
                              >
                                POS
                              </NavLink>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <NavLink
                                to="/admin/masters/VehicleRcInformation"
                                className="dropdown-item"
                              >
                                VehicleRcInformation
                              </NavLink>

                              <NavLink
                                to="/admin/masters/PayoutGrid/data"
                                className="dropdown-item"
                              >
                                PayoutGrid
                              </NavLink>
                              <NavLink
                                to="/admin/masters/InsuranceCompany/data"
                                className="dropdown-item"
                              >
                                Insurance Company
                              </NavLink>
                              <NavLink
                                to="/admin/masters/InsuranceType/data"
                                className="dropdown-item"
                              >
                                Product
                              </NavLink>

                              {/* <NavLink
                                to="/admin/masters/BankBranch/data"
                                className="dropdown-item"
                              >
                                Bank Branch
                              </NavLink> */}

                              <NavLink
                                to="/admin/masters/Branch/data"
                                className="dropdown-item"
                              >
                                Branch
                              </NavLink>
                              <NavLink
                                to="/admin/masters/Bank/data"
                                className="dropdown-item"
                              >
                                Bank
                              </NavLink>
                              <NavLink
                                to="/admin/masters/PolicyType/data"
                                className="dropdown-item"
                              >
                                Policy Type
                              </NavLink>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div>
                              <NavLink
                                to="/admin/masters/Employee/data"
                                className="dropdown-item"
                              >
                                Employee
                              </NavLink>

                              <NavLink
                                to="/admin/masters/Make/data"
                                className="dropdown-item"
                              >
                                Make
                              </NavLink>
                              <NavLink
                                to="/admin/masters/Model/data"
                                className="dropdown-item"
                              >
                                Model
                              </NavLink>
                              <NavLink
                                to="/admin/masters/Variant/data"
                                className="dropdown-item"
                              >
                                Variant
                              </NavLink>
                              <NavLink
                                to="/admin/masters/RtoGroupChecker/data"
                                className="dropdown-item"
                              >
                                RTO Cluster
                              </NavLink>
                              <NavLink
                                to="/admin/masters/FuelType/data"
                                className="dropdown-item"
                              >
                                Fuel Type
                              </NavLink>
                              {/* <NavLink
                                to="/admin/masters/commissionable-premium/data"
                                className="dropdown-item"
                              >
                                Comm. Premium
                              </NavLink> */}
                              <NavLink
                                to="/admin/masters/State/data"
                                className="dropdown-item"
                              >
                                State
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                  {Policy && (
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/admin/Policy/data"
                      >
                        Data Entry
                      </NavLink>
                    </li>
                  )}
                  {CPMargin && (
                    <li className="nav-item">
                      <NavLink
                        // className="nav-link active"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/admin/CPPolicy/data"
                      >
                        Club Margin
                      </NavLink>
                    </li>
                  )}

                  {Vouchers && (
                    <li className="nav-item dropdown">
                      <Link
                        className={`nav-link dropdown-toggle ${
                          isHeaderActive(["vouchers"]) ? "active" : ""
                        }`}
                        // to="/admin/rto"
                        id="vouchersDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Vouchers
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="topnav-emailtemplates"
                      >
                        <NavLink
                          to="/admin/vouchers/Receipt/data"
                          className="dropdown-item"
                        >
                          Receipt
                        </NavLink>

                        <NavLink
                          to="/admin/vouchers/PaymentList/data"
                          className="dropdown-item"
                        >
                          Payment
                        </NavLink>
                        <NavLink
                          to="/admin/vouchers/CreditNotes/data"
                          className="dropdown-item"
                        >
                          CreditNotes
                        </NavLink>
                        <NavLink
                          to="/admin/vouchers/DebitNotes/data"
                          className="dropdown-item"
                        >
                          DebitNotes
                        </NavLink>
                        <NavLink
                          to="/admin/vouchers/Contra/data"
                          className="dropdown-item"
                        >
                          Contra
                        </NavLink>
                        <NavLink
                          to="/admin/vouchers/Journal/data"
                          className="dropdown-item"
                        >
                          Journal
                        </NavLink>

                        <hr />
                        <NavLink
                          to="/admin/vouchers/Purchase"
                          className="dropdown-item"
                        >
                          Purchase
                        </NavLink>
                        <NavLink
                          to="/admin/vouchers/Sale"
                          className="dropdown-item"
                        >
                          Sale
                        </NavLink>
                        <NavLink
                          to="/admin/vouchers/Purchase/Return"
                          className="dropdown-item"
                        >
                          Purchase Return
                        </NavLink>
                        <NavLink
                          to="/admin/vouchers/Sale/Return"
                          className="dropdown-item"
                        >
                          Sale Return
                        </NavLink>
                      </div>
                    </li>
                  )}
                  {QC && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to={`/admin/QC?${query.toString()}`}
                      >
                        QC
                      </NavLink>
                    </li>
                  )}
                  {Books && (
                    <li className="nav-item dropdown">
                      <Link
                        className={`nav-link dropdown-toggle ${
                          isHeaderActive(["books"]) ? "active" : ""
                        }`}
                        // to="/admin/rto"
                        id="booksDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Books
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="topnav-emailtemplates"
                      >
                        <NavLink
                          to="/admin/books/Ledger/data"
                          className="dropdown-item"
                        >
                          Ledger
                        </NavLink>
                        <NavLink
                          to="/admin/books/Unbilled/data"
                          className="dropdown-item"
                        >
                          Unbilled Case
                        </NavLink>
                        <NavLink
                          to="/admin/books/TrialBalance/data"
                          className="dropdown-item"
                        >
                          Trial Balance
                        </NavLink>
                        <NavLink
                          to="/admin/books/SalesRegister/data"
                          className="dropdown-item"
                        >
                          Sales Register
                        </NavLink>
                        <NavLink
                          to="/admin/books/TDSReport/data"
                          className="dropdown-item"
                        >
                          TDS Report
                        </NavLink>
                      </div>
                    </li>
                  )}
                  {Reports && (
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/admin/Entry/Report">
                        Reports
                      </NavLink>
                    </li>
                  )}

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      // to="/admin/rto"
                      id="userDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {`${Name} ${MiddleName} ${LastName}`}
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-emailtemplates"
                    >
                      <div className="dropdown-divider" />

                      <button
                        className="dropdown-item text-danger"
                        onClick={() => {
                          logoutHandler();
                        }}
                      >
                        <i className="bx bx-power-off font-size-17 align-middle me-1 text-danger" />
                        Logout
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
