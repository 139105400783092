import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ManualGridForm from "./ManualGridForm.jsx";
// import ContentLoader from "react-content-loader";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import "./QC.css";
import CommissionTable from "./CommissionTable";
import { useSelector } from "react-redux";
import {
  ReturnFullName,
  returnAgeDifference,
  // returnCurrentTime,
  reversDate,
} from "../../Utils/usefullFunction";

import {
  calculateCPMargin,
  calculateHOMargin,
  calculateOutflow,
  calculateTPOutflow,
  calculateTotalAmount,
} from "../../lib/calculateMargin.js";

const QCStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [payoutData, setPayoutData] = useState(null);
  const { user } = useSelector((store) => store.user);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [Status, setStatus] = useState("PENDING");
  const [IsPayoutMatched, setIsPayoutMatched] = useState(false);
  const [HOMargin, setHOMargin] = useState(0);
  const [QCRemark, setQCRemark] = useState("");
  const [value, setvalue] = useState(null);
  const [count, setCount] = useState(0);
  const [IsNonMotorPolicy, setIsNonMotorPolicy] = useState(false);
  const [BasedOn, setBasedOn] = useState("");
  const [CLUB, setCLUB] = useState("");
  const [GridError, setGridError] = useState(1);
  const [ToGetToGive, setToGetToGive] = useState({});

  const [ToGetToGiveInit, setToGetToGiveInit] = useState({});
  const [Exception, setException] = useState({});
  const [Is_Purchase_AlreadyBuild, set_Is_Purchase_AlreadyBuild] = useState({
    isPurchase: false,
  });
  const [Is_Sale_AlreadyBuild, set_Is_Sale_AlreadyBuild] = useState({
    isSale: false,
  });
  const [Commission, setCommission] = useState({});
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [PolicyMetaData, setPolicyMetaData] = useState([
    {
      viewName: "Policy Copy",
      url: "",
      Name: "NewPolicyCopy",
    },
    {
      viewName: "First RC",
      url: "",
      Name: "RC1",
    },
    {
      viewName: "Second RC",
      url: "",
      Name: "RC2",
    },
    {
      viewName: "First Previous Policy",
      url: "",
      Name: "PreviousPolicy1",
    },
    {
      viewName: "Second Previous Policy",
      url: "",
      Name: "PreviousPolicy2",
    },
  ]);

  useEffect(() => {
    const fetchPayoutGrid = async () => {
      if (value?.Status === "APPROVED" || value?.RateMatched === "Manual") {
        return;
      }
      setLoading(true);
      try {
        const {
          data: { data },
        } = await axiosInstance.get(
          `/PayoutGrid/getDataForMatchedPayoutGrid/${id}`
        );

        if (data && data.length === 1) {
          setPayoutData(data[0]);
          setBasedOn(data[0]?.BasedOn);
          setIsPayoutMatched(true);
        }

        if (data.length > 1) {
          setGridError(data.length);
        }
      } catch (error) {
        console.error("Error fetching payout grid:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id && value) {
      fetchPayoutGrid();
    }
  }, [id, value]);

  const handleEdit = async () => {
    try {
      if (!Status) {
        return ToastWarn("Please select Action");
      }

      if (Is_Purchase_AlreadyBuild?.isPurchase) {
        return ToastWarn("Purchase Bill Already Build plz delete First");
      }

      if (Is_Sale_AlreadyBuild?.isSale) {
        return ToastWarn("Sales Bill Already Build plz delete First");
      }

      if (GridError > 1) {
        return ToastError(`We Found ${GridError} Grid Can't be submitted`);
      }

      let data;

      // Convert all fields of ToGetToGive to numbers
      Object.keys(ToGetToGive).forEach((key) => {
        if (!isNaN(ToGetToGive[key])) {
          ToGetToGive[key] = Number(ToGetToGive[key]);
        }
      });
      let body;
      if (Status === "APPROVED") {
        body = {
          Status,
          BasedOn,
          Commission: {
            ...Exception,
            ...ToGetToGive,
          },
          QCRemark,
          ApprovedBy: user._id,
        };
      } else {
        body = {
          Status,
          QCRemark:"",
          BasedOn:"",
          Commission: null,
          RateMatched:"",
        };
      }

      const bodyManual = {
        BasedOn,
        Commission: {
          ...Exception,
          ...ToGetToGive,
        },
        RateMatched: "Manual",
      };

      const response = await axiosInstance.put(
        `/Policy/updatePolicy/Approval/${id}`,
        IsPayoutMatched ? body : bodyManual
      );
      data = response.data;

      if (data.success) {
        ToastSusses(data.message);
        navigate(`/admin/QC${location.search}`);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (value && payoutData) {
      // if (value?.Status === "APPROVED" || value?.RateMatched === "Manual") {
      //   return;
      // }

      const policy = {
        ...value,
        Inflow: payoutData?.Reciveable ?? 0,
        InflowTP: payoutData?.ReciveableTP ?? 0,
        BasedOn: payoutData?.BasedOn ?? "",
      };

      const issueDate = new Date(value?.IssueDate);

      let foundPOSNewMargin;
      let foundCPNewMargin;

      if (
        !value?.POSRate &&
        value?.POS &&
        value?.POS?.ClubException &&
        value?.POS?.ClubException.length > 0
      ) {
        const clubException = value?.POS?.ClubException || [];
        const insUnderType = value?.InsuranceUnderFlow || [];

        foundPOSNewMargin = clubException.find((item) => {
          const defDate = new Date(item.DEF);
          const detDate = new Date(item.DET);
          const insType = item?.InsuranceType;

          const insUnderflowIds = insUnderType.map((item) => item?._id);

          const isIssueDateInRange =
            issueDate >= defDate && issueDate <= detDate;

          const isProductPresent = insType
            ? insUnderflowIds.includes(insType)
            : true;

          return isIssueDateInRange && isProductPresent;
        });
      } else if (
        value?.POSRate &&
        value?.POSRate > 0 &&
        value?.POS &&
        value?.POS?.ClubException &&
        value?.POS?.ClubException.length > 0
      ) {
        foundCPNewMargin = value?.POS?.ClubException[0];
      }

      const cpMargin = calculateCPMargin(
        foundCPNewMargin,
        foundPOSNewMargin,
        policy
      );

      //  Outflow %
      const marginOffset = calculateOutflow(
        foundCPNewMargin,
        foundPOSNewMargin,
        policy
      );

      //  HOMargin %
      const hoMargin = calculateHOMargin(
        foundCPNewMargin,
        foundPOSNewMargin,
        policy
      );

      setHOMargin(hoMargin);
      const basedOnLen = policy.BasedOn.split("+") || [];

      if (policy && basedOnLen.length === 1) {
        const totalAmount = calculateTotalAmount(policy, policy.Inflow);
        const totalAmountHo = calculateTotalAmount(policy, hoMargin);
        // const totalAmountHo = calculateHOAmount(totalAmount, hoMargin);

        const totalAmountCP = calculateTotalAmount(policy, cpMargin);
        const totalAmountPOSRate = calculateTotalAmount(policy, marginOffset);
        // const totalAmountCP = calculateCPAmount(totalAmountHo, cpMargin);

        setToGetToGive({
          HOMargin: hoMargin ?? 0,
          CPMargin: cpMargin ?? 0,
          [`Inflow${policy.BasedOn}`]: policy?.Inflow,
          [`Inflow${policy.BasedOn}Amount`]: totalAmount,
          [`HO${policy.BasedOn}Amount`]: totalAmountHo,
          [`CP${policy.BasedOn}Amount`]: totalAmountCP,
          [`POSRate${policy.BasedOn}`]: marginOffset, // POSRate %
          [`POSRate${policy.BasedOn}Amount`]: totalAmountPOSRate, // POSRate Amount
          TotalToGetAmount: totalAmount,
          TotalToGiveAmount: totalAmountPOSRate,
        });

        setToGetToGiveInit({
          HOMargin: hoMargin ?? 0,
          CPMargin: cpMargin ?? 0,
          [`Inflow${policy.BasedOn}`]: policy?.Inflow,
          [`Inflow${policy.BasedOn}Amount`]: totalAmount,
          [`HO${policy.BasedOn}Amount`]: totalAmountHo,
          [`CP${policy.BasedOn}Amount`]: totalAmountCP,
          [`POSRate${policy.BasedOn}`]: marginOffset, // POSRate %
          [`POSRate${policy.BasedOn}Amount`]: totalAmountPOSRate, // POSRate Amount
          TotalToGetAmount: totalAmount,
          TotalToGiveAmount: totalAmountPOSRate,
        });
      } else {
        //  Outflow %
        const marginOffsetTP = calculateTPOutflow(
          foundCPNewMargin,
          foundPOSNewMargin,
          policy
        );

        // outflow amount (for both OD+TP)
        const totalAmount = calculateTotalAmount(
          policy,
          policy?.Inflow,
          policy?.InflowTP
        );

        // HO amount (for both OD+TP)
        const totalAmountHo = calculateTotalAmount(policy, hoMargin, hoMargin);
        // CP amount (for both OD+TP)
        const totalAmountCP = calculateTotalAmount(policy, cpMargin, cpMargin);

        // POS amount (for both OD+TP)
        const totalAmountPOSRate = calculateTotalAmount(
          policy,
          marginOffset,
          marginOffsetTP
        );
        let TotalToGetAmount = 0;
        let TotalToGiveAmount = 0;

        const toGive = policy.BasedOn.split("+").reduce(
          (acc, curr) => {
            const inflowAmt = Math.floor(totalAmount[`${curr}Amount`]);

            TotalToGetAmount += +inflowAmt;
            TotalToGiveAmount += +totalAmountPOSRate[`${curr}Amount`];
            acc[`Inflow${curr}`] =
              curr === "OD" ? policy?.Inflow : policy?.InflowTP;
            acc[`Inflow${curr}Amount`] = inflowAmt;
            acc[`HO${curr}Amount`] = +totalAmountHo[`${curr}Amount`];
            acc[`CP${curr}Amount`] = +totalAmountCP[`${curr}Amount`];
            acc[`POSRate${curr}`] =
              curr === "OD" ? marginOffset : marginOffsetTP;
            acc[`POSRate${curr}Amount`] = +totalAmountPOSRate[`${curr}Amount`];

            return acc;
          },
          { HOMargin: hoMargin ?? 0, CPMargin: cpMargin ?? 0 }
        );

        toGive[`TotalToGetAmount`] = Math.floor(TotalToGetAmount);

        toGive[`TotalToGiveAmount`] = Math.floor(TotalToGiveAmount);

        setToGetToGive(toGive);
        setToGetToGiveInit(toGive);
      }
    }
  }, [value, payoutData]);

  useEffect(() => {
    // if (value?.Status === "APPROVED" || value?.RateMatched === "Manual") {
    //   return;
    // }

    const basedOnLen = BasedOn.split("+") || [];

    if (value && payoutData && basedOnLen.length === 1) {
      const policy = {
        ...value,
        Inflow: payoutData?.Reciveable ?? 0,
        InflowTP: payoutData?.ReciveableTP ?? 0,
        BasedOn: payoutData?.BasedOn ?? "",
      };

      let homargin = ToGetToGiveInit[`HOMargin`] ?? 0;
      let posRate = ToGetToGiveInit[`POSRate${BasedOn}`] ?? 0;

      const validException = Exception?.[`Exception${BasedOn}`] ?? 0;
      homargin = homargin - validException;
      posRate = posRate + validException;

      const totalHOAmount = calculateTotalAmount(policy, homargin); // first
      const totalAmountODPOSRatemount = calculateTotalAmount(policy, posRate);

      if (totalHOAmount && totalAmountODPOSRatemount) {
        setToGetToGive((prevState) => ({
          ...prevState,
          [`HO${BasedOn}Margin`]: homargin,
          [`POSRate${BasedOn}`]: posRate,
          [`HO${BasedOn}Amount`]: totalHOAmount,
          [`POSRate${BasedOn}Amount`]: totalAmountODPOSRatemount,
          TotalToGiveAmount: totalAmountODPOSRatemount,
        }));
      } else {
        console.error(
          "Invalid return values from calculateTotalAmount:",
          totalHOAmount,
          totalAmountODPOSRatemount
        );
      }
    } else if (value && payoutData && basedOnLen.length === 2) {
      const policy = {
        ...value,
        Inflow: payoutData?.Reciveable ?? 0,
        InflowTP: payoutData?.ReciveableTP ?? 0,
        BasedOn: payoutData?.BasedOn ?? "",
      };

      let homargin0 = ToGetToGiveInit[`HOMargin`] ?? 0;
      let homargin1 = ToGetToGiveInit[`HOMargin`] ?? 0;
      let posRate0 = ToGetToGiveInit[`POSRate${basedOnLen[0]}`] ?? 0;
      let posRate1 = ToGetToGiveInit[`POSRate${basedOnLen[1]}`] ?? 0;

      const validException0 = Exception?.[`Exception${basedOnLen[0]}`] ?? 0;
      const validException1 = Exception?.[`Exception${basedOnLen[1]}`] ?? 0;

      homargin0 = homargin0 - validException0;
      posRate0 = posRate0 + validException0;

      const totalHOAmountOD = calculateTotalAmount(
        policy,
        homargin0,
        homargin0
      ); // first
      const totalAmountODPOSRatemount = calculateTotalAmount(
        policy,
        posRate0,
        0
      );

      homargin1 = homargin1 - validException1;
      posRate1 = posRate1 + validException1;

      const totalHOAmountTP = calculateTotalAmount(
        policy,
        homargin1,
        homargin1
      );
      const totalAmountTPPOSRateAmount = calculateTotalAmount(
        policy,
        0,
        posRate1
      );

      if (
        totalHOAmountOD &&
        totalAmountODPOSRatemount &&
        totalHOAmountTP &&
        totalAmountTPPOSRateAmount &&
        !isNaN(totalHOAmountOD[`${basedOnLen[0]}Amount`]) &&
        !isNaN(totalAmountODPOSRatemount[`${basedOnLen[0]}Amount`]) &&
        !isNaN(totalHOAmountTP[`${basedOnLen[1]}Amount`]) &&
        !isNaN(totalAmountTPPOSRateAmount[`${basedOnLen[1]}Amount`])
      ) {
        const POSODAmount = +(
          totalAmountODPOSRatemount[`${basedOnLen[0]}Amount`] ?? 0
        );
        const POSTPAmount = +(
          totalAmountTPPOSRateAmount[`${basedOnLen[1]}Amount`] ?? 0
        );

        setToGetToGive((prevState) => ({
          ...prevState,
          [`HO${basedOnLen[0]}Margin`]: homargin0,
          [`HO${basedOnLen[1]}Margin`]: homargin1,
          [`POSRate${basedOnLen[0]}`]: posRate0,
          [`POSRate${basedOnLen[1]}`]: posRate1,
          [`HO${basedOnLen[0]}Amount`]:
            +totalHOAmountOD[`${basedOnLen[0]}Amount`],
          [`POSRate${basedOnLen[0]}Amount`]: POSODAmount,
          [`HO${basedOnLen[1]}Amount`]:
            +totalHOAmountTP[`${basedOnLen[1]}Amount`],
          [`POSRate${basedOnLen[1]}Amount`]: POSTPAmount,
          TotalToGiveAmount: POSODAmount + POSTPAmount,
        }));
      } else {
        console.error(
          "Invalid return values for second case:",
          totalHOAmountOD,
          totalAmountODPOSRatemount,
          totalHOAmountTP,
          totalAmountTPPOSRateAmount
        );
      }
    }
  }, [payoutData, BasedOn, ToGetToGiveInit, Exception]);

  const getSingle = async () => {
    try {
      const {
        data: { data, PurchaseBill, saleBill },
      } = await axiosInstance.get(`/Policy/Single/${id}`);

      setvalue(data);
      setStatus(data?.Status ?? "");
      setQCRemark(data?.QCRemark ?? "");

      if (PurchaseBill?.isPurchase) {
        set_Is_Purchase_AlreadyBuild(PurchaseBill);
      }
      if (saleBill?.isSale) {
        set_Is_Sale_AlreadyBuild(saleBill);
      }

      if (data?.Status === "APPROVED" || data?.RateMatched === "Manual") {
        const BasedOnArr = data?.BasedOn.split("+");

        setPayoutData({
          Reciveable: data?.Commission?.[`Inflow${BasedOnArr[0].trim()}`] ?? 0,
          ReciveableTP:
            BasedOnArr.length > 1
              ? data?.Commission?.[`Inflow${BasedOnArr[1].trim()}`] ?? 0
              : 0,
          BasedOn: data?.BasedOn ?? "",
        });
        // console.log("data?.Commission", data?.Commission);

        setBasedOn(data?.BasedOn);
        setHOMargin(data?.Commission.HOMargin || 0);
        // setToGetToGive(data?.Commission);
        // setToGetToGiveInit(data?.Commission);
        setIsPayoutMatched(true);
      }

      if (data?.Commission) {
        setException({ ...data?.Commission });
      }
      setPolicyMetaData([
        {
          viewName: "Policy Copy",
          url: data?.NewPolicyCopy ?? "",
          Name: "NewPolicyCopy",
        },
        {
          viewName: "First RC",
          url: data?.RC1 ?? "",
          Name: "RC1",
        },
        {
          viewName: "Second RC",
          url: data?.RC2 ?? "",
          Name: "RC2",
        },
        {
          viewName: "First Previous Policy",
          url: data?.PreviousPolicy1 ?? "",
          Name: "PreviousPolicy1",
        },
        {
          viewName: "Second Previous Policy",
          url: data?.PreviousPolicy2 ?? "",
          Name: "PreviousPolicy2",
        },
      ]);
      setCLUB(data?.POS?.Club ?? "");
      setIsNonMotorPolicy(data?.IsNonMotorPolicy ?? false);
      // getCommission({
      //   InsuranceType: data?.InsuranceType?._id,
      //   InsuranceCompany: data?.InsuranceCompany,
      //   PolicyType: data?.PolicyType?._id,
      //   // VehicleModal: data?.VehicleModal,
      //   NCBSTATUS: data?.NCB,
      //   PA: data?.PA,
      //   IMTType: data?.IMTType,
      //   FuelType: data?.FuelType?._id,
      //   IssueDate: data?.IssueDate,
      //   RTOCode: data?.VehicleNumber,
      //   MakeModal: data?.MakeModal,
      //   Broker: data?.Broker?._id,
      //   RegistrationDate: data?.RegistrationDate,
      //   BusinessType: data?.BusinessType,
      //   IsNonMotorPolicy: data?.IsNonMotorPolicy ?? false,
      // });
    } catch (error) { }
  };

  useEffect(() => {
    getSingle();
  }, []);

  const ExceptionHandler = (e) => {
    let value = parseFloat(e.target.value);
    if (value > HOMargin) {
      value = HOMargin;
    }
    setException((prev) => ({ ...prev, [e.target.name]: value }));
  };

  return (
    <>
      <>
        <CommissionTable
          handleOpen={handleOpen}
          handleClose={handleClose}
          open={open}
          ModalData={Commission}
        />
        <Header />
        <div className="MainDiv MainDivPadding">
          <div className="container-fluid">
            <section
              className="push "
              style={{
                position: "relative",
                transition: " margin-left .5s",
                zoom: "80%",
              }}
            >
              <div className="row mx-2 ">
                <div
                  className="col-md-7 align-items-center "
                  style={{
                    height: "100vh",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "1rem 0rem",
                      width: "100%",
                    }}
                  >
                    <button
                      className="btn"
                      style={{
                        backgroundColor: "#E8960F",
                        borderRadius: "7px",
                        color: "white",
                      }}
                      onClick={() => {
                        setCount((p) => {
                          if (p === 0) return 4;
                          return p - 1;
                        });
                      }}
                    >
                      Previous
                    </button>

                    <h5>{PolicyMetaData[count]?.viewName ?? ""}</h5>

                    <button
                      className="btn"
                      style={{
                        backgroundColor: "green",
                        borderRadius: "7px",
                        color: "white",
                      }}
                      onClick={() => {
                        setCount((p) => {
                          if (p === 4) return 0;
                          return p + 1;
                        });
                      }}
                    >
                      Next
                    </button>
                  </div>
                  {PolicyMetaData[count]?.url ? (
                    <embed
                      src={`${REACT_APP_FileURL}${PolicyMetaData[count].url}#zoom=67`}
                      type="application/pdf"
                      width="100%"
                      height="90%"
                    />
                  ) : (
                    <>
                      <h5> Not Uploaded</h5>
                    </>
                  )}
                </div>
                <div className="col-md-5 align-items-center">
                  <div
                    class=""
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      marginTop: "4rem",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* <div class="col-12" style={{ marginBottom: "9px" }}></div> */}
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>Policy No.</label>
                        <div>
                          <input
                            value={value?.PolicyNumber ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b> {value?.PolicyNumber ?? ""} </b> */}
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>Insured Name</label>
                        <div>
                          <input
                            value={value?.InsureeName ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b> {value?.InsureeName ?? ""} </b> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          Issue Date{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>
                        <div>
                          <input
                            value={value?.IssueDate?.split("-")
                              .reverse()
                              .join("-")}
                            disabled
                            className="form-control"
                          />
                          {/* <b>{value?.IMTType}</b> */}
                        </div>
                      </div>
                    </div>
                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>Vehicle No.</label>
                          <div>
                            <input
                              value={value?.VehicleNumber ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b> {value?.VehicleNumber ?? ""} </b> */}
                          </div>
                        </div>
                      </div>
                    )}
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>RSD</label>
                        <div>
                          {/* <b> {value?.RSD ?? ""} </b> */}
                          <input
                            value={reversDate(value?.RSD) ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>RED</label>
                        <div>
                          {/* <b> {value?.RSD ?? ""} </b> */}
                          <input
                            value={reversDate(value?.RED) ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                        </div>
                      </div>
                    </div>
                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          Year Of Manufacture
                          <div>
                            <input
                              value={value?.YearOfManufacture ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b> {value?.YearOfManufacture ?? ""} </b> */}
                          </div>
                        </div>
                      </div>
                    )}
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          Product{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>
                        <div>
                          <input
                            value={value?.InsuranceType?.InsuranceType ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                        </div>
                      </div>
                    </div>

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Insurer{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>
                          <div>
                            <input
                              value={value?.InsuranceCompany?.AliasName ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}

                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          Branch{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>
                        <div>
                          <input
                            value={value?.Branch?.BranchName ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                        </div>
                      </div>
                    </div>

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Model{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>
                          <div>
                            <input
                              value={value?.Model?.Model ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Variant{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>
                          <div>
                            <input
                              value={value?.Variant?.VariantName ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Make
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>
                          <div>
                            <input
                              value={value?.MakeModal?.Make ?? ""}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Policy Type{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>
                          <div>
                            <input
                              value={value?.PolicyType?.PolicyTypeName ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            NCB{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            {/* Not Applicable  = -1*/}
                            {/* No  = 0*/}
                            {/* Yes  = 20,25,35,45,50 */}
                            <input
                              value={
                                value?.NCB === 0
                                  ? "No"
                                  : value?.NCB < 0
                                    ? "Not Applicable"
                                    : "Yes"
                              }
                              // value={value?.NCB }
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.NCB === 0 ? "No" : "Yes"}</b> */}
                            {/* // value?.NCB === 0
                              //   ? "No"
                              //   : value?.NCB > 0
                              //   ? "Yes"
                              //   : value?.NCB < 0 && "Not Applicable" */}
                          </div>
                        </div>
                      </div>
                    )}
                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            IMT Type{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.IMTType}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.IMTType}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          Business Type{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>

                        <div>
                          <input
                            value={value?.BusinessType}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b>{value?.IMTType}</b> */}
                        </div>
                      </div>
                    </div>

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Nil Dep
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.nillDepth}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.IMTType}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Fuel Type{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.FuelType?.FuelType}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.IMTType}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          POS{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>

                        <div>
                          <input
                            value={ReturnFullName(value?.POS)}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b>{value?.POS?.Name ?? ""}</b> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          Agent Code
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>

                        <div>
                          <input
                            value={value?.POS?.AliasName}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b>{value?.POS?.Name ?? ""}</b> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          Club{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>

                        <div>
                          <input
                            value={value?.POS?.Club?.Name ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b>{value?.POS?.Club ?? ""}</b> */}
                        </div>
                      </div>
                    </div>
                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Vehicle Age{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={`${returnAgeDifference(
                                value?.RegistrationDate,
                                value?.RSD,
                                true
                              )} `}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.POS?.Club ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            CC/watt (1KW=1000Watt)
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.Engine ?? ""}
                              disabled
                              className="form-control"
                            />
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            GVW{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.GVW ?? ""}
                              disabled
                              className="form-control"
                            />
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          OD Premium{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>

                        <div>
                          <input
                            value={value?.ODPremium ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b>{value?.ODPremium ?? ""}</b> */}
                        </div>
                      </div>
                    </div>
                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            TP Premium{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.TPPremium ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}
                    {IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Terrorism Premium{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.TerrorismPremium ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}
                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            LL Premium{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.LLPremium ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}
                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            PA Premium{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.PAPremium ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Commissionable Premium{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.CommissionablePremium ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {!IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Add On Premium{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.AddOnPremium ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {IsNonMotorPolicy && (
                      <div class="col-lg-4" style={{ float: "left" }}>
                        <div class="col-lg-11" style={{ float: "left" }}>
                          <label>
                            LL/Terrorism Premium{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Format: UP15AA1234"
                            ></span>{" "}
                          </label>

                          <div>
                            <input
                              value={value?.LLPremium ?? ""}
                              disabled
                              className="form-control"
                            ></input>
                            {/* <b>{value?.TPPremium ?? ""}</b> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {IsNonMotorPolicy && (
                      <div className="col-lg-4" style={{ float: "left" }}>
                        <div className="col-lg-11" style={{ float: "left" }}>
                          <label>
                            Entered By{" "}
                            <span
                              style={{ color: "red" }}
                              id="Vali"
                              data-toggle="popover"
                              title="Entered By Information"
                            ></span>
                          </label>

                          <div>
                            <input
                              value={`${value?.EnteredBy?.Name ?? ""} ${value?.EnteredBy?.MiddleName
                                  ? value.EnteredBy.MiddleName + " "
                                  : ""
                                }${value?.EnteredBy?.LastName ?? ""}`}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          ODD %{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>

                        <div>
                          <input
                            value={value?.ODDiscount ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b>{value?.TPPremium ?? ""}</b> */}
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          NET Premium{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>

                        <div>
                          <input
                            value={value?.NETPremium ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b>{value?.NETPremium ?? ""}</b> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>
                          Gross Premium{" "}
                          <span
                            style={{ color: "red" }}
                            id="Vali"
                            data-toggle="popover"
                            title="Format: UP15AA1234"
                          ></span>{" "}
                        </label>

                        <div>
                          <input
                            value={value?.GrossPremium ?? ""}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b>{value?.GrossPremium ?? ""}</b> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>Based On </label>
                        <div>
                          <input
                            value={value?.BasedOn || ""}
                            disabled
                            className="form-control"
                          />
                          {/* <b> {BasedOn}</b> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>Entered By</label>
                        <div>
                          {/*   select: "Name Salutation MiddleName LastName", */}
                          <input
                            value={ReturnFullName(value?.EnteredBy)}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b> {BasedOn}</b> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4" style={{ float: "left" }}>
                      <div class="col-lg-11" style={{ float: "left" }}>
                        <label>Remark</label>
                        <div>
                          <input
                            value={value?.Remark}
                            disabled
                            className="form-control"
                          ></input>
                          {/* <b> {BasedOn}</b> */}
                        </div>
                      </div>
                    </div>
                    {GridError > 1 && (
                      <div class="">
                        <label>Grid Error</label>
                        <div>
                          <b
                            style={{
                              color: "red",
                            }}
                          >
                            {" "}
                            {`We Found ${GridError}  Grid`}*
                          </b>
                        </div>
                      </div>
                    )}
                    <hr
                      style={{
                        margin: "1rem",
                      }}
                    />
                    {/* handleEdit */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {Is_Purchase_AlreadyBuild?.isPurchase && (
                      // <h5 className="p-2"> Already Build</h5>
                      <Link
                        className="text-danger"
                        style={{
                          fontSize: "1rem",
                        }}
                        to={`/admin/Purchase/edit?_id=${Is_Purchase_AlreadyBuild._id}&PolicyNumber=${value?.PolicyNumber}`}
                      >
                        Purchase Bill Already Build{" "}
                        {Is_Purchase_AlreadyBuild?.ControlNumber}
                      </Link>
                    )}
                    {Is_Sale_AlreadyBuild?.isSale && (
                      // <h5 className="p-2"> Already Build</h5>
                      <Link
                        className="text-danger"
                        style={{
                          fontSize: "1rem",
                        }}
                        to={`/admin/Sale/${Is_Sale_AlreadyBuild._id}`}
                      >
                        Sales Bill Already Build{" "}
                        {Is_Sale_AlreadyBuild?.ControlNumber}
                      </Link>
                    )}
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleEdit();
                    }}
                  >
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      {loading ? (
                        // Custom skeleton loader using react-content-loader
                        <p>Loading please wait...</p>
                      ) : error ? (
                        <p>{error}</p>
                      ) : payoutData && IsPayoutMatched ? (
                        <table className="table table-striped table-dark">
                          <thead>
                            <tr>
                              <th scope="col">Basis</th>
                              <th scope="col">Inflow</th>
                              <th scope="col">Outflow</th>
                              <th scope="col">Exception HOM</th>
                            </tr>
                          </thead>
                          <tbody className="table-input">
                            {payoutData &&
                              payoutData?.BasedOn?.split("+").map(
                                (data, index) => {
                                  const InflowName = `Inflow${data}`;
                                  const OutflowName = `POSRate${data}`;
                                  const ExceptionName = `Exception${data}`;
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td>{`${data} %`}</td>
                                        <td>
                                          <input
                                            style={{ textAlign: "right" }}
                                            type="number"
                                            defaultValue={0}
                                            name={InflowName}
                                            value={(
                                              ToGetToGive?.[InflowName] || 0
                                            ).toFixed(2)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            style={{ textAlign: "right" }}
                                            type="number"
                                            defaultValue={0}
                                            name={OutflowName}
                                            value={(
                                              ToGetToGive?.[OutflowName] || 0
                                            ).toFixed(2)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            style={{ textAlign: "right" }}
                                            type="number"
                                            defaultValue={0}
                                            step={0.1}
                                            name={ExceptionName}
                                            max={HOMargin}
                                            onChange={ExceptionHandler}
                                            value={
                                              Exception[ExceptionName] ?? 0
                                            }
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Payout</td>
                                        <td>
                                          <input
                                            style={{ textAlign: "right" }}
                                            type="text"
                                            name={`${InflowName}Amount`}
                                            value={(
                                              ToGetToGive?.[
                                              `${InflowName}Amount`
                                              ] ?? 0
                                            ).toLocaleString("en-IN")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            style={{ textAlign: "right" }}
                                            type="text"
                                            name={`${OutflowName}Amount`}
                                            value={(
                                              ToGetToGive?.[
                                              `${OutflowName}Amount`
                                              ] ?? 0
                                            ).toLocaleString("en-IN")}
                                          />
                                        </td>

                                        <td></td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            <tr>
                              <td>Total</td>
                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  type="text"
                                  value={(
                                    ToGetToGive?.TotalToGetAmount ?? 0
                                  ).toLocaleString("en-IN")}
                                  name="TotalToGetAmount"
                                />
                              </td>
                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  type="text"
                                  value={(
                                    ToGetToGive?.TotalToGiveAmount ?? 0
                                  ).toLocaleString("en-IN")}
                                  name="TotalToGiveAmount"
                                />
                              </td>

                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {value?.RateMatched !== "Manual" && (
                            <h5>Payout Grid Not Match</h5>
                          )}
                          {value?.RateMatched !== "Manual" &&
                            user.Branch.BranchCode === "0" && (
                              <ManualGridForm
                                payoutData={payoutData}
                                setPayoutData={setPayoutData}
                                setBasedOn={setBasedOn}
                                BasedOn={BasedOn}
                              />
                            )}

                          {payoutData ? (
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th scope="col">Basis</th>
                                  <th scope="col">Inflow</th>
                                  <th scope="col">Outflow</th>
                                  <th scope="col">Exception HOM</th>
                                </tr>
                              </thead>
                              <tbody className="table-input">
                                {payoutData?.BasedOn?.split("+").map(
                                  (data, index) => {
                                    const InflowName = `Inflow${data}`;
                                    const OutflowName = `POSRate${data}`;
                                    const ExceptionName = `Exception${data}`;
                                    return (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td>{`${data} %`}</td>
                                          <td>
                                            <input
                                              style={{ textAlign: "right" }}
                                              type="number"
                                              defaultValue={0}
                                              name={InflowName}
                                              value={(
                                                ToGetToGive?.[InflowName] || 0
                                              ).toFixed(2)}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              style={{ textAlign: "right" }}
                                              type="number"
                                              defaultValue={0}
                                              name={OutflowName}
                                              value={(
                                                ToGetToGive?.[OutflowName] || 0
                                              ).toFixed(2)}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              style={{ textAlign: "right" }}
                                              type="number"
                                              defaultValue={0}
                                              step={0.1}
                                              name={ExceptionName}
                                              max={HOMargin}
                                              onChange={ExceptionHandler}
                                              value={
                                                Exception?.[ExceptionName] ?? 0
                                              }
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Payout</td>
                                          <td>
                                            <input
                                              style={{ textAlign: "right" }}
                                              type="text"
                                              name={`${InflowName}Amount`}
                                              value={(
                                                ToGetToGive?.[
                                                `${InflowName}Amount`
                                                ] ?? 0
                                              ).toLocaleString("en-IN")}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              style={{ textAlign: "right" }}
                                              type="text"
                                              name={`${OutflowName}Amount`}
                                              value={(
                                                ToGetToGive?.[
                                                `${OutflowName}Amount`
                                                ] ?? 0
                                              ).toLocaleString("en-IN")}
                                            />
                                          </td>
                                          <td></td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                                <tr>
                                  <td>Total</td>
                                  <td>
                                    <input
                                      style={{ textAlign: "right" }}
                                      type="text"
                                      value={(
                                        ToGetToGive?.TotalToGetAmount ?? 0
                                      ).toLocaleString("en-IN")}
                                      name="TotalToGetAmount"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{ textAlign: "right" }}
                                      type="text"
                                      value={(
                                        ToGetToGive?.TotalToGiveAmount ?? 0
                                      ).toLocaleString("en-IN")}
                                      name="TotalToGiveAmount"
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          ) : null}
                        </>
                      )}
                    </div>
                    <hr />
                    {IsPayoutMatched && (
                      <div className="row gap-1">
                        <div class="form-group col-sm-4">
                          <label>Action</label>
                          <select
                            class="form-control"
                            id="ApprovalStatus"
                            name="ApprovalStatus"
                            value={Status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="PENDING">PENDING</option>
                            <option value="APPROVED">APPROVED</option>
                            <option value="REJECTED">REJECTED</option>
                            <option value="HOLD">HOLD</option>
                            <option value="CANCELLED">CANCELLED</option>
                          </select>
                        </div>

                        <div class="form-group col-4">
                          <label>Remark</label>
                          <input
                            value={QCRemark}
                            onChange={(ele) => {
                              setQCRemark(ele.target.value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    )}

                    <div className="d-flex gap-3 mt-5">
                      <button
                        className="btn"
                        type="submit"
                        disabled={
                          (!value?.NewPolicyCopy || !payoutData) &&
                          (Status === "PENDING" || Status === "APPROVED")
                        }
                        style={{
                          backgroundColor: "#1a73e8",
                          borderRadius: "7px",
                          color: "white",
                        }}
                      >
                        {IsPayoutMatched
                          ? "Submit"
                          : user.Branch.BranchCode === "0"
                            ? "Save"
                            : "Submit"}
                      </button>

                      <Link
                        className="btn"
                        to={`/admin/QC${location.search}`}
                        style={{
                          backgroundColor: "#1a73e8",
                          borderRadius: "7px",
                          color: "white",
                        }}
                      >
                        Back
                      </Link>

                      <Link
                        className="btn"
                        to={`/admin/Policy/${id}`}
                        state={{ FromQc: true }}
                        style={{
                          backgroundColor: "#1a73e8",
                          borderRadius: "7px",
                          color: "white",
                        }}
                      >
                        Edit
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

export default QCStatus;
