import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import Select from "react-select";
import "../Purchase/Purchase.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { reversDate } from "../../Utils/usefullFunction";

import { AiFillFilePdf } from "react-icons/ai";
import axios from "axios";
import NewReturnSaleExcel from "./NewReturnSaleExcel";
const EditSaleReturn = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const [EnterDate, setEnterDate] = useState(lastDay);
  const [inputData, setInput] = useState();
  const [SelectedPolicyOpt, setSelectedPolicyOpt] = useState([]);
  const [UnSelectedPolicyOpt, setUnSelectedPolicyOpt] = useState([]);
  const [TotalAmountOfSelectPolicy, setTotalAmountOfSelectPolicy] = useState(0);
  const [TDSAccount, setTDSAccount] = useState({
    _id: "",
    Name: "-- Select TDS Account --",
  });
  const [loading, setLoading] = useState(false);
  const [TDSOpt, setTDSOpt] = useState([]);
  const [Remark, setRemark] = useState();
  const [TDSPercent, setTDSPercent] = useState(0);
  const [Data, setData] = useState([]);
  const [DebitAccount, setDebitAccount] = useState({
    _id: "",
    Name: "-- Select Debit Account",
  });
  const [DebitAccountOpt, setDebitAccountOpt] = useState([]);
  // const [TotalAmount, setTotalAmount] = useState(0);
  const [InsuranceCompany, setInsuranceCompany] = useState({});
  const [NetAmount, setNetAmount] = useState(0);
  const [GSTPercent, setGSTPercent] = useState();
  const [GSTAccount, setGSTAccount] = useState({
    _id: "",
    Name: "-- Select GST Account --",
  });

  const getSingleSale = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
        
      } = await axiosInstance.get(`/Sale/Return/single/${id}`);
      setEnterDate(data?.EnterDate.split("T")[0]);
      setRemark(data?.Remark);
      setTDSPercent(data?.TDSPercent);
      setTotalAmountOfSelectPolicy(data?.TotalAmount);
      setNetAmount(data?.NetAmount);
      setInsuranceCompany(data?.CreditAccount);
      setDebitAccount(data?.DebitAccount);
      setGSTPercent(data?.GSTPercent ?? 0);
      setGSTAccount(
        data?.GSTAccount ?? {
          _id: "",
          Name: "-- Select GST Account --",
        }
      );
      setTDSAccount(
        data?.TDSAccount ?? {
          _id: "",
          Name: "-- Select TDS Account --",
        }
      );
      setTDSPercent(data?.TDSPercent ?? 0);
      data.Policy.sort((a, b) => new Date(a.IssueDate) - new Date(b.IssueDate));
   
      setData(data?.Policy);
      setLoading(false);
    } catch (error) {
      console.log(error ,"-:erroe");
      setLoading(false);
    }
  };
  const getLedgerMasterForTds = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/LedgerEntry?Group=648ac8ca7df926cfdd2ddccb`
      ); // 	Duties and Tax
      setTDSOpt(data);
    } catch (error) { }
  };
  const handleEdit = async () => {
    const AmountToGive = TotalAmountOfSelectPolicy;

    let GSTAmount = 0; // + add On Original Cost
    let TDSAmount = 0; // -

    if (TDSAccount._id) {
      TDSAmount = Math.round((AmountToGive * TDSPercent) / 100);
    }
    if (GSTAccount._id) {
      GSTAmount = (AmountToGive * GSTPercent) / 100;
      GSTAmount = Number(GSTAmount.toFixed(2));
    }

    const NetAmount = AmountToGive + GSTAmount - TDSAmount;

    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Sale/Return/${id}`, {
        // CreditAccount: Broker._id,
        DebitAccount: InsuranceCompany._id,
        EnterDate,
        Remark,
        TDSAccount: TDSAccount._id,
        GSTAccount: GSTAccount._id,
        TDSAmount,
        GSTAmount,
        TDSPercent,
        GSTPercent,
        TotalAmount: AmountToGive,
        NetAmount,
        UnSelectedPolicyOpt: UnSelectedPolicyOpt,
        SelectedPolicyOpt: SelectedPolicyOpt,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/SaleReturn");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getLedgerMasterForTds();
    getSingleSale();
  }, []);

  let FilteredPolicy;

  if (inputData) {
    FilteredPolicy = Data?.filter(
      (e) =>
        e?.VehicleNumber?.toLowerCase()?.includes(inputData?.toLowerCase()) ||
        e?.InsureeName?.toLowerCase()?.includes(inputData?.toLowerCase()) ||
        e?.PolicyNumber?.toLowerCase()?.includes(inputData?.toLowerCase())
    );
  } else {
    FilteredPolicy = Data;
  }

  const getLedgerMaster = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/LedgerEntry`);
      setDebitAccountOpt(data);
    } catch (error) { }
  };

  useEffect(() => {
    getLedgerMaster();
  }, []);
  const checkIsPresentOrNot = (_id) => {
    const SelectedPolicy = SelectedPolicyOpt.findIndex((ele) => ele === _id);

    return SelectedPolicy === -1 ? false : true;
  };

  const SelectAllDeleteAll = (isChecked) => {
    if (!isChecked) {
      setSelectedPolicyOpt([]);
      setTotalAmountOfSelectPolicy(0);
      const selectALL = Data.map((ele) => {
        return ele?._id;
      });
      setUnSelectedPolicyOpt(selectALL);
    } else {
      let Total = 0;
      const selectALL = Data.map((ele) => {
        let amount = ele?.Commission?.TotalPolicyProfit ?? 0;
        Total = Total + amount;
        return ele?._id;
      });
      setSelectedPolicyOpt([...selectALL]);
      setTotalAmountOfSelectPolicy(Total);
      setUnSelectedPolicyOpt([]);
    }
  };

 
  const selectPolicyHandler = (isChecked, _id, Amount = 0) => {
    if (isChecked) {
      setSelectedPolicyOpt((pre) => [...pre, _id]);
      setTotalAmountOfSelectPolicy((pre) => pre + Amount);
      const SelectedPolicy = UnSelectedPolicyOpt.findIndex(
        (ele) => ele === _id
      );
      UnSelectedPolicyOpt.splice(SelectedPolicy, 1);
      setUnSelectedPolicyOpt([...UnSelectedPolicyOpt]);
    } else {
      const SelectedPolicy = SelectedPolicyOpt.findIndex((ele) => ele === _id);
      SelectedPolicyOpt.splice(SelectedPolicy, 1);
      setSelectedPolicyOpt([...SelectedPolicyOpt]);
      setTotalAmountOfSelectPolicy((pre) => pre - Amount);
      setUnSelectedPolicyOpt((pre) => [...pre, _id]);
    }
  };

  useEffect(() => {
    if (Data.length > 0) {
      SelectAllDeleteAll(true);
    }
  }, [Data]);
  const deleteSale = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/SaleReturnn/${_id}`);

      if (success) {
        navigate("/admin/vouchers/SaleReturnn");
        ToastSusses(message);
      } else {
        ToastError(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          {
            <div className="row">
              <div className="card-body CreateCard">
                <h4 className="card-title">Edit SaleReturn</h4>
                <hr />
                <br />
                <div className="row mx-2 ">
                  <div className="col-md-4 align-items-center">
                    <label>Debit Account</label>
                    <input
                      disabled={true}
                      className="form-control "
                      type="input"
                      value={`${InsuranceCompany["AliasName"] ?? ""}`}
                      placeholder="Enter Receipt"
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>EnterDate</label>
                    <input
                      className="form-control "
                      type="date"
                      name="EnterDate"
                      value={EnterDate}
                      onChange={(e) => setEnterDate(e.target.value)}
                      placeholder="Enter Receipt "
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Credit Account</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select Debit Account --",
                        },
                        ...DebitAccountOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      onChange={setDebitAccount}
                      value={DebitAccount}
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Remark</label>
                    <input
                      className="form-control "
                      type="text"
                      name="Remark"
                      value={Remark}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Remark"
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>GST Account</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select GST Account --",
                        },
                        ...TDSOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;


                      }}
                      onChange={setGSTAccount}
                      value={GSTAccount}
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>GST Percent</label>
                    <input
                      className="form-control "
                      type="number"
                      name="GSTPercent"
                      value={GSTPercent}
                      onChange={(e) => setGSTPercent(e.target.value)}
                      placeholder="Remark"
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>TDS Account</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select TDS Account --",
                        },
                        ...TDSOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      onChange={setTDSAccount}
                      value={TDSAccount}
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>TDS Percent</label>
                    <input
                      className="form-control "
                      type="number"
                      name="TDSPercent"
                      value={TDSPercent}
                      onChange={(e) => setTDSPercent(e.target.value)}
                      placeholder="Enter TDS Percent "
                      required //done
                    />
                  </div>
                </div>
              </div>
              <div className="card-body CreateCard">
                <div
                  className="p-2 filters"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "unset",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div className="d-flex gap-3">
                      <SearchInputFilter
                        setInput={setInput}
                        inputData={inputData}
                        PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
                      />
                      <DownloadPdf id={id} Name={`${InsuranceCompany["AliasName"]}.pdf`} />
                      <NewReturnSaleExcel _id={id} Name={`${InsuranceCompany["AliasName"]}.pdf`} />
                    </div>
                  </div>
                </div>

                <div className="PolicyCounts">
                  <div className="PolicyCounts-Item">
                    <b>Total Net Amount :-</b> {NetAmount}
                  </div>

                  <div className="PolicyCounts-Item">
                    {" "}
                    <b>Total Amount :-</b> {TotalAmountOfSelectPolicy}
                  </div>
                </div>

                {loading ? (
                  <div
                    className=" p-2 "
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <div
                    className=" p-2 "
                    style={{
                      zoom: "70%",
                    }}
                  >
                    {Data && (
                      <table
                        class="table table-striped   table-bordered  overflow-x mt-3"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="selectBoxForTable">
                              <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                                value="Car"
                                checked={
                                  SelectedPolicyOpt.length === Data.length
                                }
                                style={{
                                  width: "23px",
                                  height: "23px",
                                }}
                                onClick={(e) => {
                                  SelectAllDeleteAll(e.target.checked);
                                }}
                              />
                            </th>
                            <th scope="col">S/N</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Details</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>

                            <th scope="col">Vehicle No.</th>

                            <th scope="col">Amount Receive</th>
                          </tr>
                        </thead>

                        <tbody>
                          {FilteredPolicy &&
                            FilteredPolicy?.length !== 0 &&
                            FilteredPolicy.map((data, index) => (
                              <tr key={data._id}>
                                <th scope="row" className="selectBoxForTable">
                                  <input
                                    type="checkbox"
                                    id="vehicle2"
                                    name="vehicle2"
                                    value="Car"
                                    key={data._id}
                                    checked={checkIsPresentOrNot(data._id)}
                                    style={{
                                      width: "23px",
                                      height: "23px",
                                    }}
                                    onClick={(e) => {
                                      selectPolicyHandler(
                                        e.target.checked,
                                        data._id,
                                        data?.Commission?.TotalPolicyProfit
                                      );
                                    }}
                                  />
                                </th>
                                <th>{index + 1}</th>
                                <th>{reversDate(data?.IssueDate)} </th>

                                <th>
                                  <tr> {data?.InsuranceCompany?.Name}</tr>
                                  <tr> {data?.InsuranceType?.InsuranceType}</tr>
                                  <tr> RSD:-{reversDate(data?.RSD)}</tr>
                                  <tr> {data?.InsureeName}</tr>
                                  <tr> {data?.InsuranceCompany?.Name}</tr>
                                </th>

                                <th>
                                  <tr>
                                    <Link
                                      to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {" "}
                                      {data?.PolicyNumber}
                                    </Link>{" "}
                                  </tr>
                                  <tr> {data?.MakeModal?.Name}</tr>
                                  <tr> {data?.Variant}</tr>
                                  <tr> YOM- {data?.YearOfManufacture}</tr>
                                  <tr> {data?.PolicyType?.PolicyTypeName}</tr>
                                </th>

                                <th>
                                  <tr>Gross </tr>
                                  <tr>Net </tr>
                                  <tr>TP</tr>
                                  <tr>OD</tr>
                                  <tr>Flat</tr>
                                </th>

                                <th>
                                  <tr> ₹ {Math.round(data?.GrossPremium)}</tr>
                                  <tr> ₹ {data?.NETPremium}</tr>
                                  <tr> ₹ {data?.TPPremium}</tr>
                                  <tr> ₹ {data?.ODPremium}</tr>
                                  {/* <tr> ₹ {data?.ODPremium}</tr> */}
                                </th>
                                <th>
                                  <tr>
                                    <br />
                                  </tr>
                                  {data?.BasedOn.includes("NET") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableNET / 10 +
                                        "X NET"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("TP") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableTP / 10 +
                                        "X TP"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("OD") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableOD / 10 +
                                        "X OD"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("FLAT") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableFLAT +
                                        "FLAT"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                </th>
                                <th>
                                  <tr> {data?.PaymentMode}</tr>
                                  <tr> {data?.PolicyType?.Name}</tr>
                                  <tr> {data?.VehicleNumber}</tr>
                                  {/* <tr> {data?.ODPremium}</tr> */}
                                </th>
                                <th>
                                  <tr>
                                    {" "}
                                    ₹ {data?.Commission?.TotalPolicyProfit}
                                  </tr>
                                </th>
                              </tr>
                            ))}
                          <tr>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>

                            <th scope="row">
                              ₹ {TotalAmountOfSelectPolicy ?? 0}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      ></div>
                    }
                  </div>
                )}

                <div className="d-flex gap-3 justify-content-center">
                  <button
                    type="submit"
                    className="btn mybtn"
                    // style={{ marginTop: 28 }}
                    onClick={() => {
                      navigate("/admin/vouchers/Sale");
                    }}
                  >
                    Go Back
                  </button>

                  {
                    <button
                      type="submit"
                      className="btn mybtn"
                      // style={{ marginTop: 28 }}
                      onClick={() => {
                        handleEdit();
                      }}
                    >
                      Submit
                    </button>
                  }
                  {
                    <button
                      type="submit"
                      className="btn bg-danger text-white"
                      // style={{ marginTop: 28 }}
                      onClick={() => {
                        const isTrue = window.confirm("Do you want to delete!");
                        if (isTrue) {
                          deleteSale(id);
                        }
                      }}
                    >
                      Delete
                    </button>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <Footer />
    </>
  );
};
const DownloadPdf = ({ id, Name }) => {
  const [loading, setLoading] = useState(false);

  const getSingleSale = async () => {
    setLoading(true);
    try {
      axios({
        url: `${REACT_APP_FileURL}/api/v1/Sale/getGenerateReport/${id}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = Name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <img
          src={require("../utils/UiImages/Download.gif")}
          alt="My GIF"
          style={{
            width: "35px",
            height: "34px",
          }}
        />
      ) : (
        <AiFillFilePdf
          onClick={() => {
            getSingleSale();
          }}
          size={35}
          style={{
            backgroundColor: "green",
            color: "#fff",
            padding: "7px",
            borderRadius: "5px",
          }}
        />
      )}
    </>
  );
};
export default EditSaleReturn;
