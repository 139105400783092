import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { BiEdit } from "react-icons/bi";
import Select from "react-select";
import { coverAmountIntoCurrent } from "../../Utils/usefullFunction";
const CreatePaymentList = () => {
  const MASTER_DB = ["CASH", "BANK"];
  const MASTER_DB2 = ["LedgerGroup", "POS", "Broker", "Employee"];
  const MASTER_DB3 = ["194 JA", "194 JB", "194 C", "194 H", "194 I", " 192 B"];
  const today = new Date();

  const lastDay = today.toISOString().substr(0, 10);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");
  const Mode = Boolean(queryParams.get("Mode"));

  const [EntryDate, setEntryDate] = useState(lastDay);
  const [TDSOpt, setTDSOpt] = useState([]);
  const [CashBankAccount, setCashBankAccount] = useState();
  const [Remark, setRemark] = useState("");
  const [POSBankDetails, setPOSBankDetails] = useState([]);
  const [TDSPercent, setTDSPercent] = useState(0);
  const [TDSAccount, setTDSAccount] = useState({
    _id: "",
    Name: "-- Select TDS Account --",
  });
  const [PartyAccount, setPartyAccount] = useState("");
  const [PartyBankDetails, setPartyBankDetails] = useState({
    _id: null,
    Name: "--Select Bank --",
  });

  const [PartyAccountOpt, setPartyAccountOpt] = useState();
  const [Account, setAccount] = useState(0);
  const [RefNumber, setRefNumber] = useState("");
  const [PaymentList, setPaymentList] = useState([]);
  const [FromModal, setFromModal] = useState("");
  const [Section, setSection] = useState("");
  const [From, setFrom] = useState();
  const [PartyAccountOptFor, setPartyAccountOptFor] = useState([]);
  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const que = {};

      if (FromModal === "BANK") {
        if (!CashBankAccount._id) {
          return ToastError("BANK Account is required");
        }
        que["CashBankAccount"] = CashBankAccount._id;
      } else if (FromModal === "CASH") {
        if (!CashBankAccount._id) {
          return ToastError("Cash Account is required");
        }
        que["LedgerEntry"] = CashBankAccount._id;
      } else {
        return ToastError("Ledger Type is required");
      }

      if (PaymentList.length === 0) {
        return ToastError("Please select Party Account for create this ledger");
      }
      const {
        data: { success, message },
      } = await axiosInstance.post("/PaymentList", {
        ...que,
        EntryDate,
        Remark,
        PaymentList,
        FromModal,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/PaymentList/data");
      } else {
        ToastSusses(message);
        // navigate("/admin/PaymentList/data");
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    const que = {};

    if (FromModal === "BANK") {
      if (!CashBankAccount?._id) {
        return ToastError("BANK Account is required");
      }
      que["CashBankAccount"] = CashBankAccount?._id;
    } else if (FromModal === "CASH") {
      if (!CashBankAccount?._id) {
        return ToastError("CASH Account is required");
      }
      que["LedgerEntry"] = CashBankAccount?._id;
    } else {
      return ToastError("Ledger Type is required");
    }

    if (PaymentList.length === 0) {
      return ToastError("Please select Party Account for create this ledger");
    }
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/PaymentList/${_id}`, {
        ...que,
        EntryDate,
        Remark,
        PaymentList,
        FromModal,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/PaymentList/data");
      } else {
        ToastSusses(message);
        // navigate("/admin/PaymentList/data");
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const editHandler = (data, index) => {
    if (Account || RefNumber || Account) {
      return ToastWarn("Please Add To List First");
    }

    if (data.From === "POS") {
      if (data?.PartyAccount) {
        setPartyAccount({ _id: data?.PartyAccount, Name: data?.Name });
        getAllBankDetails(data?.PartyAccount, "Pos");
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
        getAllBankDetails(data?._id, "Pos");
      }
    }
    if (data.From === "Employee") {
      if (data?.Employee) {
        setPartyAccount({ _id: data?.Employee, Name: data?.Name });
        getAllBankDetails(data?.Employee, "Employee");
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
        getAllBankDetails(data?._id, "Employee");
      }
      //  LedgerGroup
    }
    if (data.From === "Broker") {
      if (data?.Broker) {
        setPartyAccount({ _id: data?.Broker, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
    }

    if (data.From === "LedgerGroup") {
      if (data?.LedgerEntry) {
        setPartyAccount({ _id: data?.LedgerEntry, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }

    if (data?.BankDetailName) {
      setPartyBankDetails({
        _id: data?.BankDetail,
        Name: data?.BankDetailName,
      });
    } else if (data?.BankDetail instanceof Object) {
      if (data?.BankDetail?._id) {
        setPartyBankDetails({
          _id: data?.BankDetail?._id,
          Name: `${data?.BankDetail?.AccountHolderName ?? ""}/${
            data?.BankDetail?.Name ?? ""
          }/${data?.BankDetail?.IFSC ?? ""}/${
            data?.BankDetail?.BankAccountNo ?? ""
          }`,
        });
      }
    } else {
      setPartyBankDetails({
        _id: null,
        Name: "--Select Bank --",
      });
      setPOSBankDetails([]);
    }
    getPos(data.From);
    setAccount(data?.Account ?? 0);
    setRefNumber(data.RefNumber);
    setFrom(data.From);
    setSection(data?.Section ?? "");
    if (data?.TDSAccount) {
      setTDSAccount(data?.TDSAccount);
    } else {
      setTDSAccount({
        _id: "",
        Name: "-- Select TDS Account --",
      });
    }
    if (data?.TDSPercent) {
      setTDSPercent(data?.TDSPercent);
    } else {
      setTDSPercent(0);
    }

    const PaymentListArr = PaymentList;
    PaymentListArr.splice(index, 1);
    setPaymentList([...PaymentListArr]);
  };
  const removeHandler = (index) => {
    const PaymentListArr = PaymentList;
    PaymentListArr.splice(index, 1);
    setPaymentList([...PaymentListArr]);
  };
  const addHandler = () => {
    if (!PartyAccount || !Account) {
      return ToastWarn("Please enter a valid field");
    }

    const que = {};
    const PartyAccountData = PartyAccount;
    if (From === "POS") {
      que["PartyAccount"] = PartyAccountData._id;
      // que["Address"] = PartyAccountData;
    } else if (From === "LedgerGroup") {
      que["LedgerEntry"] = PartyAccountData._id;
    } else if (From === "Broker") {
      que["Broker"] = PartyAccountData._id;
    } else if (From === "Employee") {
      que["Employee"] = PartyAccountData._id;
    }
    if (PartyBankDetails?._id) {
      que["BankDetail"] = PartyBankDetails._id;
      que["BankDetailName"] = PartyBankDetails.Name;
    }
    let TDSAmount = Math.round((Account * TDSPercent) / 100);

    if (TDSAccount?._id) {
      que["TDSAccount"] = TDSAccount;
    }
    if (Section) {
      que["Section"] = Section;
    }
    if (TDSAmount) {
      que["TDSAmount"] = TDSAmount;
    } else {
      que["TDSAmount"] = 0;
    }
    if (TDSPercent) {
      que["TDSPercent"] = TDSPercent;
    } else {
      que["TDSPercent"] = 0;
    }
    if (!PartyAccountData?._id) {
      return ToastWarn("Please enter a Party Account");
    }
    // TDSAmount
    setPaymentList([
      ...PaymentList,
      {
        // PartyAccount: PartyAccountData._id,
        Account: Number(Account),
        RefNumber,
        Name: PartyAccountData.Name,
        From: From,
        // TDSPercent,
        // TDSAmount,
        // AmountToGive,
        ...que,
      },
    ]);

    setPartyAccount({
      _id: "",
      Name: "-- Select --",
    });
    setTDSAccount({
      _id: "",
      Name: "-- Select TDS Account --",
    });
    setTDSPercent(0);
    setAccount(0);
    setFrom("");
    setSection("");
    setPartyBankDetails({
      _id: null,
      Name: "--Select Bank --",
    });
    setRefNumber("");
    setPOSBankDetails([]);
  };

  const getAllBankDetails = async (id, For) => {
    try {
      try {
        const {
          data: { data = [] },
        } = await axiosInstance.get(
          `/PBD/getPBDForPos/byName/${id}?For=${For}`
        );

        setPOSBankDetails(data);
      } catch (error) {}
    } catch (error) {}
  };
  const getPos = async (isData) => {
    try {
      if (isData === "LedgerGroup") {
        const {
          data: { data: LedgerGroupData },
        } = await axiosInstance.get("/LedgerEntry");

        const LedgerGroup = LedgerGroupData.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt(LedgerGroup);
      }
      if (isData === "Employee") {
        const {
          data: { data: EmployeeDetails },
        } = await axiosInstance.get("/Employee/selectBoxDropdown");

        const EMP = EmployeeDetails.map(
          ({
            Name = "",
            Salutation = "",
            MiddleName = "",
            LastName = "",
            _id,
          }) => {
            return {
              Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
              _id,
            };
          }
        );
        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...EMP,
        ]);
      }
      if (isData === "Broker") {
        const {
          data: { data: Broker },
        } = await axiosInstance.get("/Broker");

        const LedgerGroup = Broker.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt(LedgerGroup);
      }
      if (isData === "POS") {
        const {
          data: { data: posDetails },
        } = await axiosInstance.post("/Pos/getPosByField", {
          showFiled: {
            Name: 1,
            Salutation: 1,
            MiddleName: 1,
            LastName: 1,
            Email: 1,
            BankAccountNo: 1,
            IFSC: 1,
          },
        });
        const POS = posDetails.map(
          ({
            Name = "",
            Salutation = "",
            MiddleName = "",
            LastName = "",
            _id,
            ...rest
          }) => {
            return {
              Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
              _id,
              ...rest,
            };
          }
        );
        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...POS,
        ]);
      }
    } catch (error) {}
  };
  const getLedgerMasterForTds = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/LedgerEntry?Group=648ac8ca7df926cfdd2ddccb`
      ); // 	Duties and Tax
      setTDSOpt(data);
    } catch (error) {}
  };

  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/PaymentList/single/${_id}`);

      getPosPartyAccount(value?.FromModal);
      setFromModal(value?.FromModal);
      setRemark(value?.Remark);
      setPaymentListHandler(value?.PaymentList);
      setEntryDate(value?.EntryDate);
      setCashBankOptHandler(value);
    } catch (error) {}
  };

  const setPaymentListHandler = (PaymentList = []) => {
    const myArr = PaymentList?.map((ele) => {
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Broker: BrokerDetails,
        Employee: EmployeeDetails,
        _id,
        ...rest
      } = ele;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
        };
      }

      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }

      if (EmployeeDetails) {
        const { _id: Employee, Name } = EmployeeDetails;
        obj = {
          ...rest,
          Employee,
          Name,
        };
      }
      return obj;
    });

    setPaymentList(myArr);
  };
  const setCashBankOptHandler = (value) => {
    const { LedgerEntry: Entry, CashBankAccount: Bank } = value;
    let obj;
    if (Entry) {
      const { _id, Name } = Entry;
      obj = {
        _id,
        Name,
      };
    }
    if (Bank) {
      const { _id, Name } = Bank;
      obj = {
        _id,
        Name,
      };
    }

    setCashBankAccount(obj);
  };

  const getPosPartyAccount = async (isData) => {
    try {
      if (isData === "BANK") {
        const {
          data: { data },
        } = await axiosInstance.get("/Bank");

        // setCashBankAccountOpt([...data, ...LedgerGroup]);
        setPartyAccountOptFor(data);
      }

      if (isData === "CASH") {
        const {
          data: { data: LedgerGroupData },
        } = await axiosInstance.get("/LedgerEntry/getCashAccount");

        const LedgerGroup = LedgerGroupData.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOptFor(LedgerGroup);
      }
    } catch (error) {}
  };

  const getLedger = async (_id) => {
    try {
      const {
        data: { TotalAmount },
      } = await axiosInstance.get(`/Accountancy/getTotalAmount?Ledger=${_id}`);
      if (TotalAmount < 0) {
        setAccount(Math.abs(TotalAmount));
      } else {
        setAccount(0);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getLedgerMasterForTds();
  }, []);
  useEffect(() => {
    if (_id) {
      getDataById();
    }
  }, [_id]);
  return (
    <>
      <Header />

      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>{Mode ? "Edit PaymentList" : "Create PaymentList"}</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              {/* Employee Information */}
              <div className="col-md-2 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control"
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  onChange={(e) => setEntryDate(e.target.value)}
                  placeholder="Enter PaymentList "
                  required //done
                />
              </div>
              <div className="col-md-2 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={FromModal}
                  onChange={(e) => {
                    setFromModal(e.target.value);
                    getPosPartyAccount(e.target.value);
                    setCashBankAccount({
                      _id: "",
                      Name: "-- Select --",
                    });
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label required>CashBankAccount</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOptFor}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={setCashBankAccount}
                  value={CashBankAccount}
                />
                {/* <select
                  class="form-select"
                  aria-label="Default select example"
                  name="InsuranceCompany"
                  value={CashBankAccount}
                  onChange={(e) => setCashBankAccount(e.target.value)}
                  required
                  
                >
                  <option value="">
                    -------------Select Bank-------------
                  </option>
                  {CashBankAccountOpt &&
                    CashBankAccountOpt.map((data) => (
                      <option value={data._id}>{data.Name}</option>
                    ))} 
             
               </select> */}
              </div>
              <div className="col-md-3 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={From}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    getPos(e.target.value);
                    // getAllBankDetails([]);
                    setPOSBankDetails([]);
                    setPartyBankDetails({
                      _id: null,
                      Name: "--Select Bank --",
                    });
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB2.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label>Party Account</label>

                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOpt}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={(ele) => {
                    if (From === "POS") {
                      getAllBankDetails(ele._id, "Pos");
                    } else if (From === "Employee") {
                      getAllBankDetails(ele._id, "Employee");
                    } else {
                      setPOSBankDetails([]);
                    }
                    getLedger(ele._id);
                    setPartyAccount(ele);
                  }}
                  value={PartyAccount}
                />
                {/* <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PartyAccount"
                  value={PartyAccount}
                  onChange={(e) => {
                    setPartyAccount(e.target.value);
                  }}
                  required
                  
                >
                  <option value="">-------------Select -------------</option>
                  {PartyAccountOpt &&
                    PartyAccountOpt.map((data) => (
                      <option value={JSON.stringify(data)}>{data.Name}</option>
                    ))}
              
                </select> */}
              </div>
              <div className="col-md-3 align-items-center">
                <label>Bank Details</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={POSBankDetails}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={(ele) => {
                    setPartyBankDetails(ele);
                  }}
                  value={PartyBankDetails}
                />
                {/* <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PartyAccount"
                  value={PartyAccount}
                  onChange={(e) => {
                    setPartyAccount(e.target.value);
                  }}
                  required
                  
                >
                  <option value="">-------------Select -------------</option>
                  {PartyAccountOpt &&
                    PartyAccountOpt.map((data) => (
                      <option value={JSON.stringify(data)}>{data.Name}</option>
                    ))}
              
                </select> */}
              </div>
              <div className="col-md-1 align-items-center">
                <label>Amount</label>
                <input
                  className="form-control "
                  type="number"
                  name="Account"
                  value={Account}
                  onChange={(e) => setAccount(e.target.value)}
                  placeholder="Amount"
                  required //done
                />
              </div>
              <div className="col-md-3 align-items-center">
                <label>Narration</label>
                <input
                  className="form-control "
                  type="text"
                  name="Ref Numbe"
                  value={RefNumber}
                  onChange={(e) => setRefNumber(e.target.value)}
                  placeholder="Enter Narration"
                  required //done
                />
              </div>
              <div className="col-md-2 align-items-center">
                <label>TDS Account</label>

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={[
                    {
                      _id: "",
                      Name: "-- Select TDS Account --",
                    },
                    ...TDSOpt,
                  ]}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => {
                    const Name = `${option?.Name}`;
                    return Name;
                  }}
                  onChange={setTDSAccount}
                  value={TDSAccount}
                />
              </div>
              <div className="col-md-2 align-items-center">
                <label>Under Sections</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="Sections"
                  value={Section}
                  onChange={(e) => {
                    setSection(e.target.value);
                  }}
                  required
                >
                  <option value=""> Select Sections</option>

                  {MASTER_DB3.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-2 align-items-center">
                <label>TDS Percent</label>
                <input
                  className="form-control "
                  type="number"
                  name="TDSPercent"
                  value={TDSPercent}
                  onChange={(e) => setTDSPercent(e.target.value)}
                  placeholder="TDS Percent"
                  required //done
                />
              </div>
              {
                <div
                  className="col-md-3 align-items-center"
                  style={{
                    paddingTop: "1.4rem",
                  }}
                >
                  <button
                    className="btn mybtn"
                    onClick={() => {
                      addHandler();
                    }}
                  >
                    Add to list
                  </button>
                </div>
              }
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">PartyAccount</th>
                        <th scope="col">Bank/Account No/IFSC</th>

                        <th scope="col">TDS Account</th>
                        <th scope="col">Section</th>
                        <th scope="col">TDS Percent</th>
                        <th scope="col">Amount</th>

                        <th scope="col">TDS Amount</th>
                        <th scope="col">Without TDS</th>

                        <th scope="col">Narration</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PaymentList &&
                        PaymentList.length >= 0 &&
                        PaymentList.map((ele, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <td>{ele?.Name}</td>
                            </td>
                            <td>
                              {ele?.BankDetailName
                                ? ele?.BankDetailName
                                : ele?.BankDetail?.Name &&
                                  `${
                                    ele?.BankDetail?.AccountHolderName ?? ""
                                  }/${ele?.BankDetail?.Name ?? ""}/${
                                    ele?.BankDetail?.IFSC ?? ""
                                  }/${ele?.BankDetail?.BankAccountNo ?? ""}`}
                            </td>
                            <td>{ele?.TDSAccount?.Name}</td>
                            <td>{ele?.Section}</td>
                            <td>{ele?.TDSPercent}%</td>
                            <td>{coverAmountIntoCurrent(ele?.Account)}</td>

                            <td>{coverAmountIntoCurrent(ele?.TDSAmount)}</td>
                            <td>
                              {coverAmountIntoCurrent(
                                ele?.Account - ele?.TDSAmount
                              )}
                            </td>
                            <td>{ele?.RefNumber}</td>

                            <td>
                              <BiEdit
                                size={32}
                                style={{
                                  backgroundColor: "green",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => editHandler(ele, index)}
                              />
                              &nbsp;
                              <AiFillDelete
                                size={32}
                                style={{
                                  backgroundColor: "#A50406",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => {
                                  const isTrue = window.confirm(
                                    "Do you want to delete!"
                                  );
                                  if (isTrue) {
                                    removeHandler(index);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <th scope="row"></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total:-</td>
                        <td>
                          {coverAmountIntoCurrent(
                            PaymentList.reduce((sum, a) => sum + a?.Account, 0)
                          )}
                        </td>
                        <td>
                          {coverAmountIntoCurrent(
                            PaymentList.reduce(
                              (sum, a) => sum + a?.TDSAmount,
                              0
                            )
                          )}
                        </td>
                        <td>
                          {coverAmountIntoCurrent(
                            PaymentList.reduce((sum, a) => {
                              const total = a?.Account - a?.TDSAmount;
                              return sum + total;
                            }, 0)
                          )}
                        </td>

                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

          <div className="col-lg-5" style={{ float: "left" }}></div>
          <div className="col-lg-2" style={{ float: "left" }}>
            <center>
              <button
                className="btn mybtn "
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreatePaymentList;
