import React from "react";
import { BasedOnarr } from "../../Utils/CommonFields";

const ManualGridForm = ({ setPayoutData, setBasedOn, payoutData, BasedOn }) => {
  const numberRegex = /^[0-9]*\.?[0-9]*$/;

  const handleChange = (e, field) => {
    const value = e.target.value;

    if (numberRegex.test(value)) {
      setPayoutData((prev) => ({
        ...prev,
        [field]: +value,
      }));
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.selectWrapper}>
        <label style={styles.label}>Based On</label>
        <select
          value={BasedOn ?? ""}
          onChange={(e) => {
            setPayoutData((prev) => ({ ...prev, BasedOn: e.target.value }));
            setBasedOn(e.target.value);
            // setBased(e.target.value);
          }}
          style={styles.select}
        >
          <option value="">--Select--</option>
          {BasedOnarr.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div style={styles.inputRow}>
        {BasedOn && BasedOn.split("+").length === 1 && (
          <div style={styles.inputWrapper}>
            <label style={styles.label}>{`Reciveable ${
              BasedOn.split("+")[0]
            }`}</label>
            <input
              type="number"
              id="Reciveable"
              name="firstInput"
              value={payoutData?.Reciveable ?? ""}
              onChange={(e) => handleChange(e, "Reciveable")}
              placeholder={`Enter ${BasedOn.split("+")[0]} value`}
              style={styles.input}
            />
          </div>
        )}

        {BasedOn && BasedOn.split("+").length === 2 && (
          <>
            <div style={styles.inputWrapper}>
              <label style={styles.label}>{`Reciveable ${
                BasedOn.split("+")[0]
              }`}</label>
              <input
                type="number"
                name="firstInput"
                id="Reciveable"
                value={payoutData?.Reciveable ?? ""}
                onChange={(e) => handleChange(e, "Reciveable")}
                placeholder={`Enter ${BasedOn.split("+")[0]} value`}
                style={styles.input}
              />
            </div>
            <div style={styles.inputWrapper}>
              <label style={styles.label}>{`Reciveable ${
                BasedOn.split("+")[1]
              }`}</label>
              <input
                type="text"
                name="secondInput"
                value={payoutData?.ReciveableTP ?? ""}
                onChange={(e) => handleChange(e, "ReciveableTP")}
                placeholder={`Enter ${BasedOn.split("+")[1]} value`}
                style={styles.input}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "5px",
    // padding: '20px',
    maxWidth: "100%",
    // margin: '0 auto',
    // backgroundColor: '#f9f9f9',
  },
  selectWrapper: {
    marginBottom: "20px",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  select: {
    width: "100%",
    padding: "8px",
    fontSize: "14px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    outline: "none",
  },
  inputRow: {
    display: "flex", // Align inputs in a row
    gap: "5px", // Space between the inputs
    // justifyContent: 'space-between', // Distribute the inputs evenly
  },
  inputWrapper: {
    flex: 1, // Allow input fields to grow equally
    minWidth: "150px", // Set a minimum width to avoid them shrinking too much
  },
  input: {
    width: "100%",
    padding: "8px",
    fontSize: "14px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    outline: "none",
  },
};
export default ManualGridForm;
