import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../config";

const MultiInsurance = ({
  Options,
  SelectedInsuranceType,
  setSelectedInsuranceType,
  index,
  OptionsValue,
  view,
  IsFilter = false,
  getMakeModal = () => [],
  InsuranceCompany,
  col = "col-md-4 align-items-center",
  isLabel,
  ShowLabel = true,
  getRTOGroupOpt = () => [],
  setRTOGroup = () => [],
}) => {
  const [InsuranceType, setInsuranceType] = useState(OptionsValue ?? "");
  const [IsFirstCall, setIsFirstCall] = useState(true);

  const getDataInsuranceType = async () => {
    const selectedObj = SelectedInsuranceType;
    if (!InsuranceType) {
      selectedObj[index]["value"] = "";
      selectedObj.length = index + 1;
      return setSelectedInsuranceType([...selectedObj]);
    }
    try {
      const {
        data: { data = [] },
      } = await axiosInstance.get(
        `/InsuranceType?InsuranceUnder=${InsuranceType}&InsuranceCompany=${
          InsuranceCompany ?? ""
        }`
      );
      selectedObj[index]["value"] = InsuranceType;
      selectedObj.length = index + 1;
      if (data && data?.length !== 0) {
        return setSelectedInsuranceType([...selectedObj, { data, value: "" }]);
      } else {
        return setSelectedInsuranceType([...selectedObj]);
      }

      // setInsuranceTypeData(data);
    } catch (error) {}
  };
  useEffect(() => {
    if (IsFirstCall) {
      return setIsFirstCall(false);
    }
    getDataInsuranceType();
  }, [InsuranceType]);

  const isMotorProductSelected =
    SelectedInsuranceType?.[SelectedInsuranceType.length - 1]?.data?.[0]
      ?.InsuranceCategory === "Motor" ?? false;

  return (
    <>
      {
        <div className={IsFilter ? "" : col}>
          {ShowLabel && (
            <label
              required={!IsFilter ? true : false}
              className={isLabel && "my-2"}
            >
              {index === 0
                ? "Product"
                : index === 1
                ? "Sub Product"
                : isMotorProductSelected
                ? "Vehicle Class"
                : "Product Name"}
            </label>
          )}
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => {
              setRTOGroup([]);
              getRTOGroupOpt({ InsuranceType: e.target.value });
              if (index === 0) getMakeModal(e.target.value);
              setInsuranceType(e.target.value);
            }}
            key={OptionsValue}
            value={InsuranceType}
            style={
              IsFilter
                ? { width: "10vw", backgroundColor: "#F5F5F6", height: "38px" }
                : {}
            }
            required={index === 0 ? true : false}
            disabled={view}
          >
            <option value="">
              {!IsFilter ? "Select" : ""}{" "}
              {index === 0
                ? "Product"
                : index === 1
                ? "Sub Product"
                : isMotorProductSelected
                ? "Vehicle Class"
                : "Product Name"}
            </option>
            {Options &&
              Options?.data &&
              Options?.data.length > 0 &&
              Options?.data.map((data) => (
                <option key={data?._id} value={data._id}>{`${
                  data.InsuranceType
                } ${data?.InsuranceCompany?.Name ? "-" : ""} ${
                  data?.InsuranceCompany?.Name ?? ""
                }`}</option>
              ))}
          </select>
        </div>
      }
    </>
  );
};

export default MultiInsurance;
