import React, { useEffect, useState } from "react";
import { ToastError, ToastInfo, ToastSusses, ToastWarn } from "./Tost";
import { axiosInstance } from "../config";
import { ValidateAccountDetails } from "./usefullFunction";
import UploadBankDocModal from "../backend/Modals/UploadBankDocModal";

const PortalBankDetails = ({
  view,
  Name,
  MiddleName,
  LastName,
  value,
  Mode,
  BankDetails,
  setBankDetails,
  For = "Pos",
}) => {
  const [IsEditCheck, setIsEditCheck] = useState(false);
  const [BankDocModal, setBankDocModal] = useState(false);
  const [SelectedBankId, setSelectedBankId] = useState();
  const [BankName, setBankName] = useState("");
  const [AccountHolderName, setAccountHolderName] = useState("");
  const [PAN_NUMBER, setPAN_NUMBER] = useState("");
  const [ModalDataForBankDetails, setModalDataForBankDetails] = useState({});
  const [IFSC, setIFSC] = useState("");
  const [BankAccountNo, setBankAccountNo] = useState("");

  const editHandler = async (index, IsEdit) => {
    if (IsEditCheck) {
      return ToastWarn("Please First Add Selected Data");
    }
    const AddDetailsData = BankDetails;
    const restData = AddDetailsData.splice(index, 1);
    setBankDetails([...AddDetailsData]);
    if (IsEdit) {
      setIsEditCheck(true);
      setSelectedBankId(restData[0]._id);
      setBankAccountNo(restData[0]?.BankAccountNo);
      setIFSC(restData[0]?.IFSC);
      setAccountHolderName(restData[0]?.AccountHolderName);
      setBankName(restData[0]?.BankName);
      setPAN_NUMBER(restData[0]?.PanNumber);
      return ToastInfo("Selected");
    }

    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/PBD/${restData[0]._id}`);
      if (success) {
        return ToastSusses(message);
      }
    } catch (error) {
      return ToastError(error?.response?.data?.message);
    }
  };
  const addBankDetailsHandler = async () => {
    if (!BankName && !IFSC && !BankAccountNo && !AccountHolderName) {
      return ToastWarn("All Field Empty");
    }

    try {
      if (IsEditCheck) {
        const {
          data: { success, message, data },
        } = await axiosInstance.put(
          `/PBD/${SelectedBankId}`,
          {
            BankName: BankName,
            IFSC,
            BankAccountNo,
            AccountHolderName,
            PanNumber: PAN_NUMBER,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (success) {
          ToastSusses(message);
          setBankDetails([...BankDetails, data]);
        } else {
          ToastSusses(message);
        }
      } else {
        const que = {};

        if (value?._id) {
          // [For]: value._id
          que[For] = value._id;
        }

        const {
          data: { success, message, data },
        } = await axiosInstance.post(
          `/PBD`,
          {
            ...que,
            BankName: BankName,
            IFSC,
            BankAccountNo,
            AccountHolderName,
            PanNumber: PAN_NUMBER,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (success) {
          ToastSusses(message);
          setBankDetails([...BankDetails, data]);
        } else {
          ToastSusses(message);
        }
      }

      setIsEditCheck(false);
      setBankAccountNo("");
      setIFSC("");
      setAccountHolderName("");
      setBankName("");
      setPAN_NUMBER("");
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getPosBankDetails = async () => {
    try {
      const {
        data: { data = [] },
      } = await axiosInstance.get(
        `/PBD/getPBDForPos/${value._id}?searchBy=${For}`
      );

      setBankDetails(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (Mode) {
      getPosBankDetails();
    }
  }, []);

  return (
    <>
      <UploadBankDocModal
        handleOpen={() => {
          setBankDocModal(true);
        }}
        handleClose={() => {
          setBankDocModal(false);
        }}
        open={BankDocModal}
        ModalData={ModalDataForBankDetails}
        BankDetails={BankDetails}
        setBankDetails={setBankDetails}
      />

      <div className="col-md-3 align-items-center">
        <label>Account Holder Name</label>
        <input
          disabled={view}
          className="form-control "
          type="text"
          name="AccountHolderName"
          value={AccountHolderName}
          onChange={(e) => setAccountHolderName(e.target.value)}
          placeholder="Enter Account Holder Name"
        />
      </div>
      <div className="col-md-3 align-items-center">
        <label>BankName</label>
        <input
          disabled={view}
          className="form-control "
          type="text"
          name="BankName"
          value={BankName}
          onChange={(e) => setBankName(e.target.value)}
          placeholder="Enter BankName"
        />
      </div>
      <div className="col-md-2 align-items-center">
        <label> IFSC Code</label>
        <input
          disabled={view}
          className="form-control "
          type="text"
          name="IFSC"
          value={IFSC}
          onChange={(e) => setIFSC(e.target.value)}
          placeholder="Enter IFSC Code"
        />
      </div>
      <div className="col-md-2 align-items-center">
        <label>Bank Account Number</label>
        <input
          disabled={view}
          className="form-control "
          type="text"
          name="BankAccountNo"
          value={BankAccountNo}
          onChange={(e) => setBankAccountNo(e.target.value)}
          placeholder="Enter Bank Account Number"
        />
      </div>
      <div className="col-md-2 align-items-center">
        <label>PAN Number</label>
        <input
          disabled={view}
          className="form-control "
          type="text"
          name="PANNUMBER"
          value={PAN_NUMBER}
          onChange={(e) => setPAN_NUMBER(e.target.value)}
          placeholder="Enter PAN Number"
        />
      </div>
      <div className="mt-2">
        <button
          type="button"
          className="btn mybtn"
          // style={{ marginTop: 28 }}
          onClick={() => {
            addBankDetailsHandler();
          }}
        >
          Add Details
        </button>
      </div>

      <hr className="m-4" />

      {BankDetails &&
        BankDetails.length > 0 &&
        BankDetails.map((ele, index) => {
          return (
            <div
              className="row mb-2"
              style={{
                border: ".8px solid gray",
                padding: "10px",
                borderRadius: "5px",
              }}
              key={ele._id}
            >
              <div className="col-md-3 align-items-center">
                <input
                  disabled={true}
                  className="form-control "
                  type="text"
                  name="AccountHolderName"
                  value={ele.AccountHolderName}
                  placeholder="Enter Account Holder Name"
                />
              </div>
              <div className="col-md-3 align-items-center">
                <input
                  disabled={true}
                  className="form-control "
                  type="text"
                  name="BankName"
                  value={ele.BankName}
                  placeholder="Enter BankName"
                />
              </div>
              <div className="col-md-2 align-items-center">
                <input
                  disabled={true}
                  className="form-control "
                  type="text"
                  name="IFSC"
                  value={ele.IFSC}
                  placeholder="Enter IFSC Code"
                />
              </div>
              <div className="col-md-2 align-items-center">
                <input
                  disabled={true}
                  className="form-control "
                  type="text"
                  name="BankAccountNo"
                  value={ele.BankAccountNo}
                  onChange={(e) => setBankAccountNo(e.target.value)}
                  placeholder="Enter Bank Account Number"
                />
              </div>
              <div className="col-md-2 align-items-center">
                <input
                  disabled={true}
                  className="form-control "
                  type="text"
                  name="PAN_NUMBER"
                  value={ele.PanNumber}
                  onChange={(e) => setPAN_NUMBER(e.target.value)}
                  placeholder="Enter Pan Number"
                />
              </div>

              <div className="mt-3">
                {!view && (
                  <div className="gap-2 d-flex">
                    {ValidateAccountDetails(
                      ele?.AccountHolderName,
                      `${Name} ${MiddleName} ${LastName}`
                    ) && (
                      <button
                        button
                        type="button"
                        className="btn mybtn"
                        onClick={() => {
                          setModalDataForBankDetails({
                            ...ele,
                          });

                          setBankDocModal(true);
                        }}
                      >
                        Upload Files
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => {
                        editHandler(index, true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        const isTrue = window.confirm("Do you want to delete!");
                        if (isTrue) {
                          editHandler(index, false);
                        }
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default PortalBankDetails;
