import axios from "axios";

export const axiosInstance = axios.create({
  withCredentials: true,
  // baseURL: "http://localhost:8000/api/v1",
  baseURL: "https://goodventures.in/api/v1",
  // baseURL: "http://44.198.130.81/api/v1",
});

// export const REACT_APP_FileURL = "http://localhost:8000";
export const REACT_APP_FileURL = "https://goodventures.in";
// export const REACT_APP_FileURL = "http://44.198.130.81";
