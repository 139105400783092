import React, { useState, useEffect } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Alert, AlertTitle } from "@mui/material";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  validateHeadersExcel,
  validateHeadersEmployeeExcel,
  validateAndUpdateField,
  updateInsuranceType,
} from "../../lib/validateExcel";
import { validateHeadersPos, createMapping } from "../../lib/validateData";
import "./styles.css";
import { axiosInstance, REACT_APP_FileURL } from "../../config";

const transformData = (data) => {
  const result = data.reduce(
    (acc, curr) => {
      const Branch = curr["Branch"];
      const ReportingTo = curr["Reporting To"];
      const Permission = curr["Permission"];
      if (Branch) acc.Branch.add(Branch);

      if (ReportingTo) acc.ReportingTo.add(ReportingTo);
      if (Permission) acc.Permission.add(Permission);
      return acc;
    },
    {
      Branch: new Set(),
      ReportingTo: new Set(),
      Permission: new Set(),
    }
  );

  return {
    Branch: Array.from(result.Branch),
    ReportingTo: Array.from(result.ReportingTo),
    Permission: Array.from(result.Permission),

  };
};

const ImportEmployee = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    Branch: [],
    ReportingTo: [],
    Permission: [],
  });
  const [fileData, setFileData] = useState([]);
  const [headers, setHeaders] = useState([]);

     const [insertedCount, setInsertedCount] = useState(null);
      const [bgColor, setBgColor] = useState("");

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);

      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = jsonData[0];
      const response = validateHeadersEmployeeExcel(headers);

      if (!response?.valid) {
        setError(response);
        return;
      }
      const rows = jsonData.slice(1);

      const transformedData = rows.reduce((acc, row) => {
        if (row.length === 0) return acc;

        const transformedRow = Object.fromEntries(
          row
            .map((cell, index) => {
              const header = headers[index];
              if (
                typeof cell === "number" &&
                (header.toLowerCase().includes("date"))
              ) {
                const date = XLSX.SSF.parse_date_code(cell);
                const formattedDate = `${date.y}-${String(date.m).padStart(
                  2,
                  "0"
                )}-${String(date.d).padStart(2, "0")}`;
                return [header, formattedDate];
              }

              return [header, cell];
            })
            .filter(([key, value]) => key !== undefined && value !== undefined)
        );
        acc.push(transformedRow);
        return acc;
      }, []);

      setHeaders(headers);
      setFileData(transformedData);
      const resultData = transformData(transformedData);
      setData((prev) => ({
        ...prev,
        Branch: resultData.Branch,
        ReportingTo: resultData.ReportingTo,
        Permission: resultData.Permission,
      }));
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileUpload = (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      const fileType = file ? file.name.split(".").pop().toLowerCase() : "";

      if (fileType === "xls" || fileType === "xlsx") {
        parseExcel(file);
      } else {
        alert("Please upload a XLSX, or XLS file.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getDataForEmployeeImport = async () => {
    try {
      setLoading(true);

      const { data: response } = await axiosInstance.post("/Employee/getDataForEmployeeImport", data);

      if (response && response.data) {
        const { Branch, ReportingTo, Permission } = response.data;

        const validationErrors = [];
        const seenEmails = new Set();

        const mappingsBranch = createMapping(Branch, "BranchName", "_id");
        const mappingsReportingTo = createMapping(ReportingTo, "Name", "_id");
        const mappingsPermission = createMapping(Permission, "Roll", "_id");


        let finalData = fileData.map((entry) => {

          const branchId = mappingsBranch[entry["Branch"]?.trim().toLowerCase()] || "";
          const reportingToId = typeof entry["Reporting To"] === 'string'
            ? mappingsReportingTo[entry["Reporting To"].trim().toLowerCase()]
            : "";
          const PermissionId = typeof entry["Permission"] === 'string'
            ? mappingsPermission[entry["Permission"].trim().toLowerCase()]
            : "";

          if (!branchId) {
            validationErrors.push({
              valid: false,
              id: `${entry["Email"]}`,
              fieldName: "Branch",
              fieldValue: entry["Branch"],
              row: entry,
            })
          }

          const mobileNumber = entry["Mobile Number"]?.toString().trim();
          const email = entry["Email"]?.trim();
          const panNumber = entry["Pan Number"]?.trim();

          //duplicate email validate 

          if (seenEmails.has(email)) {
            validationErrors.push({
              valid: false,
              id: `${entry["Email"]}`,
              fieldName: "Email",
              fieldValue: email,
              row: entry,
              error: "Duplicate email found"
            });
          } else {
            seenEmails.add(email);
          }


          if (mobileNumber && mobileNumber.length !== 10) {
            validationErrors.push({
              valid: false,
              id: `${entry["Email"]}`,
              fieldName: "Mobile Number",
              fieldValue: entry["Mobile Number"],
              row: entry,
            });
          }

          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (email && !emailPattern.test(email)) {
            validationErrors.push({
              valid: false,
              id: `${entry["Email"]}`,
              fieldName: "Email",
              fieldValue: entry["Email"],
              row: entry,
            });
          }
          const panPattern = /^[A-Z]{3}[A-Z]{2}[0-9]{4}[A-Z]{1}$/;

          if (panNumber && !panPattern.test(panNumber)) {
            validationErrors.push({
              valid: false,
              id: `${entry["Code"]}`,
              fieldName: "Pan Number",
              fieldValue: entry["Pan Number"],
              row: entry,
            });
          }



          if (!entry["Email"]) {
            validationErrors.push({
              valid: false,
              id: `${entry["Email"]}`,
              fieldName: "Email",
              fieldValue: entry["Email"],
              row: entry,

            });
          }


          if (!reportingToId) {
            validationErrors.push({
              valid: false,
              id: `${entry["Email"]}`,
              fieldName: "Reporting To",
              fieldValue: entry["Reporting To"],
              row: entry,
            })
          }

          if (!PermissionId) {
            validationErrors.push({
              valid: false,
              id: `${entry["Email"]}`,
              fieldName: "Permission",
              fieldValue: entry["Permission"],
              row: entry,
            })
          }


          return {
            Branch: branchId || "",
            ReportingTo: reportingToId || "",
            Permission: PermissionId || "",
            Name: entry["Name"],
            MiddleName: entry["Middle Name"],
            LastName: entry["Last Name"],
            MobileNumber: entry["Mobile Number"],
            Email: entry["Email"],
            DOA: entry["DOA"],
            DOB: entry["DOB"],
            FatherName: entry["Father Name"],
            MaritalStatus: entry["Marital Status"],
            Gender: entry["Gender"],
            JoiningDate: entry["Joining Date"],
            AadhaarNumber: entry["Aadhaar Number"],
            PanNumber: entry["Pan Number"],
            Department: entry["Department"],
            Digination: entry["Digination"],
            SpokeLocation: entry["Spoke Location"],
            ReferralBy: entry["Referral By"],
            NomineeName: entry["Nominee Name"],
            NomineeBy: entry["Nominee By"],
            PFNumber: entry["PF Number"],
            EmployeeStateInsurance: entry["Employee State Insurance"],
            Address: entry["Address"],
            State: entry["State"],
            City: entry["City"],
            Pincode: entry["Pin Code"],
            EmergencyContactNumber: entry["Emergency Contact Number"],
            WeekendDay: entry["Weekend Day"],
            Qualification: entry["Qualification"],
            Religion: entry["Religion"],
            BloodGroup: entry["Blood Group"],
            AccountHolderName: entry["Account Holder Name"],
            BankName: entry["Bank Name"],
            IFSC: entry["IFSC"],
            BankAccountNo: entry["Bank Account No"],
          }
        })

        if (validationErrors.length > 0) {
          const uniqueArray = [];
          const seen = new Map();

          for (const obj of validationErrors) {
            if (!seen.has(obj.id)) {
              seen.set(obj.id, true);
              uniqueArray.push(obj);
            }
          }

          setError({
            message: "Validation failed!",
            data: uniqueArray,
            errorList: validationErrors,
          });
          return;
        }


        const postResponse = await axiosInstance.post(
          '/Employee/PostEmployeeImport',
          finalData
        );

        if (postResponse?.data?.success) {
          setInsertedCount(postResponse?.data?.insertedCount);
          setBgColor("#d4edda");
        }

        const fileInput = document.getElementById("file_upload");
        if (fileInput) {
          fileInput.value = "";
        }
        setHeaders([]);
        setFileData([]);
        setData([]);

      }
    } catch (error) {
      ToastError(error?.response?.data?.message || "An error occurred while fetching data.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateDownload = (event) => {
    event.preventDefault();

    const fileUr = `${REACT_APP_FileURL}/images/Template/Employee-Template.xlsx`;
    window.open(fileUr, "_blank");
  };

  return (
    <>
      <Header />
      <div className="MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ListDesigncol">
                        <h4 className="card-title">Import Employee</h4>
                      </div>
                      <button
                        onClick={handleTemplateDownload}
                        className="btn mybtn"
                      >
                        Download Template
                      </button>
                    </div>
                  </div>
                  {error && (
                    <Alert severity="error">
                      {error?.message && (
                        <AlertTitle>Error : {error?.message}</AlertTitle>
                      )}
                      {error?.missingHeaders &&
                        error?.missingHeaders?.length > 0 && (
                          <AlertTitle>
                            {error?.missingHeaders.join(", ")}
                          </AlertTitle>
                        )}
                    </Alert>
                  )}
                   {insertedCount !== null && (
                    <div
                      style={{
                        backgroundColor: bgColor,   // Green if success
                        width: "80vw",               // 80% of the viewport width
                        height: "auto",              // Automatically adjust height based on content
                        padding: "20px",             // Optional padding for spacing
                        fontSize: "1rem",          // Adjust font size
                        margin: "20px auto",

                      }}
                    >
                      {insertedCount} Records Inserted
                    </div>
                  )}
                  <div className="container">
                    <div>
                      <div className="upload-container">
                        <input
                          type="file"
                          id="file_upload"
                          accept=".xlsx, .xls"
                          onChange={handleFileUpload}
                        />
                      </div>
                      <div className="mt-4" style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-primary"
                          color="warning"
                          type="button"
                          disabled={loading || error || fileData.length === 0}
                          onClick={getDataForEmployeeImport}
                        >
                          {loading ? "In Progress..." : "Import"}
                        </button>
                      </div>
                    </div>
                    {error && error?.data && error?.data?.length !== 0 && (
                      <div className="mt-8">
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Preview Error Data
                        </div>
                        <div
                          // className=" p-2 container"
                          style={{
                            zoom: "80%",
                            height: "60vh",
                            border: "2px solid black",
                            resize: "vertical", // Allow vertical resizing only
                            overflow: "auto",
                          }}
                        >
                          <table class="table table-striped   table-bordered  overflow-x mt-3">
                            <thead>
                              <tr>
                                {headers &&
                                  headers.length > 0 &&
                                  headers.map((header) => {
                                    let hasError;
                                    if (error && error?.errorList) {
                                      hasError = error?.errorList.find(
                                        (error) => error.fieldName === header
                                      );
                                    }
                                    return (
                                      <th
                                        style={{
                                          backgroundColor: hasError
                                            ? "#f8d7da"
                                            : "",
                                        }}
                                        scope="col"
                                      >
                                        {header}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {error?.data.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    {headers.map((item, index) => {
                                      let hasError;
                                      if (error && error?.errorList) {
                                        hasError = error?.errorList.find(
                                          (err) =>
                                            err?.fieldValue === data?.row[item]
                                        );
                                      }

                                      return (
                                        <td
                                          style={{
                                            backgroundColor:
                                              hasError &&
                                                hasError?.fieldValue ===
                                                data?.row[item]
                                                ? "#f8d7da"
                                                : "",
                                          }}
                                          key={index}
                                        >
                                          {data?.row[item]}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default ImportEmployee;
