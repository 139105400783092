import React, { useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";

import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const CreateInsuranceCompany = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { value, Mode, view } = state || {};
  const [Name, setName] = useState(value?.Name || "");
  const [AliasName, setAliasName] = useState(value?.AliasName || "");

  const [Email, setEmail] = useState(value?.Email || "");
  const [Address, setAddress] = useState(value?.Address || "");
  const [Number, setNumber] = useState(value?.Number || "");
  const [CompanyLogo, setCompanyLogo] = useState();
  const [formData, setFormData] = useState({
    policyNumberLengths: value?.policyNumberLengths || "",
    typeOfValue: value?.typeOfValue || "",
    payoutOn: value?.payoutOn || "IssueDate",
    alternatePolicy: value?.alternatePolicy || "No",
    policyInputPlaceholder: value?.policyInputPlaceholder || "",
    roundOn: value?.roundOn || "Round",
  });


  const handleCreate = async () => {
    try {
      if (!Name) {
        ToastWarn("Please Name is required");
      }

      const EmpData = new FormData();
      EmpData.append("Name", Name);
      EmpData.append("AliasName", AliasName);
      EmpData.append("Email", Email);
      EmpData.append("Address", Address);
      EmpData.append("Number", Number);
      EmpData.append("CompanyLogo", CompanyLogo);
      EmpData.append("policyNumberLengths", formData.policyNumberLengths);
      EmpData.append("typeOfValue", formData.typeOfValue);
      EmpData.append("payoutOn", formData.payoutOn);
      EmpData.append("alternatePolicy", formData.alternatePolicy);
      EmpData.append("policyInputPlaceholder", formData.policyInputPlaceholder);
      EmpData.append("roundOn", formData.roundOn);

      const {
        data: { success, message },
      } = await axiosInstance.post("/InsuranceCompany", EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/InsuranceCompany/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message.message);
    }
  };

  const handleEdit = async () => {
    try {
      const EmpData = new FormData();
      EmpData.append("Name", Name);
      EmpData.append("AliasName", AliasName);
      EmpData.append("Email", Email);
      EmpData.append("Address", Address);
      EmpData.append("Number", Number);
      EmpData.append("CompanyLogo", CompanyLogo);
      EmpData.append("policyNumberLengths", formData.policyNumberLengths);
      EmpData.append("typeOfValue", formData.typeOfValue);
      EmpData.append("payoutOn", formData.payoutOn);
      EmpData.append("alternatePolicy", formData.alternatePolicy);
      EmpData.append("policyInputPlaceholder", formData.policyInputPlaceholder);
      EmpData.append("roundOn", formData.roundOn);
      const {
        data: { success, message },
      } = await axiosInstance.put(`/InsuranceCompany/${value?._id}`, EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/InsuranceCompany/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="">
              <h4 className="card-title">
                {view
                  ? "View  Insurance Company"
                  : Mode
                  ? "Edit Insurance Company"
                  : "Create Insurance Company"}
              </h4>
              <hr />

              <div data-repeater-item="" className="outer">
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label required>Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter Insurance Company Name"
                    required
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label required>Alias Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Alias"
                    value={AliasName}
                    onChange={(e) => setAliasName(e.target.value)}
                    placeholder="Enter Insurance Company Alias Name"
                    required
                  />
                </div>

                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Email Id</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="xyz@gmail.com"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Number</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Number"
                    value={Number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="77xxxxxxxx"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Address</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Address"
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Address"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Policy Number Lengths (comma-separated)</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="PolicyNumberLengths"
                    value={formData.policyNumberLengths}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^(\d+,)*\d*,?$/;
                      if (regex.test(value) || value === "") {
                        setFormData({
                          ...formData,
                          policyNumberLengths: value,
                        });
                      } else {
                        ToastWarn(
                          "Please enter numbers separated by commas only"
                        );
                      }
                    }}
                    placeholder="Enter policy number lengths"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label htmlFor="select-filed">Type of value</label>
                  <select
                    id="select-filed"
                    disabled={view}
                    className="form-control"
                    value={formData.typeOfValue}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        typeOfValue: e.target.value,
                      }))
                    }
                  >
                    <option value="">----- Select type of value -----</option>
                    <option value="Numeric">Numeric</option>
                    <option value="Alphanumeric">Alphanumeric</option>
                  </select>
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Policy Input Placeholder</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="policyInputPlaceholder"
                    value={formData.policyInputPlaceholder}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        policyInputPlaceholder: e.target.value,
                      }))
                    }
                    placeholder="Enter policy input placeholder"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Logo</label>
                  <input
                    disabled={view}
                    className="form-control"
                    type="file"
                    name="CompanyLogo"
                    // value={CompanyLogo}
                    onChange={(e) => setCompanyLogo(e.target.files[0])}
                    placeholder="CompanyLogo"
                    //done
                  />
                </div>

                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label htmlFor="select-filed">Payout Calculate On</label>
                  <select
                    id="select-filed"
                    disabled={view}
                    className="form-control"
                    value={formData.payoutOn}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        payoutOn: e.target.value,
                      }))
                    }
                  >
                    <option value="IssueDate">Issue Date</option>
                    <option value="RSD">Start Date (RSD)</option>
                  </select>
                </div>

                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label htmlFor="select-filed">Alternate Policy Number</label>
                  <select
                    id="select-filed"
                    disabled={view}
                    className="form-control"
                    value={formData.alternatePolicy}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        alternatePolicy: e.target.value,
                      }))
                    }
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>

                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label htmlFor="select-filed">Age Calculate On</label>
                  <select
                    id="select-filed"
                    disabled={view}
                    className="form-control"
                    value={formData.roundOn}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        roundOn: e.target.value,
                      }))
                    }
                  >
                    <option value="Exact">Exact</option>
                    <option value="Round">Round</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/InsuranceCompany/data")}
            >
              Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateInsuranceCompany;
